import { useState, useEffect } from "react";
import { getRelatedArticles } from "@helpers";

import * as components from "@components";
import clsx from "clsx";

export default function News({ props }) {
    const [productArticles, setProductArticles] = useState([]);

    useEffect(() => {
        getRelatedArticles(props).then((articles) => {
            setProductArticles(articles);
        });
    }, []);

    return (
        <div className={parentContainer}>
            <components.UnderlinedSectionHeader>
                News
            </components.UnderlinedSectionHeader>
            <components.HorizontalScrollWrapper className="mt-10 w-full">
                {productArticles.length > 0 ? (
                    productArticles.map((article) => {
                        const cardProps = {
                            title: article.title,
                            subheader: article.summary,
                            img: article.image_url,
                            imgAlt: "",
                            link: `/news/${article.uri}`,
                            linkDisplayText: "Read Articles",
                        };
                        return (
                            <components.GreenCard
                                key={article.id}
                                {...cardProps}
                            />
                        );
                    })
                ) : (
                    <components.NoCard product={props} />
                )}
            </components.HorizontalScrollWrapper>
        </div>
    );
}

const parentContainer = clsx(
    "mt-20 flex w-full flex-col items-center md:mt-40 md:mb-40 mb-20",
);
