import { useState } from "react";
import { Hero, Tabs, Feature } from "./components";
import { tabs, tabsContent } from "./constants";

export default function DataOverview() {
    const [selectedTab, setSelectedTab] = useState(0);
    const featureProps = { selectedTab, tabs, tabsContent: tabsContent() };
    const tabProps = { ...featureProps, setSelectedTab };
    return (
        <>
            <Hero />
            <div className="my-20 flex w-full justify-center">
                <div className="flex w-[90%]">
                    <Tabs {...tabProps} />
                    <Feature {...featureProps} />
                </div>
            </div>
        </>
    );
}
