export default function DotConnector() {
    return (
        <svg
            width="33"
            height="7"
            viewBox="0 0 33 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M27.3636 3.54688C27.3636 5.01963 28.5575 6.21354 30.0303 6.21354C31.503 6.21354 32.6969 5.01963 32.6969 3.54688C32.6969 2.07412 31.503 0.880208 30.0303 0.880208C28.5575 0.880208 27.3636 2.07412 27.3636 3.54688ZM0.299805 4.04688H30.0303V3.04688H0.299805V4.04688Z"
                fill="black"
            />
        </svg>
    );
}
