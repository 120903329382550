import clsx from "clsx";
import { HeroOverlay } from "@components";
import { About } from "@assets/hero";

export default function Hero() {
    return (
        <div className={containerStyle}>
            <HeroOverlay img="https://images.pexels.com/photos/4309369/pexels-photo-4309369.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />
            <div className="z-10 flex flex-col lg:w-1/2">
                <div className={introStyle}>our mission</div>
                <div className={explainStyle}>
                    Track carbon in every living tree on Earth to accelerate
                    natural climate solutions.
                </div>
            </div>
            <About className={aboutSvgStyle} />
        </div>
    );
}

const introStyle = clsx(
    "text-bold uppercase tracking-wider text-white mb-2 lg:mb-10",
);
const explainStyle = clsx("text-xl lg:text-6xl text-white font-bold max-full");
const containerStyle = clsx(
    "mb-20 lg:mb-40 p-10 lg:p-0 lg:px-20 flex lg:flex-row flex-col-reverse h-screen w-full items-center justify-between relative",
);
const aboutSvgStyle = clsx("z-10 w-full md:pt-20 lg:pt-0 lg:mt-40 lg:w-1/2");
