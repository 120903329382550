import Loading from "./components/Loading";

const metaProps = {
    title: "",
    desc: "",
    img: "",
};

export const constants = {
    metaProps,
    loading: <Loading />,
    url: "https://global-forest-structure.projects.earthengine.app/view/luca-viewer#alerts=1;lon=-42.384952;lat=4.987819;zoom=3",
};
