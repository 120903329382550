export default function Privacy() {
  return (
    <div className="pb-10 md:p-10 flex justify-center mt-[80px]">
      <div className="max-w-[1200px] w-full flex flex-col break-words justify-center">
        <div className="mb-10">
          <div className="font-bold text-2xl text-center">Privacy Policy</div>
        </div>
        <div className="flex flex-col justify-center mx-4">
          <p>
            <span>Last update: November 1, 2022</span>
          </p>

          <p>
            <strong>
              <span>1. Introduction&nbsp;</span>
            </strong>
          </p>

          <p>
            <span>Welcome! CTREES (&ldquo;</span>
            <span>CTrees</span>
            <span>,&rdquo; &ldquo;</span>
            <span>we,</span>
            <span>&rdquo; &ldquo;</span>
            <span>us,</span>
            <span>&rdquo; or &ldquo;</span>
            <span>our</span>
            <span>
              &rdquo;) recognizes the importance of your privacy and the need to
              protect information that users of our website, located at
              https://ctrees.org/ (the &ldquo;
            </span>
            <span>Website</span>
            <span>
              &rdquo;), provide to us. This Privacy Policy explains our data
              collection practices, how we may use and disclose that
              information, the steps we take to keep it secure, your choices
              regarding our use of this information, and how you can contact us
              if you have any questions about our privacy practices. We will
              refer to the personal information we obtain about you as &ldquo;
            </span>
            <span>Your Data.</span>
            <span>
              &rdquo; Please read our complete Privacy Policy. By visiting
              https://ctrees.org/, you accept the practices described in this
              Privacy Policy.&nbsp;
            </span>
          </p>

          <p>
            <span>
              CTrees will occasionally update this Privacy Policy. When we do,
              we will also revise the &ldquo;Last Update&rdquo; date at the top
              of this Privacy Policy.
            </span>
          </p>

          <p>
            <span>
              <strong>2. Information We Collect</strong>
            </span>
          </p>

          <p>
            <span>
              <strong>a) Information you provide to us&nbsp;</strong>
            </span>
          </p>

          <p>
            <span>
              We collect information from and about you directly when you
              provide it to us. This information may be collected when you
              contact us, fill out a form, access or participate on our Website,
              respond to surveys, or otherwise interact with us, such as through
              social media or networking sites. This information may
              include:&nbsp;
            </span>
          </p>

          <ul>
            <li aria-level="1">
              Contact information, such as your name, title, phone number,
              organization or employer, job title, work sector, physical
              address, and e-mail address;
            </li>
            <li aria-level="1">
              Comments, questions, and requests you may make;&nbsp;
            </li>
            <li aria-level="1">
              Information about your preferences, such as your preferred methods
              of communication and the types of information in which you are
              interested;&nbsp;
            </li>
            <li aria-level="1">Details of downloads from our Website;&nbsp;</li>
            <li aria-level="1">
              Records and copies of your correspondence (including email
              addresses and phone numbers), if you contact us; and&nbsp;
            </li>
            <li aria-level="1">
              Any other information you voluntarily provide.
            </li>
          </ul>

          <p>&nbsp;</p>

          <p>
            <span>
              <strong>b)&nbsp;Information we collect automatically</strong>
            </span>
          </p>

          <p>
            <span>
              Like most online services, we automatically receive standard
              technical information when you use the Website. We collect this
              information through browser cookies, pixel tags, web server logs,
              web beacons, embedded scripts, and similar technologies. These
              features enable us to personalize your experience on the Website,
              understand how you use it, maintain a persistent session, and
              improve and further develop the Website. We collect this
              information as follows:
            </span>
          </p>

          <p>&nbsp;</p>

          <ul>
            <li aria-level="1">
              <span>
                <strong>Web server cookies:</strong>
              </span>{" "}
              A cookie is a small piece of information that is sent by our web
              server, stored on your PC&rsquo;s hard drive, and used by your web
              browser to &ldquo;remember&rdquo; information about your visit. We
              use cookies to help us collect information and to enhance your
              experience using the Website. Specifically, we use cookies to
              authenticate users, personalize your experience when using the
              Website, analyze which features of the Website you use most
              frequently, and measure and optimize advertising and promotional
              effectiveness. To do this, we may use both session cookies, which
              expire once you close your web browser, and persistent cookies,
              which stay on your computer until you delete them. If you do not
              want us to place a cookie on your hard drive, you may be able to
              turn that feature off on your computer or mobile device. Please
              consult your internet browser&rsquo;s documentation for
              information on how to do this. However, if you decide not to
              accept cookies from us, certain features of the Website may not be
              fully available.
            </li>
            <li aria-level="1">
              <span>
                <strong>Pixel Tags:</strong>
              </span>{" "}
              Certain websites contain electronic images known as pixel tags
              (also called web beacons or gifs) that we use to help deliver and
              recognize cookies on our Website, count users who have visited
              those sites, and gather usage and performance data. We also
              include pixels in our emails to tell if you open and act on them.
            </li>
            <li aria-level="1">
              <span>
                <strong>Embedded Scripts:</strong>
              </span>{" "}
              An embedded script is programming code that is designed to collect
              information about your interactions with the Website, such as the
              links you click on. The code is temporarily downloaded onto your
              device from our web server or a third-party service provider, is
              active only while you are connected to the Website, and is
              deactivated or deleted thereafter.
            </li>
            <li aria-level="1">
              <span>
                <strong>Log files:</strong>
              </span>{" "}
              Like most standard website servers we use log files. This includes
              internet protocol (IP) addresses, browser type, internet service
              provider (ISP), referring/exit pages, platform type, date/time
              stamp, and number of clicks to analyze trends, administer the
              site, track user&rsquo;s movement in the aggregate, and gather
              broad demographic information for aggregate use. IP addresses etc.
              are tied to personally identifiable information to enable our
              web-based service.
            </li>
            <li aria-level="1">
              <span>
                <strong>Device information:</strong>
              </span>{" "}
              We collect information about the device you use to access or use
              the Website, including the hardware model, operating system and
              version, unique device identifiers, network information, and
              information about your use of our applications.
            </li>
            <li aria-level="1">
              <span>
                <strong>Geolocation information:</strong>
              </span>{" "}
              We may automatically collect geolocation information from your
              device, your wireless carrier, or certain third-party service
              providers (&ldquo; Geolocation Information &rdquo;). You may
              control the collection of Geolocation Information through the user
              settings on your device. You may decline to allow us to collect
              such Geolocation Information, in which case we may not be able to
              provide certain services to you.&nbsp;
            </li>
          </ul>

          <h4 role="presentation">&nbsp;</h4>

          <h4 role="presentation">
            <span>
              <strong>
                c) Information we collect from other sources, including our
                social media pages
              </strong>
            </span>
          </h4>

          <p>
            <span>
              We may collect personal information from users and visitors of
              other sites on which you have interacted with us. We will do so in
              accordance with the terms of use and privacy policies of those
              sites and applicable law. This includes our social media pages on
              places like Facebook, Instagram, TikTok, YouTube and Twitter. For
              example, we may collect the information you shared on our social
              media pages through an application or form, which will have a link
              to this Privacy Policy. We may also receive personal information
              when you comment on our social media advertisements, post comments
              about us, or tag CTrees in a public-facing social media post.
              Personal information may also be collected by the third-party
              social media sites that host our social media pages. These sites
              may provide aggregate information and analysis to us about
              visitors&#39; use of our social media pages. This allows us to
              better understand and analyze our user growth, general demographic
              information about the users of these pages, and interaction with
              the content that we post. Overall, this information may be used to
              help us understand the types of visitors and users of our social
              media pages and use of the content. This Privacy Policy does not
              cover personal information collected by such third-party social
              media sites. For more information on their privacy and security
              practices please review the privacy policies and terms of use on
              their respective websites.
            </span>
          </p>

          <p>&nbsp;</p>

          <p>
            <span>
              <strong>
                d) Information we collect through third-party data and analytics
                providers
              </strong>
            </span>
          </p>

          <p>
            <span>
              We use third-party data and analytics services (including as
              Google Analytics) to evaluate your use of the Website, compile
              reports on activity, collect demographic data, enhance our data
              with other information about our users, analyze performance
              metrics, and collect and evaluate other information relating to
              the Website and internet usage. These third parties use cookies
              and other technologies to help analyze and provide us the data,
              and may also collect information regarding your use of other
              websites, apps, and online resources, and across your electronic
              devices. We may also receive information about you from our
              marketing partners, including data brokers, such as ad campaign
              information (e.g., the ads you click on) or the social media
              platforms you use. These third-party services may also provide us
              with information based on inferences to better understand our
              users (see below). The information used by such services is
              usually at the aggregate level, but may contain identifiable
              personal information.
            </span>
          </p>

          <p>
            <span>
              For more information on Google Analytics, including how to opt out
              from certain data collection, please visit{" "}
            </span>
            <a href="https://www.google.com/analytics">
              https://www.google.com/analytics
            </a>
            <span>
              . Please be advised that if you opt out of any service, you may
              not be able to use the full functionality of the Website.
            </span>
          </p>

          <p>
            <span>
              <strong>e) Tracking data</strong>
            </span>
          </p>

          <p>
            <span>
              We collect tracking data using first and third-party cookies,
              pixels, web server logs, web beacons, and similar data collection
              and tracking technologies on the Website, third party websites,
              apps and online services, and across your devices (such as IP
              address, browser, type, ISP, platform type, device type). Third
              parties such as advertising networks and analytics providers may
              also collect information about your online activities over time
              and across different websites and devices when you access or use
              the Website.
            </span>
          </p>

          <p>
            <span>
              <strong>3. How We Use Your Data</strong>
            </span>
          </p>

          <p>
            <span>
              We use Your Data to provide the Website to you, and to operate our
              business. This includes:
            </span>
          </p>

          <p>&nbsp;</p>

          <ul>
            <li aria-level="1">
              <span>
                <strong>Service-related usage:</strong>
              </span>{" "}
              We use Your Data to provide and support the Website, such as
              communicating with you and other users, providing our services,
              informing you about our work, processing your requests, and
              maintaining our systems.
            </li>
            <li aria-level="1">
              <span>
                <strong>Analyzing and improving our services:</strong>
              </span>{" "}
              We may run calculations, aggregate, normalize, clean, enhance, and
              make derivative works of the information obtained about you in
              order to:
              <ul>
                <li aria-level="2">
                  <span>
                    <span>
                      <span>
                        <span>
                          Understand and analyze usage trends and preferences;
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li aria-level="2">
                  <span>
                    <span>
                      <span>
                        <span>
                          Monitor and analyze the effectiveness of the Website;
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li aria-level="2">
                  <span>
                    <span>
                      <span>
                        <span>
                          Improve the Website and develop new products,
                          services, features, and functionalities; and
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li aria-level="2">
                  <span>
                    <span>
                      <span>
                        <span>
                          Test the Website and our infrastructure to make sure
                          the Website is working correctly.
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
            </li>
            <li aria-level="1">
              <span>
                <strong>Marketing:</strong>
              </span>
              If you enter your contact information on the Website and opt-in to
              receive electronic marketing communications from us, we may
              provide you with materials that could be useful, relevant,
              valuable, or otherwise of interest to you, such as our newsletter,
              and updates about services and products offered by us. We will not
              send you marketing emails if you have not signed up for this, and
              you can unsubscribe from receiving such emails at any time by
              clicking on the &ldquo;unsubscribe&rdquo; link at the bottom of
              all our marketing emails. You will continue to receive other
              correspondence that relates to requests for downloads or other
              orders you have placed with CTrees.
            </li>
            <li aria-level="1">
              <span>
                <strong>Advertising</strong>
              </span>
              : CTrees, its business partners, and advertising/marketing
              companies may use Your Data to tailor, analyze, manage, report and
              optimize advertising you see on the Website, and on other sites
              and apps that you access and/or use through your devices. By
              accessing and using the Website, you consent to the processing of
              Your Data for these purposes.
            </li>
          </ul>

          <p>&nbsp;</p>

          <p>
            <span>
              <strong>4. How We Share Your Data</strong>
            </span>
          </p>

          <p>
            <span>
              We may share, transfer, or disclose Your Data if you ask us to do
              so, as well as in the following circumstances. We will take
              reasonable measures to require that any party receiving any of
              your personal information from us undertakes to: (i) retain and
              use such information only for the purposes set out in this Privacy
              Policy; (ii) not disclose your personal information except with
              your consent, as permitted by law, or as permitted by this Privacy
              Policy; and (iii) generally protect the privacy of your personal
              information.
            </span>
          </p>

          <p>
            <span>
              <strong>
                a) To assist us in running our business and providing services
                to you
              </strong>
            </span>
          </p>

          <p>
            <span>
              We may employ other companies and individuals to perform functions
              on our behalf, such as website hosting, data analysis and
              enhancement, payment processing, order fulfillment, information
              technology and related infrastructure provision, customer service,
              and email delivery. CTrees thus may reveal user data to third
              parties including third-party affiliates, our sales
              representatives, and business partners that may require
              information to invoice for a product purchased through our
              Website. Additionally, we may use third-party credit card
              processing companies to bill users for goods and services. These
              other companies will have access to Your Data only as necessary to
              perform their functions and to the extent permitted by law.
            </span>
          </p>

          <p>
            <span>
              <strong>b) To better understand and serve our users</strong>
            </span>
          </p>

          <p>
            <span>
              In an ongoing effort to better understand our users, we might
              analyze Your Data in aggregate form in order to operate, maintain,
              manage, and improve the Website. This aggregate information does
              not identify you personally. We may share this aggregate data with
              our affiliates, agents, and business partners. We may also
              disclose aggregated user statistics in order to describe our
              products and services to current and prospective business partners
              and to other third parties for other lawful purposes.&nbsp;
            </span>
          </p>

          <p>
            <span>
              <strong>c) For advertising and marketing purposes</strong>
            </span>
          </p>

          <p>
            <span>
              We may share information about your activity in connection with
              your use of the Website, including tracking data, with third-party
              advertisers and remarketers to serve advertisements on our behalf
              across the internet and for the purpose of tailoring, analyzing,
              managing, reporting, and optimizing advertising you see on the
              Website and elsewhere on the web. These entities may use cookies,
              web beacons, and other technologies to collect information about
              your use of the Website and other sites, including your IP
              address, web browser, pages viewed, time spent on pages, links
              clicked, and conversion information. This information may be used
              by us and others to, among other things, analyze and track data,
              determine the popularity of certain content, deliver advertising
              and content targeted to your interests on the Website and other
              sites, and better understand your online activity.&nbsp;
            </span>
          </p>

          <p>
            <span>
              By accessing and using the Website, you consent to the processing
              of data about you by these advertisers/remarketing providers in
              the manner and for the purposes set out in this Privacy Policy.
              For more information about interest-based ads, or to opt out of
              having your web browsing information used for behavioral
              advertising purposes, please visit{" "}
            </span>
            <a href="http://www.aboutads.info/choices">
              www.aboutads.info/choices
            </a>
            <span>.</span>
          </p>

          <p>
            <span>
              We may also work with third-party social media sites, such as
              Facebook, Google, and other media platforms, to serve ads to you
              as part of a customized campaign, unless you notify us that you
              prefer not to have information about you used in this way. For
              more information about how you can opt out of customized
              campaigns, please see &ldquo;Your Choices&rdquo; below.
            </span>
          </p>

          <p>
            <span>
              <strong>d) As part of a change of our organization</strong>
            </span>
          </p>

          <p>
            <span>
              As we grow and develop our organization, we might share Your Data
              with other organizations we join, merge with, or in other forms
              cooperate with.
            </span>
          </p>

          <p>
            <span>
              <strong>e) For legal purposes</strong>
            </span>
          </p>

          <p>
            <span>
              To the extent permitted by law, we may also disclose Your Data
              when required by law, court order, or other government or law
              enforcement authority or regulatory agency, or whenever we believe
              that disclosing such information is necessary or advisable, for
              example, to protect the rights, property, or safety of CTrees or
              others.
            </span>
          </p>

          <p>
            <span>
              <strong>5. Children&rsquo;s Privacy&nbsp;</strong>
            </span>
          </p>

          <p>
            <span>
              CTrees takes children&rsquo;s privacy seriously. We do not seek to
              collect personal information about children under the age of 13
              through our Website. If you are under 13, please do not give us
              any personal information. We encourage parents and legal guardians
              to monitor their children&rsquo;s internet usage and to help
              enforce our Privacy Policy by instructing their children to never
              provide us personal information without their permission. If you
              have reason to believe that a child under the age of 13 has
              provided personal information to us, please contact us, and we
              will promptly take steps to delete such information and terminate
              the child&rsquo;s account.
            </span>
          </p>

          <p>
            <span>
              <strong>
                6. External Websites and Payment Service Providers
              </strong>
            </span>
          </p>

          <p>
            <span>
              The Website may contain links to third-party websites. CTrees has
              no control over the privacy practices or the content of any of our
              business partners, advertisers, sponsors, or other websites to
              which we provide links. As such, we are not responsible for the
              content or the privacy policies of those third-party websites. You
              should check the applicable third-party privacy policy and terms
              of use when visiting any other websites.&nbsp;
            </span>
          </p>

          <p>
            <span>
              Please be advised that your use of third-party payment processors
              is subject to the terms and conditions, including the privacy
              policies, of those companies. By using these services, you accept
              the their terms of service, which can be found here:
            </span>
          </p>

          <ul>
            <li aria-level="1">
              Google Pay:
              <a href="https://payments.developers.google.com/terms/sellertos">
                <span>
                  <span>
                    <span>
                      <span>
                        https://payments.developers.google.com/terms/sellertos
                      </span>
                    </span>
                  </span>
                </span>
              </a>
              &nbsp;
            </li>
            <li aria-level="1">
              PayPal:
              <a href="https://www.paypal.com/us/webapps/mpp/ua/legalhub-full">
                <span>
                  <span>
                    <span>
                      <span>
                        https://www.paypal.com/us/webapps/mpp/ua/legalhub-full
                      </span>
                    </span>
                  </span>
                </span>
              </a>
              &nbsp;
            </li>
            <li aria-level="1">
              Shop Pay:
              <a href="https://shop.app/terms-of-service">
                <span>
                  <span>
                    <span>
                      <span>https://shop.app/terms-of-service</span>
                    </span>
                  </span>
                </span>
              </a>
              &nbsp;
            </li>
            <li aria-level="1">
              Meta Pay:
              <a href="https://www.metapay.com/terms-of-use/">
                <span>
                  <span>
                    <span>
                      <span>https://www.metapay.com/terms-of-use/</span>
                    </span>
                  </span>
                </span>
              </a>
              &nbsp;
            </li>
          </ul>

          <p>&nbsp;</p>

          <p>
            <span>
              <strong>7. Security&nbsp;</strong>
            </span>
          </p>

          <p>
            <span>
              We take commercially reasonable steps to protect Your Data from
              loss, misuse, and unauthorized access, disclosure, alteration, or
              destruction. Please understand, however, that no security system
              is impenetrable. We cannot guarantee the security of our
              databases, nor can we guarantee that the information you supply
              will not be intercepted while being transmitted to and from us
              over the Internet. In particular, e-mail may not be secure, and
              you should therefore take special care in deciding what
              information you send to us via e-mail.
            </span>
          </p>

          <p>
            <span>
              <strong>8. Your Choices</strong>
            </span>
          </p>

          <p>&nbsp;</p>

          <h4 role="presentation">
            <span>
              <strong>a) Deletion of Information</strong>
            </span>
          </h4>

          <p>
            <span>
              If you would like us to delete any or all information, please
              email us at admin@ctrees.org. To the extent compatible with our
              legal or regulatory record keeping requirements, we will use
              commercially reasonable efforts to process such requests in a
              timely manner.
            </span>
          </p>

          <p>
            <span>
              <strong>b) Cookies</strong>
            </span>
          </p>

          <p>
            <span>
              Most web browsers are set to accept cookies by default. If you
              prefer, you can usually choose to set your browser to remove or
              reject browser cookies. Please note that if you choose to remove
              or reject cookies, this could affect the availability and
              functionality of the Website.
            </span>
          </p>

          <h4 role="presentation">
            <span>
              <strong>c) Promotional Communications</strong>
            </span>
          </h4>

          <p>
            <span>
              You may opt out of receiving promotional communications from us by
              following the instructions in those communications or by emailing
              us at admin@ctrees.org. Please note that you cannot opt out of
              receiving transactional e-mails related to your orders.&nbsp;
            </span>
          </p>

          <p>
            <span>
              <strong>9. Do Not Track</strong>
            </span>
          </p>

          <p>
            <span>
              As discussed above, third parties such as advertising networks and
              analytics providers may collect information about your online
              activities over time and across different websites when you access
              or use the Website. Currently, various browsers offer a &ldquo;Do
              Not Track&rdquo; option, but there is no standard for commercial
              websites. At this time, we do not monitor, recognize, or honor any
              opt-out or do not track mechanisms, including general web browser
              &ldquo;Do Not Track&rdquo; settings and/or signals.
            </span>
          </p>

          <p role="presentation">
            <span>
              <strong>10. Notice to California Residents</strong>
            </span>
          </p>

          <p>
            <span>
              Pursuant to California&rsquo;s &ldquo;Shine the Light&rdquo; law
              (Civil Code Section &sect; 1798.83), California residents have the
              right to request certain information regarding our disclosure of
              personal information to third parties for their direct marketing
              purposes, including the names and addresses of those third
              parties, and examples of the types of services or products
              marketed by those third parties.&nbsp;
            </span>
          </p>

          <p>
            <span>
              To make such a request, please send an email to admin@ctrees.org.
              Please note, however, that we have never shared any personal
              information for a third party&rsquo;s direct marketing purposes.
            </span>
          </p>

          <p role="presentation">
            <span>
              <strong>11. Notice to Nevada Residents</strong>
            </span>
          </p>

          <p>
            <span>
              If you are a resident of Nevada, you have the right to opt-out of
              the sale of personal information to third parties. You can
              exercise this right by contacting us at admin@ctrees.org with the
              subject line &ldquo;Nevada Do Not Sell Request&rdquo; and
              providing us with your name and the email address associated with
              your account. Please note, however, that we do not sell personal
              information to third parties.
            </span>
          </p>

          <p role="presentation">
            <span>
              <strong>
                12. Notice to Individuals Located in the European Economic Area,
                the United Kingdom, or Switzerland
              </strong>
            </span>
          </p>

          <p>
            <span>
              If you are an individual located in the European Economic Area,
              the United Kingdom or Switzerland, please review our GDPR Privacy
              Notice (below) for further information regarding the processing of
              your personal data and your rights under the General Data
              Protection Regulation (2016/679).
            </span>
          </p>

          <p role="presentation">
            <span>
              <strong>13. Notice to All Non-U.S. Residents</strong>
            </span>
          </p>

          <p>
            <span>
              The Website is hosted in and provided from the United States and
              is intended for visitors located within the United States. If you
              are located outside of the United States, please be aware that any
              information you provide to us will be transferred to the United
              States where the privacy laws may not be as protective as those in
              your country of origin. If you are located outside the United
              States and choose to use the Website, you consent to any transfer
              and processing of your personal information in accordance with
              this Privacy Policy, and you do so at your own risk.
            </span>
          </p>

          <p>
            <span>
              <strong>14. Updates to This Privacy Policy&nbsp;</strong>
            </span>
          </p>

          <p>
            <span>
              This Privacy Policy is effective as of the date stated at the top
              of this Privacy Policy. We may update this Privacy Policy from
              time to time and without prior notice to you to reflect changes in
              our privacy practices. We will indicate at the top of this Privacy
              Policy when it was most recently updated, and we encourage you to
              revisit this page periodically to stay aware of any changes. By
              accessing the Website after we notify you of changes to this
              Privacy Policy, you are deemed to have accepted such
              changes.&nbsp;
            </span>
          </p>

          <p role="presentation">
            <span>
              <strong>15. How to Contact Us</strong>
            </span>
          </p>

          <p>
            <span>
              If you have any questions or comments about this Privacy Policy,
              our privacy practices, or if you would like to exercise your
              rights and choices, please email us at admin@ctrees.org.
            </span>
          </p>

          <p>
            <span>###</span>
          </p>

          <p>
            <span>
              <strong>CTrees GDPR Privacy Notice</strong>
            </span>
          </p>

          <p>
            <span>Last Update: November 1, 2022</span>
          </p>

          <p>
            <span>
              If you are located in the European Union (EU), United Kingdom,
              Lichtenstein, Norway, or Iceland (&ldquo;
            </span>
            <span>European Individuals</span>
            <span>
              &rdquo;), you may have additional rights with respect to your
              Personal Data under the General Data Protection Regulation
              (&ldquo;
            </span>
            <span>EU GDPR</span>
            <span>
              &rdquo;), and/or the EU GDPR as saved into United Kingdom law by
              the United Kingdom&#39;s European Union (Withdrawal) Act 2018
              (&ldquo;
            </span>
            <span>UK GDPR</span>
            <span>
              &rdquo;) (collectively with the EU GDPR, the &ldquo;GDPR&rdquo;),
              as set forth in this GDPR Notice (the &ldquo;
            </span>
            <span>GDPR Notice</span>
            <span>&rdquo;).</span>
          </p>

          <p>
            <span>
              In this GDPR Notice, we use the terms &ldquo;Personal Data&rdquo;
              and &ldquo;processing&rdquo; as they are defined in the GDPR, but
              &ldquo;Personal Data&rdquo; generally means information that can
              be used to identify a person, and &ldquo;processing&rdquo;
              generally refers to actions that can be performed on data such as
              its collection, use, storage or disclosure. CTrees will usually be
              the controller of your Personal Data processed in connection with
              the Services.&nbsp;
            </span>
          </p>

          <p>
            <span>
              Where applicable, this GDPR Notice is intended to supplement, and
              not replace, our Privacy Policy (above)
            </span>
            <span>
              . If there are any conflicts between the GDPR Notice and the other
              parts of the Privacy Policy, and you are located in the EU, United
              Kingdom, Lichtenstein, Norway, or Iceland, the provision that is
              more protective of Personal Data shall control to the extent of
              such conflict. If you have any questions about this GDPR Notice or
              whether any of the following rights apply to you, please contact
              us at admin@ctrees.org
            </span>
            <span> </span>
            <span>with the subject line &ldquo;GDPR Request.&rdquo;</span>
          </p>

          <p>
            <span>
              <strong>1. Types of Personal Data we Collect</strong>
            </span>
          </p>

          <p>
            <span>
              We currently collect and otherwise process the categories of
              Personal Data listed in Section 2 of the Privacy Policy.
            </span>
          </p>

          <p>
            <span>
              <strong>2. How we Obtain the Personal Data&nbsp;</strong>
            </span>
          </p>

          <p>
            <span>
              We collect and/or receive the Personal Data in the ways listed in
              Section 2 of the Privacy Policy.
            </span>
          </p>

          <p>
            <span>
              <strong>3. Why we Collect the Personal Data</strong>
            </span>
          </p>

          <p>
            <span>
              We collect and/or receive the Personal Data for the purposes
              listed in Section 3 of the Privacy Policy. We will only process
              your Personal Data if we have a lawful basis for doing so. Under
              the GDPR, the lawful bases we rely on for processing this
              information are:
            </span>
          </p>

          <p>
            <span>
              <strong>a) Your Consent</strong>
            </span>
          </p>

          <p>
            <span>
              In some cases, we process Personal Data based on the consent you
              expressly grant to us at the time we collect such data. When we
              process Personal Data based on your consent, it will be expressly
              indicated to you at the point and time of collection. You can
              remove your consent at any time. You can do this by contacting us
              via email at admin@ctrees.org with the subject line &ldquo;GDPR
              Request.&rdquo;
            </span>
          </p>

          <p>
            <span>
              <strong>b) We Have a Contractual Obligation</strong>
            </span>
          </p>

          <p>
            <span>
              We process certain categories of Personal Data as a matter of
              &ldquo;contractual necessity,&rdquo; meaning that we need to
              process the data to perform under our Terms of Use with you, which
              enables us to provide you with the Website and other services.
              When we process data due to contractual necessity, failure to
              provide such Personal Data will result in your inability to use
              some or all portions of the Website or services that require such
              data. These categories of Personal Data are:
            </span>
          </p>

          <ul>
            <li aria-level="1">Contact information; and</li>
            <li aria-level="1">
              Any other information you voluntarily provide to us to request our
              services or communicate with us.
            </li>
          </ul>

          <p>&nbsp;</p>

          <p>
            <span>
              <strong>c) We Have a Legitimate Interest</strong>
            </span>
          </p>

          <p>
            <span>
              We process the following categories of Personal Data when we
              believe it furthers the legitimate interest of us or third
              parties:
            </span>
          </p>

          <ul>
            <li aria-level="1">Contact information;</li>
            <li aria-level="1">
              Any other information you voluntarily provide; and
            </li>
            <li aria-level="1">
              Automatically collected information about your use of our Website
              and other interactions with us (including log files, device
              information, geolocation, tracking data, performance metrics,
              etc.).
            </li>
          </ul>

          <p>
            <span>Our legitimate interests are:</span>
          </p>

          <ul>
            <li aria-level="1">
              <em>
                <span>
                  <span>
                    <span>
                      <span>Information Security</span>
                    </span>
                  </span>
                </span>
              </em>
              <em>:</em> We process contact information and automatically
              collected information in order to maintain an audit log of
              activities performed. We use this information pursuant to our
              legitimate interests in tracking usage, combating DDOS or other
              attacks, and removing or defending against malicious individuals
              or programs.&nbsp;
            </li>
            <li aria-level="1">
              <em>
                <span>
                  <span>
                    <span>
                      <span>
                        Operation and Improvement of our Website and Services
                      </span>
                    </span>
                  </span>
                </span>
              </em>
              <em>:</em> We process automatically collected information pursuant
              to our legitimate interest in operating and improving our Website
              and services.&nbsp;
            </li>
            <li aria-level="1">
              <em>
                <span>
                  <span>
                    <span>
                      <span>Audience Measurement and Marketing</span>
                    </span>
                  </span>
                </span>
              </em>
              <em>:</em> Pursuant to a user&rsquo;s consent, we use analytics
              and targeting cookies, and collect identifiers through such
              cookies, for purposes of audience measurement, analytics, audience
              reaction to our Website, creating relevant user experiences, and
              targeted marketing by us and other third parties.
            </li>
            <li aria-level="1">
              <em>
                <span>
                  <span>
                    <span>
                      <span>General Business Development and Management</span>
                    </span>
                  </span>
                </span>
              </em>
              <em>: </em>We process contact information and other information
              you voluntarily provide pursuant to our legitimate interest in
              creating and managing our relationships with European Individuals,
              including without limitation:
              <ul>
                <li aria-level="2">
                  <span>
                    <span>
                      <span>
                        <span>
                          To respond to inquiries from European
                          Individuals;&nbsp;
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li aria-level="2">
                  <span>
                    <span>
                      <span>
                        <span>
                          To provide European Individuals with information about
                          our products and services; and
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
                <li aria-level="2">
                  <span>
                    <span>
                      <span>
                        <span>
                          To assist European Individuals with any issues while
                          using the Website or our services.
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
            </li>
            <li aria-level="1">
              <em>
                <span>
                  <span>
                    <span>
                      <span>Direct Marketing</span>
                    </span>
                  </span>
                </span>
              </em>
              <em>:</em> Generally, we send email marketing to European
              Individuals pursuant to their consent. When you use the Website,
              email marketing may be sent to you pursuant to our legitimate
              interest in sending marketing communications to you in the context
              of such engagement.
            </li>
            <li aria-level="1">
              <em>
                <span>
                  <span>
                    <span>
                      <span>Protection of Rights</span>
                    </span>
                  </span>
                </span>
              </em>
              <em>: </em>We may disclose any categories of Personal Data to
              respond to claims of violation of third party rights or to enforce
              and protect our rights.
            </li>
          </ul>

          <p>&nbsp;</p>

          <p>
            <span>
              <strong>d) We Have a Legal Obligation</strong>
            </span>
          </p>

          <p>
            <span>
              We may be required to disclose Personal Data in response to lawful
              requests by public authorities, including for the purpose of
              meeting national security or law enforcement requirements. We may
              also disclose Personal Data to other third parties when compelled
              to do so by government authorities or required by law or
              regulation including, but not limited to, in response to court
              orders and subpoenas.
            </span>
          </p>

          <p>
            <span>
              <strong>4. How we Share Your Personal Data</strong>
            </span>
          </p>

          <p>
            <span>
              Section 4 of the Privacy Policy explains how we share your
              Personal Data with third parties.
            </span>
          </p>

          <p>
            <span>
              <strong>5. How we Store and Protect Your Personal Data</strong>
            </span>
          </p>

          <p>
            <span>
              We use commercially reasonable administrative, technical, and
              physical safeguards to protect your Personal Data from loss,
              misuse, and unauthorized access, disclosure, alteration, or
              destruction, for which we take into account the nature of the
              Personal Data, its processing, and the threats posed to it.
              Unfortunately, no data transmission or storage system can be
              guaranteed to be secure at all times. If you have reason to
              believe that your interaction with us is no longer secure, please
              immediately notify us via email at admin@ctrees.org
            </span>
            <span> </span>
            <span>with the subject line &ldquo;GDPR Request.&rdquo;</span>
          </p>

          <p>
            <span>
              We retain your Personal Data for as long as needed to fulfill the
              purposes for which we obtained it, as further described in this
              Privacy Policy. We will only keep your Personal Data for as long
              as allowed or required by law.
            </span>
          </p>

          <p>
            <span>
              <strong>6. Your Data Protection Rights</strong>
            </span>
          </p>

          <p>
            <span>
              You have certain rights with respect to your Personal Data,
              including those set forth below. For more information about these
              rights, or to submit a request, please email us at
              admin@ctrees.org
            </span>
            <span> </span>
            <span>
              with the subject line &ldquo;GDPR Request.&rdquo;&nbsp;You are not
              required to pay any charge for exercising your rights. If you make
              a request, we have one month to respond to you. Please note that
              in some circumstances, we may not be able to fully comply with
              your request, such as if it is frivolous or extremely impractical,
              if it jeopardizes the rights of others, or if it is not required
              by law, but in those circumstances, we will still respond to
              notify you of such a decision. In some cases, we may also need you
              to provide us with additional information, which may include
              Personal Data, if necessary to verify your identity and the nature
              of your request.
            </span>
          </p>

          <p>&nbsp;</p>

          <ul>
            <li aria-level="1">
              <span>
                <strong>Right of access</strong>
              </span>
              : You can request more information about the Personal Data we hold
              about you and request a copy of such Personal Data.&nbsp;
            </li>
            <li aria-level="1">
              <span>
                <strong>Right to rectification</strong>
              </span>
              : If you believe that any Personal Data we are holding about you
              is incorrect or incomplete, you can request that we correct or
              supplement such data.&nbsp;
            </li>
            <li aria-level="1">
              <span>
                <strong>Right to erasure</strong>
              </span>
              : You can request that we erase some or all of your Personal Data
              from our systems.&nbsp;
            </li>
            <li aria-level="1">
              <span>
                <strong>Right to restriction of processing</strong>
              </span>
              : You have the right to ask us to restrict the processing of your
              Personal Data.&nbsp;
            </li>
            <li aria-level="1">
              <span>
                <strong>Right to object to processing</strong>
              </span>
              : You have the the right to object to the processing of your
              Personal Data in certain circumstances.
            </li>
            <li aria-level="1">
              <span>
                <strong>Right to data portability</strong>
              </span>
              : You can ask for a copy of your Personal Data in a
              machine-readable format. You can also request that we transmit the
              data to another controller where technically feasible.
            </li>
            <li aria-level="1">
              <span>
                <strong>Right to withdraw consent</strong>
              </span>
              : If we are processing your Personal Data based on your consent
              (as indicated at the time of collection of such data), you have
              the right to withdraw your consent at any time. Please note,
              however, that if you exercise this right, you may have to then
              provide express consent on a case-by-case basis for the use or
              disclosure of certain of your Personal Data, if such use or
              disclosure is necessary to enable you to utilize some or all of
              our Services.
            </li>
            <li aria-level="1">
              <span>
                <strong>
                  Objecting to Legitimate Interest/Direct Marketing
                </strong>
              </span>
              : You may object to Personal Data processed pursuant to our
              legitimate interest. In such case, we will no longer process your
              Personal Data unless we can demonstrate appropriate, overriding
              legitimate grounds for the processing or if needed for the
              establishment, exercise, or defense of legal claims. You may also
              object at any time to processing of your Personal Data for direct
              marketing purposes by clicking &ldquo;Unsubscribe&rdquo; within an
              automated marketing email or by submitting your request to
              admin@ctrees.org with the subject line &ldquo;GDPR Request.&rdquo;
              In such case, your Personal Data will no longer be used for that
              purpose.
            </li>
          </ul>

          <p>
            <span>
              We will respond to all requests we receive from individuals
              wishing to exercise their data protection rights in accordance
              with applicable data protection laws. We reserve the right to keep
              any information in our archives that we deem necessary to comply
              with our legal obligations, resolve disputes and enforce our
              agreements. Please note that all of these rights are subject to
              applicable exemptions and restrictions, and are not absolute
              rights. If we need to rely on these exemptions or restrictions, we
              will provide this information to you in our response.&nbsp;
            </span>
          </p>

          <p>
            <span>
              <strong>7. How to Complain</strong>
            </span>
          </p>

          <p>
            <span>
              If you have any concerns about our use of your Personal Data, you
              can make a complaint to us at admin@ctrees.org with the subject
              line &ldquo;GDPR Request.&rdquo;
            </span>
          </p>

          <p>
            <span>
              You also have the right to lodge a complaint about the processing
              of your personal data with a supervisory authority of the European
              state where you work or live or where any alleged infringement of
              data protection laws occurred. A list of most of the supervisory
              authorities can be found here:{" "}
            </span>
            <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">
              http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
            </a>
            <span>.</span>
          </p>

          <p>
            <span>
              <strong>8. Changes to Our Organization</strong>
            </span>
          </p>

          <p>
            <span>
              As we grow and develop our organization, as well as in the event
              of a merger, reorganization, dissolution, or similar corporate
              event, or the sale of all or substantially all of our assets, the
              information that we have collected, including Personal Data, may
              be transferred to the organization we join, merge with, or in
              other forms cooperate with, or the surviving or acquiring entity.
              All such transfers shall be subject to our commitments with
              respect to the privacy and confidentiality of such Personal Data
              as set forth in this GDPR Notice.&nbsp;
            </span>
          </p>

          <p>
            <span>
              <strong>9. Updates to this GDPR Notice</strong>
            </span>
          </p>

          <p>
            <span>
              If, in the future, we intend to process your Personal Data for a
              purpose other than that which it was collected, we will provide
              you with information on that purpose and any other relevant
              information at a reasonable time prior to such processing. After
              such time, the relevant information relating to such processing
              activity will be revised or added appropriately within this GDPR
              Notice, and the &ldquo;Last Update&rdquo; at the top of this page
              will be updated accordingly.&nbsp;
            </span>
          </p>

          <p>
            <span>
              <strong>10. Our Contact Information</strong>
            </span>
          </p>

          <p>
            <span>
              Please reach out to admin@ctrees.org for any questions,
              complaints, or requests regarding this GDPR Notice, and include in
              the subject line &ldquo;GDPR Request.&rdquo;
            </span>
          </p>
        </div>
      </div>
    </div>
  );
}
