import { newsFilterOptions } from "@helpers/constants";
import { routesFlat } from "@routes";
import { Link } from "react-router-dom";
import * as styles from './styles'

export default function NoCard({ product }) {
    return (
        <div className="my-4 flex">
            <div className={styles.leftCard}></div>
            <div className={styles.centerCard}>
                <div>
                    {newsFilterOptions.product[product]} Articles Coming Soon
                </div>
                <Link to={routesFlat.news}>
                    <div className={styles.linkText}>Read Latest</div>
                </Link>
            </div>
            <div className={styles.rightCard}></div>
        </div>
    );
}