import Lottie from "react-lottie";
import { defaultOptions } from "@assets/lottie";
import { Button, HeroOverlay } from "@components";
import * as styles from "./styles";

export default function SolutionHero({
    animationData,
    title,
    subheader,
    link,
    bgImg,
}) {
    const lottieOptions = {
        ...defaultOptions,
        animationData,
    };
    return (
        <div className={styles.parentContainer}>
            <HeroOverlay img={bgImg} />
            <div className={styles.animationContainer}>
                <Lottie options={lottieOptions} height={"auto"} width={500} />
            </div>
            <div className={styles.textContainer}>
                <div className="text-4xl font-bold md:text-6xl">{title}</div>
                <div className={styles.subheader}>{subheader}</div>
                <Button to={link} className="w-[200px]">
                    Explore Data
                </Button>
            </div>
        </div>
    );
}
