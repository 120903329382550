export default function BarChartIcon() {
    return (
        <svg
            width="12"
            height="13"
            viewBox="0 0 12 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9 12.0742V6.07422H12V12.0742H9ZM4.5 12.0742V0.0742188H7.5V12.0742H4.5ZM0 12.0742V4.07422H3V12.0742H0Z"
                fill="#46CE84"
            />
        </svg>
    );
}
