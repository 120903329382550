export default function TargetsAndLines({ className, alt }) {
    const s3ImageUrl = "https://ctrees-website.s3.amazonaws.com/webv3/solutions/beats/lines.png";

    return (
        <div role="img" aria-label={alt} className={`relative w-full h-full ${className}`}>
            <img src={s3ImageUrl} alt="lines" className="absolute inset-0 w-full h-full object-cover" />
            <svg
                className="absolute inset-0 w-full h-full p-4"
                viewBox="0 0 413 440"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                    cx="354.959"
                    cy="322.739"
                    r="37.2676"
                    stroke="#DFF841"
                    strokeWidth="2"
                />
                <circle cx="354.96" cy="322.736" r="57.0927" stroke="#DFF841" />
                <circle
                    cx="354.957"
                    cy="322.735"
                    r="7.14194"
                    transform="rotate(-27.1602 354.957 322.735)"
                    fill="#DFF841"
                    stroke="#DFF841"
                    strokeWidth="1.71613"
                />
                <circle
                    cx="206.521"
                    cy="90.9419"
                    r="90.3375"
                    stroke="#FF6B6B"
                />
                <circle
                    cx="206.522"
                    cy="90.9428"
                    r="44.9243"
                    stroke="#FF6B6B"
                    strokeWidth="2"
                />
                <circle
                    cx="206.52"
                    cy="90.9416"
                    r="7.14194"
                    transform="rotate(-27.1602 206.52 90.9416)"
                    fill="#FF6B6B"
                    stroke="#FF6B6B"
                    strokeWidth="1.71613"
                />
                <circle
                    cx="36.1245"
                    cy="403.385"
                    r="25.6166"
                    stroke="#46CE84"
                    strokeWidth="2"
                />
                <circle
                    cx="36.1241"
                    cy="403.389"
                    r="35.5342"
                    stroke="#46CE84"
                />
                <circle
                    cx="36.1213"
                    cy="403.391"
                    r="7.14194"
                    transform="rotate(-27.1602 36.1213 403.391)"
                    fill="#46CE84"
                    stroke="#46CE84"
                    strokeWidth="1.71613"
                />
            </svg>
        </div>
    );
}