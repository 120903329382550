import {
    TargetsAndGrid,
    RangeLineChart,
    StackedLineBarChart,
} from "@assets/beats/solutions";

export const metaDataHeader = {
    title: "",
    desc: "",
    img: "",
};

export const latest = [
    {
        title: "CTrees presents on alerts for jurisdictional monitoring at AGU24",
        date: "12.11.24",
        link: "/news/AGU24",
    },
    {
        title: "CTrees hosts panel on jurisdictional finance at Climate Week NYC",
        date: "9.24.24",
        link: "/news/nycw2024-ctreespanel",
    },
    {
        title: "CTrees selected as data provider for Verra REDD methodology",
        date: "8.17.23",
        link: "/news/ctrees-verra-redd-data",
    },
];

export const hero = {
    title: "Carbon Markets",
    subheader:
        "CTrees provides carbon market actors with scientific insights and data, supporting private investment in nature protection.",
    bgImg: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/hero/carbon-markets.png",
};

export const firstBeat = {
    title: "Solving for additionality and integrity",
    subheader:
        "<p>Carbon markets allow the private sector to support global efforts to reduce emissions related to forestry and land use activities. But questions about the real impact of nature carbon credits have stalled investments.</p>" +
        "<p class='mt-6'>The latest science on the additionality of nature-based solutions can boost the integrity of carbon credits, ensuring reliable investment options for companies interested in protecting nature.</p>",
    img: <RangeLineChart className={"h-full w-3/5"} />,
};
export const secondBeat = {
    title: "Verified results for projects and jurisdictions",
    subheader:
        "<p>CTrees' scientific data on carbon and activity is tailored to support both the supply and demand sides of nature-based carbon credits.</p></br>" +
        "<p class='mb-6'>Our geospatial and historical datasets provide:</p>" +
        "<ul class='list-disc pl-8'><li>Baseline evaluation</li><li>Estimates of emissions and removals</li><li>Assessments of additionality, permanence, and leakage</li><li>Evaluation of projects and jurisdictional programs</li></ul>",
    img: <TargetsAndGrid />,
    bg: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/beats/carbon-markets.png",
    textRight: true,
};
export const thirdBeat = {
    title: "Total coverage for total accountability",
    subheader:
        "<p class='mb-6'>CTrees offers MRV for major voluntary carbon market standards, including Verra, Architecture for REDD+ Transactions (ART), and Climate Action Reserve (CAR), encompassing:</p>" +
        "<ul class='list-disc pl-8 pb-8'><li>Since 2023, CTrees has provided jurisdictional data to Verra for its avoided unplanned deforestation methodology (VM0048).</li>" +
        "<li>In 2024, CTrees was selected as a data provider for Wildlife Conservation Society’s high-integrity forests (HIFOR) initiative.</li>" +
        "<li>CTrees is developing data platforms for any organization to monitor project and jurisdictional carbon programs, for release in 2025.</li>" +
        "<li>CTrees is collaborating with partner NGOs to develop and improve scientific methodologies for improved forest management (IFM), afforestation, reforestation, revegetation (ARR), and blue carbon & sustainable management of mangroves. </li></ul>" +
        "<p>CTrees actively collaborates with these standards to continually improve methodologies using scientific approaches and data.</p>",
    img: <StackedLineBarChart className={"h-4/5 md:h-3/5 w-3/5"} />,
};

export const dataCards = [
    {
        img: "https://ctrees-website.s3.us-west-2.amazonaws.com/news/23_08+Verra+consolidated+REDD.jpg",
        imgAlt: "",
        title: "Data for Verra REDD+ Methodology",
        subheader:
            "Since 2023, CTrees has provided jurisdictional data to Verra for its consolidated REDD+ methodology (VM0048).",
        link: "/news/ctrees-verra-redd-data",
        linkDisplayText: "learn more",
    },
    {
        img: "https://ctrees-website.s3.us-west-2.amazonaws.com/news/23_11+LUCA+update+Brazilian+Amazon.png",
        imgAlt: "",
        title: "Land Use Change Alerts for Projects",
        subheader:
            "In Nov. 2023, CTrees added a feature to analyze alerts for any project boundary.",
        link: "/news/LUCA-update-Nov-2023",
        linkDisplayText: "learn more",
    },
    {
        img: "https://ctrees-website.s3.us-west-2.amazonaws.com/news/24_11+JMRV+Platform+CA+Screenshot.png",
        imgAlt: "",
        title: "Jurisdictional MRV",
        subheader: "In Nov. 2024, CTrees updated its data on carbon stocks, emissions, and removals for all jurisdictions globally.",
        link: "/jmrv",
        linkDisplayText: "access data",
    },
];

export const dataSubheader = "Access CTrees data and insights for carbon markets MRV";
