import { useState, useEffect } from "react";
import clsx from "clsx";

import * as styles from "../styles";
import { LinkText } from "..";

export default function RightCol({ tabContent }) {
    const [readMore, setReadMore] = useState(true);

    useEffect(() => {
        const clampedElement = document.querySelector(".methodology");
        if (isLineClampApplied(clampedElement)) {
            setReadMore(true);
        }
    }, []);

    return (
        <div className={styles.colContainer + " ml-10"}>
            <div className={styles.featTitle}>methodology</div>
            <div
                className={clsx(
                    (readMore ? " line-clamp-[10]" : "") + " methodology",
                )}
                dangerouslySetInnerHTML={{
                    __html: tabContent.method,
                }}
            />

            <div
                className="cursor-pointer text-black/50 hover:text-green"
                onClick={() => setReadMore(!readMore)}
            >
                Read {readMore ? "more" : "less"}
                <div className="w-[80px] border-b-[1px] border-b-green" />
            </div>

            <div className={styles.featTitle}>references</div>
            {tabContent.references.map((reference) => (
                <div className="mb-4">
                    {reference.author && (
                        <div className="text-sm">
                            {reference.author}
                            <LinkText
                                to={reference.link}
                                className="-mt-2 text-sm"
                            >
                                {reference.linkText}
                            </LinkText>
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

function isLineClampApplied(element) {
    return element.scrollHeight > element.clientHeight;
}
