import Lottie from "react-lottie";
import clsx from "clsx";
import * as lottie from "@assets/lottie";
import * as global from "@components";
import { routesFlat } from "@routes";
import * as components from "./components";

export default function SolutionTemplate({ constants, animationData }) {
    const heroProps = {
        ...constants.hero,
        animationData,
        link: routesFlat.products,
    };
    const dataProps = {
        cards: constants.dataCards,
        subheader: constants.dataSubheader,
    };
    const lottieOptions = {
        ...lottie.defaultOptions,
        animationData: lottie.Line,
    };

    return (
        <>
            {/* HEADER */}
            <global.MetaTagHeader {...constants.metaDataHeader} />
            <components.Hero {...heroProps} />
            <components.LatestMarquee articles={constants.latest || []} />
            <div className="mt-10 md:hidden">
                <Lottie options={lottieOptions} height={200} />
            </div>

            {/* TEXT IMG BEATS */}
            <components.ImgText {...constants.firstBeat} />
            <global.DottedDivider className={dividerStyle} />
            <components.ImgGreenText {...constants.secondBeat} />
            <global.DottedDivider className={dividerStyle} />
            <components.ImgText {...constants.thirdBeat} />
            <global.DottedDivider className={dividerStyle} />

            <div className="mt-20">
                <global.PartnerWithCTrees />
            </div>
            {/* DATA CAROUSEL */}
            <div className="mt-20 md:hidden">
                <Lottie options={lottieOptions} height={200} />
            </div>
            <global.Data {...dataProps} />

            {/* PARTNER WITH CTREES */}
            <div className="mb-20 md:hidden">
                <Lottie options={lottieOptions} height={200} />
            </div>

            <global.StayConnected />
        </>
    );
}

const dividerStyle = clsx("mx-10 my-8 md:hidden");
