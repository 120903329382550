const s3Bucket = `https://ctrees-website.s3.amazonaws.com/staff_photos/`;
const violet = `${s3Bucket}vmoon.jpg`;
const aleena = `${s3Bucket}aashary.jpg`;
const adugna = `${s3Bucket}amullissa.jpg`;
const daniel = `${s3Bucket}dmelling.jpg`;
const fiona = `${s3Bucket}fosborn.jpg`;
const fabien = `${s3Bucket}fwagner.jpg`;
const griffin = `${s3Bucket}gcarter.jpg`;
const ken = `${s3Bucket}kmatsunaga.jpg`;
const labien = `${s3Bucket}lsagang.jpg`;
const naomi = `${s3Bucket}nprovost.jpg`;
const ricardo = `${s3Bucket}rsilva.jpg`;
const shivraj = `${s3Bucket}sgill.jpg`;
const shuang = `${s3Bucket}sli.jpg`;
const vaiman = `${s3Bucket}vshum.jpg`;
const sassanImg = `${s3Bucket}ssaatchi.jpg`;
const susana = `${s3Bucket}stran.jpeg`;
const yan = `${s3Bucket}yyang.jpg`;
const zhihua = `${s3Bucket}zliu.jpg`;
const nich = `${s3Bucket}nkwon.jpg`;
const stephanie = `${s3Bucket}sgeorge.jpg`;
const ray = `${s3Bucket}rsong.jpg`;
const phil = `${s3Bucket}pdecola.jpg`;
const javiera = `${s3Bucket}jcanales.jpeg`;
const stephen = `${s3Bucket}shagen.JPG`;
const cynthia = `${s3Bucket}ccreze.jpeg`;
const dee = `${s3Bucket}dlawrence.jpg`;
const caroline = `${s3Bucket}cdebossart.jpg`;
const william = `${s3Bucket}wboyd.jpg`;
const liz = `${s3Bucket}landerson.jpg`;
const aijing = `${s3Bucket}ali.jpg`;
const lorena = `${s3Bucket}lalves.jpg`;
const xinbo = `${s3Bucket}xlu.jpg`;
const frank = `${s3Bucket}fmwangi.jpg`;
const rachel = `${s3Bucket}rkovinsky.png`;
const lee = `${s3Bucket}lwhite.jpg`;

export const teamFunctions = {
    leadership: { display: "leadership", id: "leadership" },
    tech: { display: "technology", id: "tech" },
    science: { display: "science", id: "science" },
    market: { display: "marketing", id: "market" },
    op: { display: "operations", id: "op" },
};

export const altFunctions = {
    board: { display: "board of directors", id: "board" },
    partners: { display: "scientific partners", id: "partners" },
    advisors: { display: "advisors", id: "advisors" },
};

export const leadership = [
    {
        name: "Sassan Saatchi",
        img: sassanImg,
        func: teamFunctions.leadership,
        title: "Co-founder & CEO",
        bio: [
            "Sassan Saatchi, Ph.D. is co-founder and CEO of CTrees, a nonprofit that tracks carbon in every tree on the planet and provides data to governments, companies, and organizations seeking to reduce emissions from deforestation and degradation and restore forests at all scales. ",
            "Saatchi is a senior scientist at NASA Jet Propulsion Laboratory, California Institute of Technology and an adjunct professor at the Center for Tropical Research at the UCLA Institute of the Environment and Sustainability. His present research interests include the global carbon cycle, in particular, forest biomass and carbon dynamics; land use and land cover change; forest structure and regeneration; and the impact of climate change and variability on global forest function and resilience. He is currently directing several interdisciplinary research projects on the carbon cycling of tropical forests, particularly in the Amazon and Congo Basin. For NASA's NISAR and ESA's BIOMASS satellite missions, he is developing algorithms to retrieve changes of vegetation above ground biomass across different landscapes and ecoregions.",
            "Saatchi received a Ph.D. degree from George Washington University with a concentration in electrophysics and applied mathematics. ",
        ],
    },
    {
        name: "Stephen Hagen",
        img: stephen,
        func: teamFunctions.leadership,
        title: "COO",
        bio: [
            "Stephen Hagen, Ph.D., is chief operating officer (COO) at CTrees, a nonprofit in Pasadena that tracks carbon in every tree on the planet, and provides data to governments, businesses, and organizations seeking to protect and restore forests and other ecosystems on land. As COO, Hagen will manage CTrees’ data and research initiatives for monitoring, reporting, and verification of natural climate solutions. ",
            "Hagen joined CTrees in 2022 as head of nature-based solutions and lead scientist for the DeVine project, a joint partnership with Conservation International and CTrees to monitor management of lianas, vines in tropical forests that inhibit regrowth of forests. ",
            "Hagen has more than 20 years of experience in remote sensing science and applied data solutions for forests and ecosystems. Most recently, he served as chief research officer at Regrow Ag, a science and technology company that helps companies in the agricultural sector to reduce emissions and adapt to climate change. ",
            "Hagen previously served as director of measurement and modeling at Applied GeoSolutions (AGS), a science company offering data solutions to governments, businesses, and NGOs to monitor agriculture, forests, water quality, and land use change. At AGS, Hagen spearheaded remote sensing research focused on precision agriculture. Hagen later played a pivotal role in the establishment and development of Dagan, a soil health and ecosystem services company, where Hagen served as senior scientist and COO. ",
            "Hagen earned a Ph.D. in earth and environmental sciences from the University of New Hampshire in 2006. Hagen’s scientific research has focused on building machine learning and remote sensing-based tools to map and understand the effects of land management and conservation.",
        ],
    },
    {
        name: "Aleena Ashary",
        img: aleena,
        func: teamFunctions.leadership,
        title: "Head of Product",
        bio: [
            "As Head of Product at CTrees, Aleena leads teams to develop innovative and data-driven products that tackle one of the biggest global challenges: climate change.",
            "With a strong foundation in machine learning (ML), AI, and product strategy, Aleena helps transform satellite data into actionable insights for use by policymakers, governments, and climate advocates alike.",
            "Leveraging her past product management expertise, Aleena drives the development of data platforms that utilize cutting-edge AI and deep learning models to inform decisions around forest conservation, deforestation, and carbon management. She has extensive experience with product roadmapping, managing cross-functional collaboration, and leading development teams to build scalable and impact-oriented solutions.",
            "Aleena comes to CTrees with a background in ML and data science. Previously, she worked as a consultant at Deloitte, where she focused on building data strategies and implementing AI and analytics solutions for Fortune 500/FTSE 100 companies. ",
            "Aleena’s background allows her to bridge the technical and strategic elements of her current work, leading teams that deliver products defined by both scientific rigor and real-world application.",
        ],
    },
    {
        name: "Daniel Melling",
        img: daniel,
        func: teamFunctions.leadership,
        title: "Head of Marketing",
        bio: [
            "Daniel Melling is head of marketing at CTrees, where he grows use of CTrees data products through solution design, partnership development, and communication. Daniel frequently represents CTrees at international conferences, speaking at or moderating panels at North American Carbon World, IETA Latin America Climate Summit, UNFCCC COP27, Climate Week NYC, the Governors’ Climate & Forest Task Force annual meetings, and more. Daniel also supports CTrees data product development through market research, analysis, and user discovery. Daniel joined CTrees in 2022 as its first employee (with Aleena Ashary, head of product) and has been a member of CTrees’ core leadership team since its launch. ",
            "Daniel has more than 12 years of experience building innovative climate change programs at the intersection of policy, technology, and business. Previously, Daniel served as communications manager at the Emmett Institute on Climate Change and the Environment at UCLA School of Law, where he worked alongside a dozen faculty members to elevate the program’s U.S. News ranking in environmental law from #5 to #1 in the country. Daniel also served in communications roles at World Resources Institute, a nonprofit in Washington, D.C., where he supported media relations, CEO presentations, and major initiatives such as Global Forest Watch, Renewable Energy Buyers Alliance (now Clean Energy Buyers Association), New Climate Economy, among others. ",
            "Daniel holds an MBA from UCLA Anderson School of Management and a Bachelor’s degree in history, magna cum laude, from UCLA. Outside CTrees, he swims with the West Hollywood Aquatics masters program, and serves on the steering committee of the Environment + Justice Donor Circle at Liberty Hill Foundation, a philanthropy focused on social change in the Los Angeles region.  ",
        ],
    },
    {
        name: "Rami Assad",
        img: sassanImg,
        func: teamFunctions.leadership,
        title: "Head of Finance & Administration",
        bio: [
            "With over 15 years of experience as a nonprofit finance professional, Rami is dedicated to advancing missions that promote environmental awareness.",
            "As Head of Finance & Administration at CTrees, Rami oversees all accounting and financial reporting functions across the organization – ensuring transparency, compliance, and efficient use of the organization’s resources.",
            "Prior to joining CTrees, Rami was the Regional Director of Finance & Administration at EcoPeace Middle East, an environmental peacebuilding organization.",
            "Rami is based in Southern California. He holds an MBA from Loyola Marymount University in Los Angeles and is currently pursuing a CPA license to further enhance his financial expertise.",
        ],
    },
    {
        name: "Fabien Wagner",
        img: fabien,
        func: teamFunctions.leadership,
        title: "",
    },
    {
        name: "Raymond Song",
        img: ray,
        func: teamFunctions.leadership,
        title: "",
    },
    {
        name: "Phil Decola",
        img: phil,
        func: teamFunctions.leadership,
        title: "Co-founder",
    },
];

export const science = [
    { name: "Sassan Saatchi", img: sassanImg, func: teamFunctions.leadership },
    {
        name: "Fabien Wagner",
        img: fabien,
        func: teamFunctions.leadership,
    },
    { name: "Yan Yang", img: yan, func: teamFunctions.science },
    { name: "Adugna Mullissa", img: adugna, func: teamFunctions.science },
    {
        name: "Ricardo Dalagnol da Silva",
        img: ricardo,
        func: teamFunctions.science,
    },

    { name: "Le Bienfaiteur Sagang", img: labien, func: teamFunctions.science },
    { name: "Stephanie George", img: stephanie, func: teamFunctions.science },
    { name: "Cynthia Creze", img: cynthia, func: teamFunctions.science },
    { name: "Zhihua Liu", img: zhihua, func: teamFunctions.science },
    { name: "Shuang Li", img: shuang, func: teamFunctions.science },
    { name: "Javiera Canales", img: javiera, func: teamFunctions.science },
    { name: "Griffin Carter", img: griffin, func: teamFunctions.science },
    { name: "Fiona Osborn", img: fiona, func: teamFunctions.science },
    { name: "Lorena Alves", img: lorena, func: teamFunctions.science },
    { name: "Ken Matsunaga", img: ken, func: teamFunctions.science },
];

export const tech = [
    {
        name: "Aleena Ashary",
        img: aleena,
        func: teamFunctions.leadership,
    },
    { name: "Naomi Provost ", img: naomi, func: teamFunctions.tech },
    { name: "Violet Moon", img: violet, func: teamFunctions.tech },
    { name: "Liz Anderson", img: liz, func: teamFunctions.tech },
    { name: "Xinbo Lu", img: xinbo, func: teamFunctions.tech },
    { name: "Shivraj Gill", img: shivraj, func: teamFunctions.tech },
    { name: "Nicholas Kwon", img: nich, func: teamFunctions.tech },
    { name: "Frank Mwangi", img: frank, func: teamFunctions.tech },
];

export const op = [
    { name: "Rami Assad", img: sassanImg, func: teamFunctions.leadership },
    { name: "Susana Tran", img: susana, func: teamFunctions.op },
    { name: "Vaiman Shum", img: vaiman, func: teamFunctions.op },
];

export const market = [
    { name: "Daniel Melling", img: daniel, func: teamFunctions.leadership },
    { name: "Raymond Song", img: ray, func: teamFunctions.leadership },
    { name: "Aijing Li", img: aijing, func: teamFunctions.market },
    { name: "Rachel Kovinsky", img: rachel, func: teamFunctions.market },
];
export const countries = {
    fr: "France",
    us: "USA",
    br: "Brazil",
    ne: "The Netherlands",
    it: "Italy",
    dn: "Denmark",
};

export const partners = {
    fr: [
        {
            name: "Philippe Ciais",
            title: "Co-founder and Research Director, Institut Pierre-Simon Laplace (IPSL)",
            img: "",
        },
        {
            name: "Jean-Pierre Wigneron",
            title: "Co-founder and Head, INRAE Remote Sensing Lab, Bordeaux",
            img: "",
        },
        { name: "Arthur Calvi", title: "Data Analyst" },
        { name: "Ibrahim Fayad", title: "Research Scientist" },
        { name: "Yu Feng", title: "Research Scientist" },
        { name: "Sami Jouaber", title: "Research Scientist" },
        { name: "Agnès Pellissier-Tanon", title: "Research Scientist" },
        { name: "François Ritter", title: "Research Scientist" },
        { name: "Martin Schwartz ", title: "Data Analyst" },
        { name: "Yidi Xu", title: "Research Scientist" },
    ],
    dn: [
        {
            name: "Martin Brandt",
            title: "Co-founder and Professor of Geography, University of Copenhagen",
            img: "",
        },
        {
            name: "Rasmus Fensholt",
            title: "Co-founder and Professor of Geography, University of Copenhagen",
            img: "",
        },
        { name: "Dimitri Gominski", title: "Research Scientist" },
        { name: "Sizhuo Li", title: "Research Scientist" },
        { name: "Siyu Liu", title: "Research Scientist" },
        { name: "Maurice Mugabowindekwe", title: "Research Scientist" },
        { name: "Florian Reiner", title: "Research Scientist" },
    ],
    br: [
        {
            name: "Luiz Aragão",
            title: "Senior Scientist Head of Earth Observation and Geoinformatics Division, National Institute for Space Research (INPE)",
            img: "",
        },
        { name: "Celso H.L. Silva Junior", title: "Research Scientist" },
        { name: "Liana Anderson", title: "Research Scientist" },
    ],
};

export const advisors = {
    us: [
        {
            name: "Susan Cook-Patton",
            title: "Senior Forest Restoration Scientist, The Nature Conservancy",
            local: "USA",
        },
        {
            name: "KC Cushman",
            title: "Postdoctoral Fellow, NASA JPL",
            local: "USA",
        },
        {
            name: "Grant Domke",
            title: "Forest Carbon Scientist, US Forest Service",
            local: "USA",
        },
        {
            name: "Samuel Favrichon",
            title: "Remote Sensing Scientist, Caltech/NASA JPL",
            local: "USA",
        },
        {
            name: "Ron McRoberts",
            title: "Forest Statistician, Raspberry Ridge Analytics",
            local: "USA",
        },
        {
            name: "Herman Shugart",
            title: "Professor Emeritus of Forest Ecology, University of Virginia",
            local: "USA",
        },

        {
            name: "Miles Silman",
            title: "Professor of Biology and Director, Center for Energy, Environment & Sustainability, Wake Forest University",
            local: "USA",
        },

        {
            name: "Compton Tucker",
            title: "Senior Scientist, NASA Goddard Space Flight Center",
            local: "USA",
        },
    ],
    fr: [
        {
            name: "Jerome Chave",
            title: "Laboratoire Évolution & Diversité Biologique, Université Toulouse Paul Sabatier",
            local: "France",
        },
        {
            name: "Alexandre d'Aspremont",
            title: "Professor of Computer Science, Department at Ecole Normale Superieure",
            local: "France",
        },
    ],
    br: [
        {
            name: "Thelma Krug",
            title: "Vice Chair, IPCC; Former Co-chair, IPCC Task Force on National Greenhouse Gas Inventories",
            local: "Brazil",
        },
    ],
    it: [
        {
            name: "Giacomo Grassi",
            title: "Senior Scientific Officer, Joint Research Centre of the European Commission ",
            local: "Italy",
        },
    ],
    ne: [
        {
            name: "Gert-Jan Nabuurs",
            title: "Professor European Forest Resources, Wageningen University and Research; Lead Scientist European Forest Resources, Wageningen Environmental Research (Alterra)",
            local: "The Netherlands",
        },
    ],
};

export const board = [
    {
        name: "Dee Lawrence",
        title: "Director and co-founder, High Tide Foundation",
        local: "USA",
        img: dee,
    },
    {
        name: "Caroline de Bossart",
        title: "Director, Neglected Climate Opportunities, Grantham Foundation for the Protection of the Environment",
        local: "USA",
        img: caroline,
    },
    {
        name: "William Boyd",
        title: "Professor of Law, UCLA School of Law; Professor, UCLA Institute of the Environment and Sustainability; Project Lead, Governors' Climate and Forests Task Force",
        local: "USA",
        img: william,
    },
    {
        name: "Lee White",
        title: "Special Envoy, Science Panel for the Congo Basin",
        local: "USA",
        img: lee,
    },
];

export const additional = [
    {
        name: "Sassan Saatchi",
        focus: "Forest Carbon Systems",
        title: "Senior Research Scientist, JPL/Caltech; Professor, UCLA Institute of the Environment and Sustainability",
        local: "USA",
    },
    {
        name: "Philippe Ciais",
        focus: "Climate and Carbon",
        title: "Research Director, Institut Pierre-Simon Laplace (IPSL)",
        local: "France",
    },
    {
        name: "Martin Brandt",
        focus: "Data Science and Analytics",
        title: "Professor, Geography, University of Copenhagen",
        local: "Denmark",
    },
    {
        name: "Phil Decola",
        focus: "GHG Inventory",
        title: "Co-chair, WMO Integrated Global GHG Information System",
        local: "USA",
    },
    {
        name: "Rasmus Fensholt",
        focus: "Data Science and Analytics",
        title: "Professor, Geography, University of Copenhagen",
        local: "Denmark ",
    },
    {
        name: "Jean-Pierre Wigneron",
        focus: "Remote Sensing Science",
        title: "Head, INRAE Remote Sensing Lab, Bordeaux",
        local: "France",
    },
];
