import clsx from "clsx";

export const bgImg = clsx(
    "absolute rounded-full h-[380px] object-cover w-full object-left",
);
export const parentContainer = clsx("flex w-full justify-around mt-20");
export const solutionContainer = clsx(
    "flex flex-col items-center max-w-[320px] relative",
);
export const title = clsx("text-2xl uppercase max-w-[220px] text-center mb-4");
export const lottie = clsx(
    "w-full justify-center flex flex-col rounded-full h-[400px]",
);
