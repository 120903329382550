import clsx from "clsx";
export const parent = clsx("flex w-[90%] flex-col mt-10 tab-scrollbar");
export const border = clsx("-mt-[2px] h-[2px] w-full bg-gray");
export const underline = clsx("mt-2 h-[2px] w-full bg-green");
export const tabContainer = clsx(
    "flex w-full z-10 flex text-lg justify-around uppercase overflow-x-auto tab-scrollbar",
);
export const tab = clsx(
    "text-nowrap text-md cursor-pointer px-4 lg:text-2xl whitespace-nowrap",
);
