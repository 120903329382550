import Sidebar from "../Sidebar";
import * as styles from "./styles";

export default function ProfileWithPhoto(props) {
    const photoProps = { img: props.img, name: props.name };
    const sidebarProps = props;
    return (
        <div className={styles.photoContainer}>
            <Photo {...photoProps} />
            <div className={styles.bioContainer}>
                <div className="flex items-center">
                    <div className={styles.name}>{props.name}</div>
                    {props.bio && <Sidebar {...sidebarProps} />}
                </div>
                <div className={styles.title}>
                    {props.title ? props.title : props.func.display}
                </div>
            </div>
        </div>
    );
}

export function Photo({ img, name }) {
    return (
        <div className={styles.img}>
            <img
                src={img}
                className="w-full rounded-sm grayscale"
                alt={`headshot of ${name}`}
            />
        </div>
    );
}
export function Profile({ name, title }) {
    return (
        <div className={styles.profileContainer}>
            <div className="flex flex-col text-sm ">
                <div className={styles.profileName}>{name}</div>
                <div className="text-black/50">{title}</div>
            </div>
        </div>
    );
}