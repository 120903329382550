export default function paginate(articles, numPerPage) {
    const paginated = [];
    let current = [];

    articles.forEach((post, i) => {
        current.push(post);

        // If max article count per page OR end of array, paginate.
        if (current.length === numPerPage || i === articles.length - 1) {
            paginated.push(current);
            current = [];
        }
    });

    return paginated;
}