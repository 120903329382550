import Skeleton from "react-loading-skeleton";

export default function Loading() {
    return (
        <div>
            <Skeleton className="mb-4 h-[50px]" count={1} />
            <div className="flex">
                <Skeleton className="ml-10 h-[100px] !w-[300px]" count={5} />
                <Skeleton className="ml-10 h-[515px] !w-[700px]" />
            </div>
        </div>
    );
}
