import clsx from "clsx";
import Wrapper from "../../template";

export default function YearSelector() {
    return (
        <Wrapper className={overlayStyle}>
            <svg
                className="h-8"
                viewBox="0 0 328 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g filter="url(#filter0_b_2981_11144)">
                    <rect
                        x="0.779297"
                        y="0.761719"
                        width="326.8"
                        height="36"
                        rx="18"
                        fill="black"
                        fill-opacity="0.8"
                    />
                    <path
                        d="M23.5792 18.7617L28.1792 23.3617L26.7792 24.7617L20.7792 18.7617L26.7792 12.7617L28.1792 14.1617L23.5792 18.7617Z"
                        fill="white"
                    />
                    <path
                        opacity="0.3"
                        d="M44.1792 6.51172V31.0117"
                        stroke="white"
                        stroke-width="1.55"
                    />
                    <path
                        opacity="0.4"
                        d="M53.5823 16.0417L55.2463 13.4017H56.1583L55.0863 15.6897C55.4543 15.8817 55.7423 16.2657 55.7423 16.7617C55.7423 17.4017 55.2143 17.9617 54.5583 17.9617C53.8703 17.9617 53.3743 17.4017 53.3743 16.7617C53.3743 16.5057 53.4383 16.2657 53.5823 16.0417ZM57.9179 24.7617L61.4219 20.6337C61.2459 20.6497 61.1179 20.6497 60.9739 20.6497C59.0699 20.6497 57.4219 18.9697 57.4219 17.0017C57.4219 14.8897 59.0379 13.2097 61.2619 13.2097C63.4699 13.2097 65.1179 14.8897 65.1179 16.9537C65.1179 18.1537 64.5419 19.0177 63.8379 19.8497L59.6779 24.7617H57.9179ZM61.2619 19.4337C62.6699 19.4337 63.7099 18.3297 63.7099 16.9857C63.7099 15.6417 62.6699 14.5377 61.2619 14.5377C59.8699 14.5377 58.8299 15.6417 58.8299 16.9857C58.8299 18.3297 59.8699 19.4337 61.2619 19.4337ZM70.6474 24.9217C68.2954 24.9217 66.6314 23.1777 66.6314 20.6497V17.4817C66.6314 14.9697 68.2954 13.2097 70.6474 13.2097C72.9834 13.2097 74.6794 14.9697 74.6794 17.4817V20.6497C74.6794 23.1777 72.9834 24.9217 70.6474 24.9217ZM70.6474 23.5777C72.1994 23.5777 73.3034 22.5057 73.3034 20.6497V17.4817C73.3034 15.6257 72.1994 14.5537 70.6474 14.5537C69.1114 14.5537 68.0074 15.6417 68.0074 17.4817V20.6497C68.0074 22.4897 69.1114 23.5777 70.6474 23.5777Z"
                        fill="white"
                    />
                    <path
                        d="M84.1792 6.51172V31.0117"
                        stroke="white"
                        stroke-opacity="0.3"
                        stroke-width="1.55"
                    />
                    <path
                        opacity="0.4"
                        d="M94.1448 16.0417L95.8088 13.4017H96.7208L95.6488 15.6897C96.0168 15.8817 96.3048 16.2657 96.3048 16.7617C96.3048 17.4017 95.7768 17.9617 95.1208 17.9617C94.4328 17.9617 93.9368 17.4017 93.9368 16.7617C93.9368 16.5057 94.0008 16.2657 94.1448 16.0417ZM98.4804 24.7617L101.984 20.6337C101.808 20.6497 101.68 20.6497 101.536 20.6497C99.6324 20.6497 97.9844 18.9697 97.9844 17.0017C97.9844 14.8897 99.6004 13.2097 101.824 13.2097C104.032 13.2097 105.68 14.8897 105.68 16.9537C105.68 18.1537 105.104 19.0177 104.4 19.8497L100.24 24.7617H98.4804ZM101.824 19.4337C103.232 19.4337 104.272 18.3297 104.272 16.9857C104.272 15.6417 103.232 14.5377 101.824 14.5377C100.432 14.5377 99.3924 15.6417 99.3924 16.9857C99.3924 18.3297 100.432 19.4337 101.824 19.4337ZM110.587 24.9217C108.539 24.9217 106.827 23.4337 106.779 21.6897H108.187C108.443 22.7777 109.371 23.5777 110.587 23.5777C111.995 23.5777 113.035 22.4897 113.035 21.1457C113.035 19.7857 112.059 18.6977 110.651 18.6977C109.675 18.6977 108.939 19.1617 108.507 19.8497L107.195 19.4657L108.363 13.4017H113.675V14.7457H109.467L108.843 17.9937C109.291 17.6737 109.979 17.4017 110.827 17.4017C112.955 17.4017 114.443 19.0817 114.443 21.3217C114.443 23.3217 112.795 24.9217 110.587 24.9217Z"
                        fill="white"
                    />
                    <path
                        opacity="0.3"
                        d="M124.179 6.51172V31.0117"
                        stroke="white"
                        stroke-width="1.55"
                    />
                    <path
                        opacity="0.4"
                        d="M133.067 16.0417L134.731 13.4017H135.643L134.571 15.6897C134.939 15.8817 135.227 16.2657 135.227 16.7617C135.227 17.4017 134.699 17.9617 134.043 17.9617C133.355 17.9617 132.859 17.4017 132.859 16.7617C132.859 16.5057 132.923 16.2657 133.067 16.0417ZM141.194 24.9217C138.842 24.9217 137.178 23.1777 137.178 20.6497V17.4817C137.178 14.9697 138.842 13.2097 141.194 13.2097C143.53 13.2097 145.226 14.9697 145.226 17.4817V20.6497C145.226 23.1777 143.53 24.9217 141.194 24.9217ZM141.194 23.5777C142.746 23.5777 143.85 22.5057 143.85 20.6497V17.4817C143.85 15.6257 142.746 14.5537 141.194 14.5537C139.658 14.5537 138.554 15.6417 138.554 17.4817V20.6497C138.554 22.4897 139.658 23.5777 141.194 23.5777ZM151.163 24.9217C148.811 24.9217 147.147 23.1777 147.147 20.6497V17.4817C147.147 14.9697 148.811 13.2097 151.163 13.2097C153.499 13.2097 155.195 14.9697 155.195 17.4817V20.6497C155.195 23.1777 153.499 24.9217 151.163 24.9217ZM151.163 23.5777C152.715 23.5777 153.819 22.5057 153.819 20.6497V17.4817C153.819 15.6257 152.715 14.5537 151.163 14.5537C149.627 14.5537 148.523 15.6417 148.523 17.4817V20.6497C148.523 22.4897 149.627 23.5777 151.163 23.5777Z"
                        fill="white"
                    />
                    <path
                        opacity="0.3"
                        d="M164.179 6.51172V31.0117"
                        stroke="white"
                        stroke-width="1.55"
                    />
                    <path
                        opacity="0.4"
                        d="M173.614 16.0417L175.278 13.4017H176.19L175.118 15.6897C175.486 15.8817 175.774 16.2657 175.774 16.7617C175.774 17.4017 175.246 17.9617 174.59 17.9617C173.902 17.9617 173.406 17.4017 173.406 16.7617C173.406 16.5057 173.47 16.2657 173.614 16.0417ZM181.741 24.9217C179.389 24.9217 177.725 23.1777 177.725 20.6497V17.4817C177.725 14.9697 179.389 13.2097 181.741 13.2097C184.077 13.2097 185.773 14.9697 185.773 17.4817V20.6497C185.773 23.1777 184.077 24.9217 181.741 24.9217ZM181.741 23.5777C183.293 23.5777 184.397 22.5057 184.397 20.6497V17.4817C184.397 15.6257 183.293 14.5537 181.741 14.5537C180.205 14.5537 179.101 15.6417 179.101 17.4817V20.6497C179.101 22.4897 180.205 23.5777 181.741 23.5777ZM191.118 24.9217C189.07 24.9217 187.358 23.4337 187.31 21.6897H188.718C188.974 22.7777 189.902 23.5777 191.118 23.5777C192.526 23.5777 193.566 22.4897 193.566 21.1457C193.566 19.7857 192.59 18.6977 191.182 18.6977C190.206 18.6977 189.47 19.1617 189.038 19.8497L187.726 19.4657L188.894 13.4017H194.206V14.7457H189.998L189.374 17.9937C189.822 17.6737 190.51 17.4017 191.358 17.4017C193.486 17.4017 194.974 19.0817 194.974 21.3217C194.974 23.3217 193.326 24.9217 191.118 24.9217Z"
                        fill="white"
                    />
                    <path
                        opacity="0.3"
                        d="M204.179 6.51172V31.0117"
                        stroke="white"
                        stroke-width="1.55"
                    />
                    <path
                        d="M215.449 16.0417L217.113 13.4017H218.025L216.953 15.6897C217.321 15.8817 217.609 16.2657 217.609 16.7617C217.609 17.4017 217.081 17.9617 216.425 17.9617C215.737 17.9617 215.241 17.4017 215.241 16.7617C215.241 16.5057 215.305 16.2657 215.449 16.0417ZM220.921 24.7617V14.7617H218.729L219.849 13.4017H222.297V24.7617H220.921ZM228.78 24.9217C226.428 24.9217 224.764 23.1777 224.764 20.6497V17.4817C224.764 14.9697 226.428 13.2097 228.78 13.2097C231.116 13.2097 232.812 14.9697 232.812 17.4817V20.6497C232.812 23.1777 231.116 24.9217 228.78 24.9217ZM228.78 23.5777C230.332 23.5777 231.436 22.5057 231.436 20.6497V17.4817C231.436 15.6257 230.332 14.5537 228.78 14.5537C227.244 14.5537 226.14 15.6417 226.14 17.4817V20.6497C226.14 22.4897 227.244 23.5777 228.78 23.5777Z"
                        fill="#DFF841"
                    />
                    <path
                        opacity="0.3"
                        d="M244.179 6.51172V31.0117"
                        stroke="white"
                        stroke-width="1.55"
                    />
                    <path
                        opacity="0.4"
                        d="M255.996 16.0417L257.66 13.4017H258.572L257.5 15.6897C257.868 15.8817 258.156 16.2657 258.156 16.7617C258.156 17.4017 257.628 17.9617 256.972 17.9617C256.284 17.9617 255.788 17.4017 255.788 16.7617C255.788 16.5057 255.852 16.2657 255.996 16.0417ZM261.468 24.7617V14.7617H259.276L260.396 13.4017H262.844V24.7617H261.468ZM268.735 24.9217C266.687 24.9217 264.975 23.4337 264.927 21.6897H266.335C266.591 22.7777 267.519 23.5777 268.735 23.5777C270.143 23.5777 271.183 22.4897 271.183 21.1457C271.183 19.7857 270.207 18.6977 268.799 18.6977C267.823 18.6977 267.087 19.1617 266.655 19.8497L265.343 19.4657L266.511 13.4017H271.823V14.7457H267.615L266.991 17.9937C267.439 17.6737 268.127 17.4017 268.975 17.4017C271.103 17.4017 272.591 19.0817 272.591 21.3217C272.591 23.3217 270.943 24.9217 268.735 24.9217Z"
                        fill="white"
                    />
                    <path
                        opacity="0.3"
                        d="M284.179 6.51172V31.0117"
                        stroke="white"
                        stroke-width="1.55"
                    />
                    <path
                        d="M304.779 18.7617L300.179 14.1617L301.579 12.7617L307.579 18.7617L301.579 24.7617L300.179 23.3617L304.779 18.7617Z"
                        fill="white"
                    />
                </g>
                <defs>
                    <filter
                        id="filter0_b_2981_11144"
                        x="-39.2207"
                        y="-39.2383"
                        width="406.8"
                        height="116"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                    >
                        <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                        />
                        <feGaussianBlur
                            in="BackgroundImageFix"
                            stdDeviation="20"
                        />
                        <feComposite
                            in2="SourceAlpha"
                            operator="in"
                            result="effect1_backgroundBlur_2981_11144"
                        />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_backgroundBlur_2981_11144"
                            result="shape"
                        />
                    </filter>
                </defs>
            </svg>
        </Wrapper>
    );
}

// bottom centerered
const overlayStyle = clsx("absolute bottom-4 left-1/2 -translate-x-1/2 transform px-10");