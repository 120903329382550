import { useState } from "react";
import * as constants from "../../constants";
import { UnderlinedSectionHeader, OpacityWrapper } from "@components";
import { ProfileWithPhoto, Profile, Tabs } from "./components";
import * as styles from "./styles";

export default function Team() {
    const [selectedTeamTab, setTeamTab] = useState(
        constants.teamFunctions.leadership,
    );
    const [selectedAltTab, setAltTab] = useState(constants.altFunctions.board);

    const teamTabs = {
        tabs: Object.values(constants.teamFunctions),
        selectedTab: selectedTeamTab.id,
        setTab: setTeamTab,
        id: "team",
    };
    const altTabs = {
        tabs: Object.values(constants.altFunctions),
        selectedTab: selectedAltTab.id,
        setTab: setAltTab,
        id: "alt",
    };

    return (
        <>
            <UnderlinedSectionHeader>Team</UnderlinedSectionHeader>
            <Tabs {...teamTabs} />
            <StaffSection
                id={selectedTeamTab.id}
                section={constants[selectedTeamTab.id]}
            />

            <Tabs {...altTabs} />
            <StaffSection
                id={selectedAltTab.id}
                section={constants[selectedAltTab.id]}
            />
        </>
    );
}

function StaffSection({ section, id }) {
    const isArray = Array.isArray(section);
    if (isArray) {
        return (
            <OpacityWrapper className={styles.staffContainer} key={id}>
                {section.map((person, i) =>
                    person.img ? (
                        <ProfileWithPhoto key={i} {...person} />
                    ) : (
                        <Profile key={i} {...person} />
                    ),
                )}{" "}
            </OpacityWrapper>
        );
    }

    return (
        <div className={styles.countryContainer}>
            {Object.entries(section).map(([countryCode, people]) => (
                <CountrySection
                    country={constants.countries[countryCode]}
                    people={people}
                />
            ))}
        </div>
    );
}

function CountrySection({ country, people }) {
    return (
        <div key={country} className={styles.countrySection}>
            <div className="mb-4 w-[200px] uppercase">
                <div className={styles.country}>{country}</div>
            </div>
            <div>
                {people.map((person, index) => (
                    <div className="text-wrap mb-4 lg:flex w-full" key={index}>
                        <div className="lg:w-[300px] font-bold">{person.name}</div>
                        <div className="lg:w-[300px] text-xs lg:text-sm text-black/50">
                            {person.title}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}
