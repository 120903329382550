export default function Arrow({ className }) {
    return (
        <svg
            className={className}
            width="56"
            height="49"
            viewBox="0 0 56 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_b_2981_11115)">
                <rect
                    y="48.1689"
                    width="48"
                    height="56"
                    rx="24"
                    transform="rotate(-90 0 48.1689)"
                    fill="black"
                    fill-opacity="0.75"
                />
                <path
                    d="M28.9 33.1689L26.05 26.1189L19 23.2689V21.8689L37 15.1689L30.3 33.1689H28.9ZM29.55 29.4689L33.6 18.5689L22.7 22.6189L27.6 24.5689L29.55 29.4689Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter
                    id="filter0_b_2981_11115"
                    x="-40"
                    y="-39.8311"
                    width="136"
                    height="128"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="20" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_2981_11115"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_2981_11115"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
}
