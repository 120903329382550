import clsx from "clsx";
import { OpacityWrapper } from "@components";
import { partners } from "@helpers/constants";
import { UnderlinedSectionHeader, Marquee } from "@components";

// CSS Marquee scroll credit: https://jackwhiting.co.uk/posts/creating-a-marquee-with-tailwind/

export default function Partners() {
    return (
        <OpacityWrapper className={parentContainerStyle}>
            <UnderlinedSectionHeader>partners</UnderlinedSectionHeader>
            <div className="relative flex w-full overflow-x-hidden">
                <Marquee>
                    <Icons />
                </Marquee>
            </div>
        </OpacityWrapper>
    );
}

const Icons = () => {
    return (
        <div className="mt-20 flex">
            {iconList.map((icon) => {
                const invert = icon?.includes("taskforce") ? " invert" : "";
                return (
                    <div
                        className={`${invert} flex h-auto w-[350px] justify-center`}
                        key={icon}
                    >
                        <img
                            src={icon}
                            className={"h-[100px] grayscale hover:grayscale-0"}
                            loading="lazy"
                        />
                    </div>
                );
            })}
        </div>
    );
};

const iconList = [
    partners.ageos,
    partners.cool,
    partners.crosswalk,
    partners.taskforce,
    partners.cll,
    partners.terra,
    partners.trace,
    partners.vp,
    partners.aso,
    partners.fs,
];

const parentContainerStyle = clsx(
    "my-20 flex flex-col items-center justify-center",
);
