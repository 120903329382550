import { motion } from "framer-motion";

export default function ScaleOnHoverDiv({
    children,
    className,
    onClick,
    role = "text",
}) {
    return (
        <motion.div
            whileHover={{ scale: 0.95 }}
            whileTap={{ scale: 0.9 }}
            className={className}
            onClick={onClick}
            role={role}
        >
            {children}
        </motion.div>
    );
}
