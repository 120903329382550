import clsx from "clsx";

export const name = clsx("block text-center text-4xl font-semibold tracking-widest uppercase");
export const nameContainer = clsx("flex flex-col items-center justify-center mt-4");
export const openBtn = clsx(
    "ml-1 h-[6px] rotate-[270deg] cursor-pointer hover:scale-105",
);
export const bio = clsx("flex flex-col w-full items-center mt-6");
export const overlay = clsx("overlay z-[60] fixed inset-0 bg-black bg-opacity-50");
export const sidebar = clsx(
    "fixed right-0 top-0 z-[60] h-full w-1/3 bg-white p-4 shadow-lg border-l-green border-l-[10px]",
);
export const title = clsx("text-black/50 uppercase mt-2 text-sm flex flex-col items-center")
export const titleLine = clsx("h-[1px] w-[90%] bg-black mt-[4px]")
export const contentContainer = clsx("mt-4 flex w-full flex-col items-center")