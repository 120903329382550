// Sample Data
const aboutURI = "https://ctrees-website.s3.us-west-2.amazonaws.com/webv3/about/"

export const teamImages = [
    {
        src: `${aboutURI}SassanSpeakingUCLARetreat.jpg`,
        desc: "Sassan Saatchi presents at a CTrees staff retreat in 2024.",
    },
    {
        src: `${aboutURI}LeBien_Gabon.jpg`,
        desc: "Le Bienfaiteur Sagang (center) leads a science workshop in Gabon in 2023.",
    },
    {
        src: `${aboutURI}AllStaff_UCLARetreat.jpg`,
        desc: "CTrees team at staff retreat in 2024.",
    },
    {
        src: `${aboutURI}Liz_Retreat.jpeg`,
        desc: "Liz Anderson presents at a CTrees staff retreat in 2024.",
    },
    {
        src: `${aboutURI}SassanPresentingGCF.jpg`,
        desc: "Sassan Saatchi (left) demonstrates CTrees at the Governors’ Climate & Forests Task Force Annual Meeting in 2023.",
    },
    
];