import clsx from "clsx";

export default function Wrapper({ className = alignOnBottom, alt, children }) {
    return (
        <div
            role={alt ? "img" : "presentation"}
            aria-label={alt || undefined}
            aria-hidden={alt ? undefined : "true"}
            className={className}
        >
            {children}
        </div>
    );
}

// initial placement is along the bottom of the parent like controls on a map
const alignOnBottom = clsx("absolute bottom-4 flex flex-row justify-between items-center w-full px-4");