import Wrapper from "../../template";
import AlertSwitch from "./AlertSwitch";
import Arrow from "./Arrow";

export default function Alert() {
    return (
        <Wrapper>
            <Arrow />
            <AlertSwitch />
        </Wrapper>
    );
}

