import { Link } from "react-router-dom";
import { routesFlat } from "@routes";

export default function ContactUs() {
    return (
        <div className={containerStyle}>
            <p>Want to reach out?</p>
            <p>
                <Link className="font-bold underline" to={routesFlat.contact}>
                Contact us
                </Link>
            </p>
        </div>
    );
}

const containerStyle = "flex flex-col items-start text-xl lg:text-2xl";