import clsx from "clsx";

export const parentContainer = clsx(
    "flex h-screen w-full flex-col-reverse md:flex-row",
);
export const rightSide = clsx(
    "w-full md:w-1/2 md:h-full h-1/3 p-10 flex items-center justify-center",
);
export const subheader = clsx(
    "mt-6 max-w-[400px] text-xl md:mt-10 md:text-2xl",
);
export const title = clsx(
    "max-w-[500px] md:text-6xl text-4xl tracking-wider font-bold uppercase",
);

export const textContainer = clsx(
    "my-40 flex w-full flex-col items-center justify-center",
);
export const desc = clsx("mt-10 max-w-[800px] text-center text-2xl px-10");
