import clsx from "clsx";

export default function DropdownArrow({ className, isOpen = false, onClick, fill="#323232"}) {
    const arrowStyle = clsx(className + (isOpen ? " rotate-180" : ""));

    return (
        <div className={arrowStyle} onClick={onClick}>
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 20 13"
                fill={fill}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M17.65 0L10 7.63333L2.35 0L0 2.35L10 12.35L20 2.35L17.65 0Z"
                    fill={fill}
                />
            </svg>
        </div>
    );
}
