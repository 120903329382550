import clsx from "clsx";

export const bg = clsx(" w-full flex justify-center");
export const innerTextParentContainer = clsx(
    "w-[90%] space-y-16 rounded-lg bg-gradient-to-r from-lime via-blue to-green to-70% flex md:flex-row flex-col p-16 z-10 relative justify-between",
);

export const emailInputContainer = clsx(
    "flex flex-col justify-end relative w-full",
);
export const subscribeContainer = clsx(
    "text-sm flex flex-nowrap items-start mb-10",
);
export const successOrFailMsgContainer = clsx(
    "absolute top-0 h-[50px] flex flex-col -mt-16 mb-4",
);
export const displayMsg = clsx("rounded-lg bg-blue shadow-md px-2 py-1");
export const displayMsgPointer = clsx(
    "w-[0px] border-t-[10px] border-x-[15px] border-t-blue border-x-[transparent] ml-4",
);
export const input = clsx(
    "self-start focus:outline-none focus:ring-b focus:ring-blue w-full border-t-0 border-x-0 border-b-[1px]" +
        " placeholder-black border-[1px] mb-4 contrast-more:placeholder-slate-600 py-3 px-4 text-black tracking-wider bg-[rgba(0,0,0,0)]",
);
