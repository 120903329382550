const newsfeedBase = `${process.env.REACT_APP_API_URL}/api/${process.env.REACT_APP_API_VERSION}/newsfeed`;
const summary = `${newsfeedBase}/summary`;

export function getArticles() {
    return fetch(summary, {
        method: "GET",
    })
        .then((resp) => resp.json())
        .then((resp) => resp);
}

export function getRelatedArticles(tags, excludeId = 0) {
    return fetch(`${summary}?tags=${tags}&limit=5&exclude_id=${excludeId}`)
        .then((resp) => resp.json())
        .then((resp) => resp);
}

export function getArticle(uri) {
    return fetch(`${newsfeedBase}/item?uri=${uri}`)
    .then((resp) => resp.json())
    .then((resp) => resp);

}