import { useRef } from "react";
import { motion } from "framer-motion";
import * as styles from "./styles";
import clsx from "clsx";

export default function Tabs(props) {
    const tabContainerRef = useRef(null);

    const handleTabClick = (tab) => {
        props.setTab(tab);

        // auto scroll to the selected tab on small screens
        const container = tabContainerRef.current;
        const tabElement = document.getElementById(tab.id);
        if (container && tabElement) {
            const containerRect = container.getBoundingClientRect();
            const tabRect = tabElement.getBoundingClientRect();
            const containerCenter =
                containerRect.left + containerRect.width / 2;
            const tabCenter = tabRect.left + tabRect.width / 2;
            const scrollOffset = tabCenter - containerCenter;
            container.scrollBy({ left: scrollOffset, behavior: "smooth" });
        }
    };

    return (
        <div className={styles.parent}>
            <div className={styles.tabContainer} ref={tabContainerRef}>
                {props.tabs?.map((tab) => {
                    const selected = props.selectedTab === tab.id;
                    const selectedTabStyle = selected ? "" : "text-black/50";
                    return (
                        <div
                            key={tab.id}
                            id={tab.id}
                            className={clsx(selectedTabStyle, styles.tab)}
                            onClick={() => handleTabClick(tab)}
                        >
                            {tab.display}
                            {selected && (
                                <motion.div
                                    className={styles.underline}
                                    layoutId={props.id}
                                    initial={false}
                                    transition={{
                                        type: "spring",
                                        stiffness: 300,
                                        damping: 50,
                                    }}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
            <div className={styles.border} />
        </div>
    );
}
