export default function Rings({ className }) {
    return (
        <svg
            className={className}
            width="156"
            height="64"
            viewBox="0 0 156 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_b_2981_11102)">
                <rect
                    y="63.1484"
                    width="62.458"
                    height="155.374"
                    rx="12"
                    transform="rotate(-90 0 63.1484)"
                    fill="black"
                    fill-opacity="0.75"
                />
                <circle
                    cx="31.229"
                    cy="31.9194"
                    r="17.729"
                    transform="rotate(-90 31.229 31.9194)"
                    stroke="#5F6368"
                    stroke-width="3"
                />
                <circle
                    cx="77.687"
                    cy="31.9194"
                    r="17.729"
                    transform="rotate(-90 77.687 31.9194)"
                    stroke="#5F6368"
                    stroke-width="3"
                />
                <circle
                    cx="124.145"
                    cy="31.9194"
                    r="17.729"
                    transform="rotate(-90 124.145 31.9194)"
                    stroke="#5F6368"
                    stroke-width="3"
                />
                <path
                    d="M40.5678 46.99C37.8848 48.6524 34.8068 49.5684 31.6515 49.6434C28.4962 49.7184 25.3781 48.9498 22.6192 47.4169C19.8603 45.884 17.5606 43.6424 15.9577 40.9236C14.3547 38.2047 13.5066 35.1073 13.501 31.9511"
                    stroke="#46CE84"
                    stroke-width="3"
                />
                <path
                    d="M130.704 15.4484C133.697 16.6404 136.31 18.6259 138.26 21.1913C140.21 23.7567 141.423 26.805 141.771 30.0086C142.118 33.2121 141.586 36.4498 140.231 39.3735C138.876 42.2972 136.75 44.7963 134.081 46.6023C131.413 48.4084 128.302 49.4529 125.085 49.6236C121.867 49.7944 118.663 49.085 115.818 47.5716C112.974 46.0582 110.595 43.7981 108.938 41.0342C107.282 38.2703 106.41 35.1072 106.416 31.8849"
                    stroke="#DFF841"
                    stroke-width="3"
                />
                <path
                    d="M95.4141 31.9194C95.4141 34.2476 94.9555 36.553 94.0645 38.704C93.1736 40.855 91.8676 42.8094 90.2214 44.4557C88.5751 46.102 86.6206 47.4079 84.4697 48.2989C82.3187 49.1899 80.0133 49.6484 77.6851 49.6484C75.3569 49.6484 73.0514 49.1899 70.9005 48.2989C68.7495 47.4079 66.7951 46.102 65.1488 44.4557C63.5025 42.8094 62.1966 40.855 61.3056 38.704C60.4146 36.553 59.9561 34.2476 59.9561 31.9194"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
            </g>
            <defs>
                <filter
                    id="filter0_b_2981_11102"
                    x="-40"
                    y="-39.3096"
                    width="235.374"
                    height="142.458"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="20" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_2981_11102"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_2981_11102"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
}
