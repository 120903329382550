import { Link, useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import clsx from "clsx";

import { navRoutes, routesFlat } from "@routes";
import { NavItem } from "./components";
import { Logout } from "../../Login/components";
import { BlackLogo } from "@assets/icons";

function getBgColor(currentRoute) {
    if (currentRoute.includes(routesFlat.news)) {
        return " bg-green";
    }

    return " bg-background/20";
}

export default function NavDesktop() {
    const { isAuthenticated } = useAuth0();
    const currentRoute = useLocation();
    // NOTE: This will be more elaborate once we get final designs on solution pages
    // because the header needs to match the left side color of the bg screen height.
    const bgColor = getBgColor(currentRoute.pathname);
    const parentStyle = clsx(parentWrapperStyle + bgColor);

    return (
        <div className={parentStyle}>
            <Link to={routesFlat.landing}>
                <BlackLogo withText={true} className={logoStyle} />
            </Link>
            <div className={routesContainerStyle}>
                {navRoutes.map((route) => (
                    <NavItem route={route} />
                ))}
                {isAuthenticated && <Logout />}
            </div>
        </div>
    );
}

const parentWrapperStyle = clsx(
    "absolute top-0 z-30 hidden lg:flex w-full justify-between ",
);
const logoStyle = clsx("h-[40px] w-auto self-center md:h-[60px]");
const routesContainerStyle = clsx(
    "flex justify-around w-1/2 pr-6 items-center",
);
