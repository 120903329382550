import clsx from "clsx";
import Lottie from "react-lottie";
import { UnderlinedSectionHeader, DottedDivider } from "@components";
import { defaultOptions } from "@assets/lottie";
import * as styles from "./styles";
import { beats } from "./constants";

export default function WhyCTrees() {
    return (
        <>
            <UnderlinedSectionHeader>why ctrees?</UnderlinedSectionHeader>
            {beats.map((props, i) => {
                const options = {
                    ...defaultOptions,
                    animationData: props.animationData,
                };
                const chartProps = {
                    ...props,
                    options,
                };
                
                return (
                    <>
                        <div
                            className={styles.parentContainer}
                            key={props.title}
                        >
                            {props.chartLeft && (
                                <>
                                    <Chart {...chartProps} />
                                    <div className={styles.divider}></div>
                                </>
                            )}
                            <div className={styles.textContainer}>
                                <div className="flex max-w-[400px] flex-col">
                                    <div className="mb-10 text-3xl uppercase">
                                        {props.title}
                                    </div>
                                    <div>{props.desc}</div>
                                </div>
                            </div>
                            {!props.chartLeft && (
                                <>
                                    <div className={styles.divider}></div>
                                    <Chart {...chartProps} />
                                </>
                            )}
                        </div>
                        <DottedDivider
                            key={props.desc}
                            className={
                                clsx(i === 2 && "border-black ") + " w-[90%]"
                            }
                        />
                    </>
                );
            })}
            <div className="flex w-full justify-center">
                <div className={styles.blackDot}></div>
            </div>
        </>
    );
}

function Chart({ options, chartDesc, chartWidth = 600 }) {
    return (
        <div className={styles.chartContainer}>
            <Lottie options={options} height={"auto"} width={chartWidth} />
            <div className={styles.chartDesc}>{chartDesc}</div>
        </div>
    );
}
