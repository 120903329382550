import clsx from "clsx";
import * as components from "./components";

export default function Footer() {
    return (
        <div className={quadrantContainerStyle}>
            <div className="order-1 lg:order-1">
                <components.ContactUs />
            </div>
            <div className="order-2 lg:order-2">
                <components.Links />
            </div>
            <div className={`order-3 lg:order-4 ${thirdItemStyle}`}>
                <components.Legal />
            </div>
            <div className="order-4 lg:order-3 lg:mt-48">
                <components.Logo />
            </div>
        </div>
    );
}

const quadrantContainerStyle = clsx(
    "min-h-[450px] bg-[#45ce84] p-10 text-black",
    "grid w-full grid-cols-1 gap-10 lg:grid-cols-2", // quadrant magic
);
const thirdItemStyle = clsx("-mt-8 flex items-end justify-start lg:mt-auto lg:justify-end");
