import clsx from "clsx";

export const parentContainer = clsx(
    "w-full h-screen flex flex-col relative z-20 justify-center items-center mb-60",
);
export const textContainer = clsx(
    "absolute top-0 z-20 mt-48 flex flex-col items-center justify-center",
);
export const headerText = clsx(
    "text-center text-6xl max-w-[1000px] font-bold uppercase leading-none tracking-widest",
);
export const subHeaderText = clsx("my-10 text-center text-2xl md:max-w-[500px]");
export const halfCircle =clsx("via-green to-blue absolute inset-0 h-[200vh] w-full rounded-full bg-gradient-to-r from-yellow")