import { useAuth0 } from "@auth0/auth0-react";
import clsx from "clsx";

export default function Logout(){
    const { logout } = useAuth0();
    const returnToUrl = process.env.REACT_APP_HOME_URL || "https://ctrees.org";
    const handleLogout = () => {
        console.log(
            "Logging out, redirecting to:",
            process.env.REACT_APP_HOME_URL,
        );
        
        localStorage.clear();
        logout({
            returnTo: returnToUrl,
        });
    };

    return (
        <button onClick={handleLogout} className={buttonStyle}>
            Log Out
        </button>
    );
};

export const buttonStyle = clsx(
    "text-xs uppercase z-30 w-auto font-bold tracking-wide flex flex-col relative",
);