import Lottie from "react-lottie";
import { Button, UnderlinedSectionHeader } from "@components";
import * as lottie from "@assets/lottie";
import { routesFlat } from "@routes";
import * as styles from "./styles";

const s3Bucket =
    "https://ctrees-website.s3.amazonaws.com/webv3/solutions/hero/";

export default function Products() {
    // Note: Can't move to constants because need link route reference.
    const solutions = [
        {
            title: "Global Assessment",
            subheader:
                "Access the only system to measure land carbon change and activity globally.",
            bgImg: `${s3Bucket}global.png`,
            link: routesFlat.globalAssessment,
            animationData: lottie.SolutionEarth,
        },
        {
            title: "Country and State Programs",
            subheader:
                "Partner to map forests, reduce deforestation, and protect carbon sinks.",
            bgImg: `${s3Bucket}country-and-state.png`,
            link: routesFlat.countryState,
            animationData: lottie.SolutionCali,
        },
        {
            title: "Carbon Markets",
            subheader:
                "Measure, report, and verify performance of projects and jurisdictional programs.",
            bgImg: `${s3Bucket}carbon-markets.png`,
            link: routesFlat.carbonMarkets,
            animationData: lottie.SolutionTree,
        },
    ];
    return (
        <>
            <UnderlinedSectionHeader className="mt-40">
                Solutions
            </UnderlinedSectionHeader>
            <div className={styles.parentContainer}>
                {solutions.map((solution) => {
                    const lottieOptions = {
                        ...lottie.defaultOptions,
                        animationData: solution.animationData,
                    };

                    return (
                        <div className={styles.solutionContainer}>
                            <div className={styles.title}>{solution.title}</div>
                            <div className={styles.lottie}>
                                <img
                                    className={styles.bgImg}
                                    src={solution.bgImg}
                                />
                                <Lottie
                                    options={lottieOptions}
                                    height={"auto"}
                                    width={300}
                                />
                            </div>

                            <div className="my-4 text-center">
                                {solution.subheader}
                            </div>
                            <Button className="px-10" to={solution.link}>
                                Learn More
                            </Button>
                        </div>
                    );
                })}
            </div>
        </>
    );
}
