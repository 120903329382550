import { Link } from "react-router-dom";
import { routesFlat } from "@routes";
import * as icons from "@assets/icons";
import clsx from "clsx";

export default function Legal() {
    return (
        <div className={containerStyle}>
            <Link to={routesFlat.privacy} className="lg:pb-1">
                Privacy Notice
            </Link>
            <Link to={routesFlat.terms} className="lg:pb-1">
                Terms of Use
            </Link>

            <div className={socialContainerStyle}>
                <Link to={routesFlat.linkedin} target={"_blank"}>
                    <icons.LinkedIn className={iconStyle} />
                </Link>
                <Link to={routesFlat.youtube} target={"_blank"}>
                    <icons.YouTube className={iconStyle} />
                </Link>
            </div>
        </div>
    );
}

const containerStyle = clsx(
    "flex flex-col mt-4 lg:mt-0 gap-6 text-start text-sm uppercase lg:flex-row lg:gap-8 font-bold tracking-wider lg:font-normal",
);
const socialContainerStyle = clsx("flex items-start gap-2 my-2 mt-10 lg:my-0");
const iconStyle = clsx("w-4 h-4 mr-2");
