import { DottedDivider } from "@components";
import * as styles from "./styles";

function Row({ props }) {
    const isEven = props.i % 2 === 0;
    const isLast = props.i === props.len - 1;

    function List() {
        return (
            <div className={styles.listContainer}>
                <div className="text-2xl font-bold">{props.title}</div>
                <div className="mt-8 text-lg">
                    {props.list.map((item) => (
                        <div className="mb-4 flex">
                            <div className={styles.greenBullet}></div>
                            <div className="w-[400px]">{item}</div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    return (
        <>
            <div className={styles.rowContainer}>
                {isEven && (
                    <div className={styles.listParentDesktop}>
                        <List />
                    </div>
                )}
                <div className={styles.imgSvgParent}>
                    <div className={"relative"}>
                        <img className={styles.img} src={props.bgImg} />
                        <div className={styles.svg}>{props.img}</div>
                    </div>
                </div>
                {!isEven && (
                    <div className={styles.listParentDesktop}>
                        <List />
                    </div>
                )}
                <div className={styles.listParentMobile}>
                    <List />
                </div>
                
            </div>
            {!isLast && <DottedDivider className="hidden md:block w-[90%]" />}
        </>
    );
}

export default function Features({ props }) {
    return (
        <div className={styles.parent}>
            <div className={styles.rowsParent}>
                {props.map((feature, i) => (
                    <Row key={i} props={{ ...feature, i, len: props.length }} />
                ))}
            </div>
        </div>
    );
}
