export default function IncompleteCircle({ className, alt}) {
    return (
        <div role="img" aria-label={alt} className={className}>
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 235 234"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <circle
                    cx="118.802"
                    cy="117.974"
                    r="96.8172"
                    stroke="black"
                    stroke-width="0.687841"
                    stroke-dasharray="1.38 1.38"
                />
                <circle
                    cx="118.798"
                    cy="117.97"
                    r="75.7707"
                    stroke="black"
                    stroke-width="0.687841"
                    stroke-dasharray="1.38 1.38"
                />
                <circle
                    cx="118.794"
                    cy="117.974"
                    r="115.071"
                    stroke="black"
                    stroke-width="0.687841"
                    stroke-dasharray="1.38 1.38"
                />
                <circle
                    cx="121.257"
                    cy="215.132"
                    r="2.80961"
                    fill="#EEEEEE"
                    stroke="black"
                    stroke-width="0.687841"
                />
                <circle
                    cx="32.2977"
                    cy="74.6239"
                    r="2.80961"
                    fill="#EEEEEE"
                    stroke="black"
                    stroke-width="0.687841"
                />
                <circle
                    cx="124.782"
                    cy="21.3719"
                    r="2.80961"
                    fill="#EEEEEE"
                    stroke="black"
                    stroke-width="0.687841"
                />
                <circle
                    cx="192.446"
                    cy="206.847"
                    r="2.80961"
                    fill="#EEEEEE"
                    stroke="black"
                    stroke-width="0.687841"
                />
                <circle
                    cx="146.944"
                    cy="211.116"
                    r="2.80961"
                    fill="#EEEEEE"
                    stroke="black"
                    stroke-width="0.687841"
                />
                <circle
                    cx="3.3817"
                    cy="121.311"
                    r="2.80961"
                    fill="#EEEEEE"
                    stroke="black"
                    stroke-width="0.687841"
                />
                <circle
                    cx="33.8231"
                    cy="196.024"
                    r="2.80961"
                    fill="#EEEEEE"
                    stroke="black"
                    stroke-width="0.687841"
                />
                <circle
                    cx="203.723"
                    cy="71.7782"
                    r="2.80961"
                    fill="#EEEEEE"
                    stroke="black"
                    stroke-width="0.687841"
                />
                <circle
                    cx="50.8114"
                    cy="24.4637"
                    r="2.80961"
                    fill="#EEEEEE"
                    stroke="black"
                    stroke-width="0.687841"
                />
                <circle
                    cx="203.723"
                    cy="39.3797"
                    r="2.80961"
                    fill="#EEEEEE"
                    stroke="black"
                    stroke-width="0.687841"
                />
                <circle
                    cx="21.6356"
                    cy="117.034"
                    r="2.80961"
                    fill="#EEEEEE"
                    stroke="black"
                    stroke-width="0.687841"
                />
                <circle
                    cx="113.868"
                    cy="41.8583"
                    r="2.80961"
                    fill="#EEEEEE"
                    stroke="black"
                    stroke-width="0.687841"
                />
                <circle
                    cx="193.999"
                    cy="128.737"
                    r="2.80961"
                    fill="#EEEEEE"
                    stroke="black"
                    stroke-width="0.687841"
                />
                <circle
                    cx="170.089"
                    cy="174.138"
                    r="2.80961"
                    fill="#EEEEEE"
                    stroke="black"
                    stroke-width="0.687841"
                />
                <circle
                    cx="89.3426"
                    cy="229.485"
                    r="2.80961"
                    fill="#EEEEEE"
                    stroke="black"
                    stroke-width="0.687841"
                />
                <circle
                    cx="44.6649"
                    cy="180.929"
                    r="2.80961"
                    fill="#EEEEEE"
                    stroke="black"
                    stroke-width="0.687841"
                />
                <circle
                    cx="118.802"
                    cy="194.089"
                    r="2.80961"
                    fill="#EEEEEE"
                    stroke="black"
                    stroke-width="0.687841"
                />
                <circle
                    cx="58.7665"
                    cy="72.1532"
                    r="2.80961"
                    fill="#EEEEEE"
                    stroke="black"
                    stroke-width="0.687841"
                />
                <circle
                    cx="52.495"
                    cy="155.124"
                    r="2.80961"
                    fill="#EEEEEE"
                    stroke="black"
                    stroke-width="0.687841"
                />
                <circle
                    cx="131.038"
                    cy="3.73131"
                    r="2.80961"
                    fill="#EEEEEE"
                    stroke="black"
                    stroke-width="0.687841"
                />
                <circle
                    cx="229.714"
                    cy="87.7001"
                    r="2.80961"
                    fill="#EEEEEE"
                    stroke="black"
                    stroke-width="0.687841"
                />
                <circle
                    cx="19.1727"
                    cy="60.409"
                    r="2.80961"
                    fill="#EEEEEE"
                    stroke="black"
                    stroke-width="0.687841"
                />
            </svg>
        </div>
    );
}
