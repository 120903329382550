import * as constants from "./constants";
import ProductTemplate from "../../template";
import { routesFlat } from '@routes'
import { beTagsFlat } from '@helpers/constants'

export default function LandCarbon() {
    const props = {
        constants,
        exploreData: routesFlat.products,
        product: beTagsFlat.carbon  
    };
    return <ProductTemplate {...props} />;
}
