import HubspotForm from "react-hubspot-form";
import Skeleton from "react-loading-skeleton";
import clsx from "clsx";

export default function ContactForm() {
    return (
        <div className={containerStyle}>
            <div className={titleStyle}>
                Get in touch with us to see how our products can support your
                goals.
            </div>
            <div className={formContainerStyle}>
                <HubspotForm
                    portalId="22828643"
                    formId="8619e3f1-b45e-4122-aa17-2ed40ad000b1"
                    loading={
                        <div className="w-full">
                            Loading...
                            {Array(6)
                                .fill(null)
                                .map((i) => (
                                    <Skeleton
                                        key={i}
                                        className="h-[50px] w-full"
                                    />
                                ))}
                        </div>
                    }
                />
            </div>
        </div>
    );
}

const containerStyle = clsx(
    "m-auto mb-10 flex w-full max-w-[500px] flex-col justify-center",
);
const titleStyle = clsx("mb-10 text-center text-xl md:text-left");
const formContainerStyle = clsx(
    "h-full w-full max-w-[500px] px-4 pt-10 md:px-0 md:py-0 md:pt-0",
);
