import { useState } from "react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import * as styles from "./styles";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export default function ImgCarousel ({ images, className }) {
    const [slideCount, setSlideCount] = useState(1);
    return (
        <div className={className + styles.container}>
            <Swiper
                style={{ "--swiper-theme-color": "#46CE84" }}
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                spaceBetween={20}
                slidesPerView={1}
                breakpoints={{
                    //(medium screens and up)
                    768: {
                        slidesPerView: 3,
                    },
                }}
                navigation
                pagination={{ clickable: true, className: "bg-green" }}
                onSlideChange={(e) => setSlideCount(e.realIndex + 1)}
                loop={true}
            >
                {images.map((img, i) => (
                    <SwiperSlide key={i} className="mr-20">
                        <div className={styles.slide}>
                            <img className={styles.img} src={img.src} />
                            <div className={styles.desc}>{img.desc}</div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            <div className={styles.counter}>
                {slideCount}/{images.length}
            </div>
        </div>
    );
};
