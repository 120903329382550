import clsx from "clsx";
import * as styles from "./styles";
import { CloseIcon } from "@assets/icons";

export default function Toggle(props) {
    return (
        <div
            className={clsx(
                styles.buttonWrapper + ` ${props.isOpen ? " mr-16" : " mr-16"}`,
            )}
        >
            <button
                onClick={props.cycleOpen}
                className={styles.buttonContainer}
            >
                <div className={"flex flex-col " + (props.isOpen ? "m-2" : "")}>
                    {props.isOpen ? (
                        <CloseIcon />
                    ) : (
                        <>
                            <div className={styles.line}></div>
                            <div className={styles.line}></div>
                            <div className={styles.bottomLine}></div>
                        </>
                    )}
                </div>
            </button>
        </div>
    );
}
