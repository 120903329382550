export default function LinkedIn() {
    return (
        <svg
            width="26"
            height="26"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M0.652344 1.32329C0.652344 0.590683 1.26561 -0.00390625 2.02169 -0.00390625H17.8103C18.5666 -0.00390625 19.1796 0.590683 19.1796 1.32329V17.1964C19.1796 17.9293 18.5666 18.5234 17.8103 18.5234H2.02169C1.26561 18.5234 0.652344 17.9293 0.652344 17.1967V1.32305V1.32329Z"
                fill="black"
            />
            <g transform="translate(4, 4)">
                <path
                    d="M3.2681 12.5044V4.13833H0.469744V12.5044H3.26835H3.2681ZM1.86941 2.99629C2.84507 2.99629 3.45248 2.35383 3.45248 1.551C3.43414 0.729946 2.84507 0.105469 1.88799 0.105469C0.930184 0.105469 0.304688 0.729946 0.304688 1.551C0.304688 2.35383 0.911844 2.99629 1.85107 2.99629H1.86917H1.86941ZM4.81694 12.5044H7.61505V7.83294C7.61505 7.58315 7.63339 7.33288 7.70724 7.15452C7.90946 6.6547 8.36991 6.13738 9.1431 6.13738C10.1554 6.13738 10.5606 6.90449 10.5606 8.02928V12.5044H13.3587V7.70756C13.3587 5.13798 11.9784 3.94224 10.1373 3.94224C8.62788 3.94224 7.96497 4.78054 7.59671 5.35156H7.6153V4.13857H4.81718C4.85361 4.92342 4.81694 12.5046 4.81694 12.5046V12.5044Z"
                    fill="#46CE84"
                />
            </g>
        </svg>
    );
}
