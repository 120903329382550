import clsx from "clsx";
import { Marquee } from "@components";
import { Link } from "react-router-dom";
import { DotConnectorIcon } from "@assets/icons";

export default function LatestMarquee({ articles }) {
    return (
        <div className={parentContainerStyle}>
            <div className={latestTextStyle}>Latest</div>
            <Marquee speed={50}>
                <div className={articlesContainerStyle}>
                    {articles.map(({ date, title, link }) => {
                        return (
                            <Link
                                className="mx-4 flex items-center"
                                to={link}
                                key={title}
                            >
                                <div className="mr-4 font-thin">{date}</div>
                                <div className="mr-4 font-bold tracking-wide">
                                    {title}
                                </div>
                                <DotConnectorIcon />
                            </Link>
                        );
                    })}
                </div>
            </Marquee>
        </div>
    );
}

const latestTextStyle = clsx("md:w-1/5 px-4 md:py-2 py-4 text-right text-lg");
const articlesContainerStyle = clsx(
    "flex w-full items-center justify-between text-xs",
);
const parentContainerStyle = clsx("flex bg-blue uppercase text-[black]");

// fn PROPS
// articles: Array<Object>
// [{ title: String, date: String | date, link: String }]
