export default function Expander({ className }) {
    return (
        <svg
            className={className}
            width="56"
            height="49"
            viewBox="0 0 56 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_b_2981_11127)">
                <rect
                    y="48.9189"
                    width="48"
                    height="56"
                    rx="24"
                    transform="rotate(-90 0 48.9189)"
                    fill="black"
                    fill-opacity="0.75"
                />
                <path
                    d="M19 33.9189V27.9189H21V30.5189L24.1 27.4189L25.5 28.8189L22.4 31.9189H25V33.9189H19ZM31 33.9189V31.9189H33.6L30.5 28.8189L31.9 27.4189L35 30.5189V27.9189H37V33.9189H31ZM24.1 22.4189L21 19.3189V21.9189H19V15.9189H25V17.9189H22.4L25.5 21.0189L24.1 22.4189ZM31.9 22.4189L30.5 21.0189L33.6 17.9189H31V15.9189H37V21.9189H35V19.3189L31.9 22.4189Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter
                    id="filter0_b_2981_11127"
                    x="-40"
                    y="-39.0811"
                    width="136"
                    height="128"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="20" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_2981_11127"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_2981_11127"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
}
