export function wrapImagesWithDiv(htmlString) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const imgElements = doc.querySelectorAll("img");

  imgElements.forEach((img) => {
      const div = doc.createElement("div");
      div.className = "imgParent";

      img.parentNode.insertBefore(div, img);
      div.appendChild(img);
  });

  // Serialize the modified document back to an HTML string
  return new XMLSerializer().serializeToString(doc);
}