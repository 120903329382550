export default function Zoom({ className }) {
    return (
        <svg
            className={className}
            width="112"
            height="49"
            viewBox="0 0 112 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_b_2981_11121)">
                <rect
                    y="48.9189"
                    width="48.5"
                    height="112"
                    rx="24.25"
                    transform="rotate(-90 0 48.9189)"
                    fill="black"
                    fill-opacity="0.75"
                />
                <path
                    d="M35.6 33.9189L29.3 27.6189C28.8 28.0189 28.225 28.3356 27.575 28.5689C26.925 28.8023 26.2333 28.9189 25.5 28.9189C23.6833 28.9189 22.1458 28.2898 20.8875 27.0314C19.6292 25.7731 19 24.2356 19 22.4189C19 20.6023 19.6292 19.0648 20.8875 17.8064C22.1458 16.5481 23.6833 15.9189 25.5 15.9189C27.3167 15.9189 28.8542 16.5481 30.1125 17.8064C31.3708 19.0648 32 20.6023 32 22.4189C32 23.1523 31.8833 23.8439 31.65 24.4939C31.4167 25.1439 31.1 25.7189 30.7 26.2189L37 32.5189L35.6 33.9189ZM25.5 26.9189C26.75 26.9189 27.8125 26.4814 28.6875 25.6064C29.5625 24.7314 30 23.6689 30 22.4189C30 21.1689 29.5625 20.1064 28.6875 19.2314C27.8125 18.3564 26.75 17.9189 25.5 17.9189C24.25 17.9189 23.1875 18.3564 22.3125 19.2314C21.4375 20.1064 21 21.1689 21 22.4189C21 23.6689 21.4375 24.7314 22.3125 25.6064C23.1875 26.4814 24.25 26.9189 25.5 26.9189ZM23 23.4189V21.4189H28V23.4189H23Z"
                    fill="white"
                />
                <path
                    opacity="0.3"
                    d="M56 12.4189V36.9189"
                    stroke="white"
                    stroke-width="1.55"
                />
                <path
                    d="M91.6 33.9189L85.3 27.6189C84.8 28.0189 84.225 28.3356 83.575 28.5689C82.925 28.8023 82.2333 28.9189 81.5 28.9189C79.6833 28.9189 78.1458 28.2898 76.8875 27.0314C75.6292 25.7731 75 24.2356 75 22.4189C75 20.6023 75.6292 19.0648 76.8875 17.8064C78.1458 16.5481 79.6833 15.9189 81.5 15.9189C83.3167 15.9189 84.8542 16.5481 86.1125 17.8064C87.3708 19.0648 88 20.6023 88 22.4189C88 23.1523 87.8833 23.8439 87.65 24.4939C87.4167 25.1439 87.1 25.7189 86.7 26.2189L93 32.5189L91.6 33.9189ZM81.5 26.9189C82.75 26.9189 83.8125 26.4814 84.6875 25.6064C85.5625 24.7314 86 23.6689 86 22.4189C86 21.1689 85.5625 20.1064 84.6875 19.2314C83.8125 18.3564 82.75 17.9189 81.5 17.9189C80.25 17.9189 79.1875 18.3564 78.3125 19.2314C77.4375 20.1064 77 21.1689 77 22.4189C77 23.6689 77.4375 24.7314 78.3125 25.6064C79.1875 26.4814 80.25 26.9189 81.5 26.9189ZM80.5 25.4189V23.4189H78.5V21.4189H80.5V19.4189H82.5V21.4189H84.5V23.4189H82.5V25.4189H80.5Z"
                    fill="white"
                />
            </g>
            <defs>
                <filter
                    id="filter0_b_2981_11121"
                    x="-40"
                    y="-39.5811"
                    width="192"
                    height="128.5"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="20" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_2981_11121"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_2981_11121"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
}
