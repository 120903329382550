import { logos } from "@helpers/constants";

export default function BlackLogo({ withText = false, className }) {
    if (withText) {
        return (
            <img
                src={logos.blackWithText}
                alt="black and white CTrees leaf logo with the company name, CTrees, directly to the right of the logo in black"
                className={className}
            />
        );
    }
    return (
        <img
            src={logos.black}
            alt="black and white CTrees leaf logo"
            className={className}
        />
    );
}
