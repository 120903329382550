import clsx from "clsx";
import { formatDateMonth } from "@helpers";
import { feTagsFlat } from "@helpers/constants";
import { HorizontalScrollWrapper, GreenCard } from "@components";
import { tag as tagStyle } from "@components/Cards/Blue/styles";

export default function Featured({ articles }) {
    return (
        <HorizontalScrollWrapper className="mb-40">
            {articles.map((item, i) => {
                const tags = item.product_tags.concat(item.article_tags);

                return (
                    <GreenCard
                        img={item.image_url}
                        imgAlt={item.title}
                        key={i}
                        link={item.uri}
                    >
                        <div className={date}>
                            {formatDateMonth(item.publish_date)}
                        </div>
                        <div className={title}>{item.title}</div>
                        <div className="line-clamp-2 w-4/5 text-sm">
                            {item.summary}
                        </div>
                        <div className="absolute bottom-0 mb-4 flex">
                            {tags?.map((tag) => (
                                <div className={tagStyle}>
                                    {feTagsFlat[tag]}
                                </div>
                            ))}
                        </div>
                    </GreenCard>
                );
            })}
        </HorizontalScrollWrapper>
    );
}

const date = clsx("-mt-4 text-xs font-bold uppercase tracking-widest");
const title = clsx("my-2 line-clamp-2 text-lg font-bold leading-6");
