import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import * as styles from "./styles";

import { DropdownArrowIcon, CloseIcon } from "@assets/icons";
import { Photo } from "../Profile";

export default function Sidebar(props) {
    const [isOpen, setIsOpen] = useState(false);
    const nameParts = props.name.split(" ");

    const toggleSidebar = () => setIsOpen(!isOpen);

    const handleClickOutside = (event) => {
        if (event.target.classList.contains("overlay")) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto";
        }
        return () => (document.body.style.overflow = "auto");
    }, [isOpen]);

    return (
        <>
            <DropdownArrowIcon
                fill="#46CE84"
                className={styles.openBtn}
                onClick={toggleSidebar}
            />

            <AnimatePresence>
                {isOpen && (
                    <>
                        <motion.div
                            className={styles.overlay}
                            initial={opacity0}
                            animate={{ opacity: 0.5 }}
                            exit={opacity0}
                            transition={{ duration: 0.3 }}
                            onClick={handleClickOutside}
                        ></motion.div>
                        <motion.div
                            initial={animateX}
                            animate={{ x: 0 }}
                            exit={animateX}
                            transition={{
                                type: "spring",
                                stiffness: 300,
                                damping: 30,
                            }}
                            className={styles.sidebar}
                        >
                            <div className="max-h-screen overflow-y-scroll pb-20">
                                <div className={styles.contentContainer}>
                                    <CloseIcon
                                        onClick={toggleSidebar}
                                        className="self-end"
                                    />
                                    <div className={styles.bio}>
                                        <Photo
                                            img={props.img}
                                            name={props.name}
                                        />
                                        <div className={styles.nameContainer}>
                                            {nameParts.map((part) => (
                                                <span
                                                    key={part}
                                                    className={styles.name}
                                                >
                                                    {part}
                                                </span>
                                            ))}
                                            <div className={styles.title}>
                                                {props.title}
                                                <div
                                                    className={styles.titleLine}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-6 w-[80%] text-lg">
                                        <div className="font-bold">About</div>
                                        {props.bio.map((section, i) => (
                                            <div
                                                key={i}
                                                className="mt-4 text-sm"
                                            >
                                                {section}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </>
                )}
            </AnimatePresence>
        </>
    );
}

const opacity0 = { opacity: 0 };
const animateX = { x: "100%" };
