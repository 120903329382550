export default function AlertSwitch({ className }) {
    return (
        <svg
            className={className}
            width="112"
            height="49"
            viewBox="0 0 112 49"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_b_2981_11109)">
                <rect
                    y="48.7959"
                    width="48.5"
                    height="112"
                    rx="24.25"
                    transform="rotate(-90 0 48.7959)"
                    fill="black"
                    fill-opacity="0.75"
                />
                <path
                    d="M29.95 34.7959H26.05V30.7959H19L23 24.7959H21L28 14.7959L35 24.7959H33L37 30.7959H29.95V34.7959ZM22.75 28.7959H26.75H24.85H31.15H29.25H33.25H22.75ZM22.75 28.7959H33.25L29.25 22.7959H31.15L28 18.2959L24.85 22.7959H26.75L22.75 28.7959Z"
                    fill="white"
                />
                <path
                    opacity="0.3"
                    d="M56 12.2959V36.7959"
                    stroke="white"
                    stroke-width="1.55"
                />
                <path
                    d="M87 33.7959L81 31.6959L76.35 33.4959C76.0167 33.6292 75.7083 33.5917 75.425 33.3834C75.1417 33.1751 75 32.8959 75 32.5459V18.5459C75 18.3292 75.0625 18.1376 75.1875 17.9709C75.3125 17.8042 75.4833 17.6792 75.7 17.5959L81 15.7959L87 17.8959L91.65 16.0959C91.9833 15.9626 92.2917 16.0001 92.575 16.2084C92.8583 16.4167 93 16.6959 93 17.0459V31.0459C93 31.2626 92.9375 31.4542 92.8125 31.6209C92.6875 31.7876 92.5167 31.9126 92.3 31.9959L87 33.7959ZM86 31.3459V19.6459L82 18.2459V29.9459L86 31.3459ZM88 31.3459L91 30.3459V18.4959L88 19.6459V31.3459ZM77 31.0959L80 29.9459V18.2459L77 19.2459V31.0959Z"
                    fill="#5F6368"
                />
            </g>
            <defs>
                <filter
                    id="filter0_b_2981_11109"
                    x="-40"
                    y="-39.7041"
                    width="192"
                    height="128.5"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="20" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_2981_11109"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_2981_11109"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
}
