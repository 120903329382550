import clsx from "clsx";
import { Link } from "react-router-dom";

import { UnderlinedSectionHeader } from "@components";
import { formatDateMonth } from "@helpers";
import { feTagsFlat } from "@helpers/constants";
import { Featured, Newsletter } from "./components";

import * as styles from './styles'
import { tag as tagStyle } from "@components/Cards/Blue/styles";

const email = "press@ctrees.org";

export default function MostRecent({ article }) {
    const tags = article?.product_tags.concat(article?.article_tags);

    return (
        <>
            <UnderlinedSectionHeader className="mb-8 self-start">
                News
            </UnderlinedSectionHeader>
            <div className={styles.parentContainer}>
                <Link to={article?.uri} className={styles.leftColContainer}>
                    <img
                        src={article?.image_url}
                        alt={article?.title}
                        className="h-[300px] w-full rounded-md object-cover"
                    />
                    <div className="my-4 text-2xl font-bold">
                        {article?.title}
                    </div>
                    <div className={styles.date}>
                        {formatDateMonth(article?.publish_date)}
                    </div>
                    <div className="mb-4 line-clamp-3">{article?.summary}</div>
                    <div className="flex">
                        {tags?.map((tag) => (
                            <div className={clsx(tagStyle + "!bg-black/5")}>
                                {feTagsFlat[tag]}
                            </div>
                        ))}
                    </div>
                </Link>
                <div className={styles.rightColContainer}>
                    <Featured />
                    <div className={styles.rightColHeader}>media contact</div>
                    <div className={clsx(styles.text + " max-w-[200px]")}>
                        For press inquiries, please email
                        <Link to={`mailto:${email}`}>{email}.</Link>
                    </div>
                    <Newsletter />
                </div>
            </div>
        </>
    );
}
