import { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { CMSContext } from "@";
import { routesFlat } from "../../routes";
import { LoadingAnimation } from "../../components";

export default function CMS() {
    const { state } = useContext(CMSContext);
    const iframeRef = useRef(null);

    useEffect(() => {
        if (iframeRef.current && state?.isAdmin && state?.token) {
            iframeRef.current.src = `${process.env.REACT_APP_CMS_URL}/admin#${state.token}`;
        }
    }, [state?.token, state?.isAdmin]);

    if (state?.isAdmin && state?.token) {
        return (
            <iframe
                ref={iframeRef}
                className="h-productScreen mt-[60px] w-full"
                src={`${process.env.REACT_APP_CMS_URL}/admin#${state?.token ? state.token : ""}`}
            />
        );
    } else {
        if (state && !state?.isAdmin) {
            return (
                <div className={errParentStyle}>
                    <div>Error: You don't have access here.</div>
                    <Link to={routesFlat.landing} className={errLinkStyle}>
                        Go Home
                    </Link>
                </div>
            );
        } else {
            return <LoadingAnimation />;
        }
    }
}

const errParentStyle = clsx(
    "w-full h-screen flex justify-center items-center flex-col",
);
const errLinkStyle = clsx(
    "bg-green px-4 py-2 rounded-md hover:bg-lightGreen mt-4",
);
