import {
    ColorfulDotsCircle,
    ClusterPie,
    IncompleteCircle,
} from "@assets/beats/solutions";

export const metaDataHeader = {
    title: "",
    desc: "",
    img: "",
};

export const latest = [
    {
        title: "At AGU24, Sassan Saatchi presents on systematic land monitoring for the global stocktake",
        date: "12.10.24",
        link: "/news/AGU24",
    },
    {
        title: "CTrees releases 2023 global data on emissions, removals, and carbon stocks",
        date: "11.15.24",
        link: "/news/jmrv-update-2024",
    },
    {
        title: "CTrees launches first global system for monitoring tropical forest degradation",
        date: "10.21.24",
        link: "/news/reddai-press-release",
    },
];

export const hero = {
    title: "Global Assessment",
    subheader:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse semper egestas ipsum, vitae consequat erat pellentesque quis.",
    bgImg: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/hero/global.png",
};

export const firstBeat = {
    title: "A global data challenge",
    img: <IncompleteCircle className={"h-4/5 md:h-3/5"} />,
    subheader:
        "<p>The global stocktake is a crucial initiative by countries and stakeholders to measure greenhouse gas emissions and assess progress towards Paris Agreement goals.</p><p class='mt-6'>But taking stock of land emissions remains a challenge: Forests and land use are the largest source of uncertainty in the global carbon budget due to inconsistent country inventories and the complex nature of land use activities.</p>",
};
export const secondBeat = {
    title: "Building a unified global picture",
    subheader:
        "By leveraging groundbreaking remote sensing science and AI, CTrees measures carbon stocks and fluxes across all biomes worldwide, including forests, grasslands, mangroves, as well as agricultural and urban lands.<p class='mt-6'> The CTrees platform systematically tracks land use activities, mapping deforestation, degradation, and regeneration to offer a comprehensive and precise global assessment of emissions and removals on land.</p>",
    img: (
        <ColorfulDotsCircle
            className={"flex h-5/6 w-5/6 items-center justify-center"}
        />
    ),
    bg: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/beats/global.png",
    textRight: true,
};
export const thirdBeat = {
    title: "Total coverage for total accountability",
    subheader:
        "CTrees’ global greenhouse gas inventory system accounts for emission reductions at any scale, from smallholder and local initiatives to state and national programs.<p class='mt-6'>Our science-based data platform offers an up-to-date assessment of global climate action progress and identifies optimal pathways towards achieving net-zero goals.</p>",
    img: <ClusterPie className={"h-3/5 md:h-3/5"} />,
};

export const dataCards = [
    {
        img: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/data/jmrv.png",
        imgAlt: "",
        title: "Jurisdictional MRV",
        subheader:
            "New data finds emissions from deforestation and degradation increased in 2023.",
        link: "/jmrv",
        linkDisplayText: "Read Case Study",
    },
    {
        img: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/data/land-carbon.png",
        imgAlt: "",
        title: "Land Carbon Map",
        subheader:
            "New data finds emissions from deforestation and degradation increased in 2023.",
        link: "/land-carbon",
        linkDisplayText: "Read Case Study",
    },
    {
        img: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/data/tree-level.png",
        imgAlt: "",
        title: "Tree-Level Data",
        subheader: "New data finds height of trees around the globe.",
        link: "/jmrv",
        linkDisplayText: "Read Case Study",
    },
    {
        img: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/data/river.png",
        imgAlt: "",
        title: "River Basin Data",
        subheader:
            "New data finds emissions from deforestation and degradation increased in 2023.",
        link: "/land-carbon",
        linkDisplayText: "Read Case Study",
    },
    {
        img: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/data/land-carbon.png",
        imgAlt: "",
        title: "Other data",
        subheader:
            "New data finds emissions from deforestation and degradation increased in 2023.",
        link: "/land-carbon",
        linkDisplayText: "Read Case Study",
    },
];

export const dataSubheader =
    "Access CTrees data and insights on global assessment of carbon and change on land.";
