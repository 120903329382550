import clsx from "clsx";

export const container = clsx("h-screen flex flex-col md:flex-row justify-between");
export const rightSideContainer = clsx(
    "flex w-full flex-col items-center mt-4 md:flex-row md:items-start md:justify-end md:gap-4",
);
export const mainPanel = clsx(
    "w-full h-[500px] md:w-[410px] p-4 mx-2 mb-2 rounded-lg",
);
export const panelSkeleton = clsx(
    "w-full h-[60px] md:w-[410px] p-4 mx-2 mb-2 rounded-lg",
);
export const rightSideElement = clsx(
    "h-10 px-4 md:py-2 bg-white rounded-lg flex justify-between items-center",
);
export const searchSkeleton = clsx(rightSideElement, "w-[250px] lg:w-[500px] ");
