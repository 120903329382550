import { BlackLogo } from "@assets/icons";
import clsx from "clsx";

export default function Logo() {
    return (
        <div className={containerStyle}>
            <BlackLogo withText={true} className="max-w-[400px] -ml-4 lg:-ml-7" />
            <div className={copyrightContainerStyle}>
                <p className={`text-start ${textStyle}`}>Ctrees</p>
                <p className={`text-center ${textStyle}`}>
                    © 2021 Ctrees. All rights reserved.
                </p>
            </div>
        </div>
    );
}

const containerStyle = clsx("flex w-full flex-col text-sm max-w-[450px]");
const copyrightContainerStyle = clsx("flex-inline flex lg:justify-between max-w-[300px] m-auto lg:m-0");
const textStyle = clsx("mt-4 px-1 text-xxs uppercase font-bold tracking-widest");
