import { Helmet } from "react-helmet";

export default function MetaTagHeader({ title, desc, img }) {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="google" content="nositelinkssearchbox" />
      <title>{title}</title>
      <meta name="description" content={desc} />
    </Helmet>
  );
}
