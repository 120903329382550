import clsx from "clsx";

export default function DottedDivider({ className }) {
    return (
        <div
            className={clsx(
                "border-b-[1px] border-dashed border-black/20 " +
                    className,
            )}
        ></div>
    );
}
