import { PartnerWithCTrees } from "@components";
import { Hero, Features, News, FAQ } from "./components";

export default function ProductTemplate(props) {
    const { constants, exploreData, product } = props;
    return (
        <div className="flex flex-col items-center">
            <Hero props={constants.hero} />
            <Features props={constants.features} />
            <PartnerWithCTrees />
            <News props={product} />
            {/* <FAQ props={constants.questionList} /> */}
        </div>
    );
}
