export default function StackedLineBarChart({ className, alt }) {
    return (
        <div role="img" aria-label={alt} className={className}>
            <svg
                className="w-full h-full"
                viewBox="0 0 435 368"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1.87402 0.117081V366.014"
                    stroke="#9B9B9B"
                    stroke-width="2"
                    stroke-dasharray="3.49 3.49"
                />
                <path
                    d="M61.6377 0.117081V366.014"
                    stroke="#9B9B9B"
                    stroke-width="2"
                    stroke-dasharray="3.49 3.49"
                />
                <path
                    d="M76.1045 0.117081V366.014"
                    stroke="#9B9B9B"
                    stroke-width="2"
                    stroke-dasharray="3.49 3.49"
                />
                <path
                    d="M135.868 0.117081V366.014"
                    stroke="#9B9B9B"
                    stroke-width="2"
                    stroke-dasharray="3.49 3.49"
                />
                <path
                    d="M151.364 0.117081V366.014"
                    stroke="#9B9B9B"
                    stroke-width="2"
                    stroke-dasharray="3.49 3.49"
                />
                <path
                    d="M210.098 0.117081V366.014"
                    stroke="#9B9B9B"
                    stroke-width="2"
                    stroke-dasharray="3.49 3.49"
                />
                <path
                    d="M225.594 0.117081V366.014"
                    stroke="#9B9B9B"
                    stroke-width="2"
                    stroke-dasharray="3.49 3.49"
                />
                <path
                    d="M284.325 0.117081V366.014"
                    stroke="#9B9B9B"
                    stroke-width="2"
                    stroke-dasharray="3.49 3.49"
                />
                <path
                    d="M299.824 0.117081V366.014"
                    stroke="#9B9B9B"
                    stroke-width="2"
                    stroke-dasharray="3.49 3.49"
                />
                <path
                    d="M358.5 0.117081V366.014"
                    stroke="#9B9B9B"
                    stroke-width="2"
                    stroke-dasharray="3.49 3.49"
                />
                <path
                    d="M373.996 0.117081V366.014"
                    stroke="#9B9B9B"
                    stroke-width="2"
                    stroke-dasharray="3.49 3.49"
                />
                <path
                    d="M433.917 0.117081V366.014"
                    stroke="#9B9B9B"
                    stroke-width="2"
                    stroke-dasharray="3.49 3.49"
                />
                <path
                    d="M1.87598 261.089H62.9729"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M1.87598 278.545H62.9729"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M1.87598 296.001H62.9729"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M1.87598 313.458H62.9729"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M1.87598 330.914H62.9729"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M1.87598 348.37H62.9729"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M1.87598 365.826H62.9729"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M76.0654 221.812H137.162"
                    stroke="#B6D9FF"
                    stroke-width="3"
                />
                <path
                    d="M76.0654 234.903H137.162"
                    stroke="#B6D9FF"
                    stroke-width="3"
                />
                <path
                    d="M76.0654 247.996H137.162"
                    stroke="#B6D9FF"
                    stroke-width="3"
                />
                <path
                    d="M76.0654 261.088H137.162"
                    stroke="#B6D9FF"
                    stroke-width="3"
                />
                <path
                    d="M76.0654 274.181H137.162"
                    stroke="#B6D9FF"
                    stroke-width="3"
                />
                <path
                    d="M76.0654 287.272H137.162"
                    stroke="#B6D9FF"
                    stroke-width="3"
                />
                <path
                    d="M76.0654 300.365H137.162"
                    stroke="#B6D9FF"
                    stroke-width="3"
                />
                <path
                    d="M76.0654 313.457H137.162"
                    stroke="#B6D9FF"
                    stroke-width="3"
                />
                <path
                    d="M76.0654 326.549H137.162"
                    stroke="#B6D9FF"
                    stroke-width="3"
                />
                <path
                    d="M76.0654 339.642H137.162"
                    stroke="#B6D9FF"
                    stroke-width="3"
                />
                <path
                    d="M76.0654 352.733H137.162"
                    stroke="#B6D9FF"
                    stroke-width="3"
                />
                <path
                    d="M76.0654 365.826H137.162"
                    stroke="#B6D9FF"
                    stroke-width="3"
                />
                <path
                    d="M150.254 121.438H211.351"
                    stroke="#46CE84"
                    stroke-width="3"
                />
                <path
                    d="M150.254 138.895H211.351"
                    stroke="#46CE84"
                    stroke-width="3"
                />
                <path
                    d="M150.254 156.351H211.351"
                    stroke="#46CE84"
                    stroke-width="3"
                />
                <path
                    d="M150.254 173.808H211.351"
                    stroke="#46CE84"
                    stroke-width="3"
                />
                <path
                    d="M150.254 191.264H211.351"
                    stroke="#46CE84"
                    stroke-width="3"
                />
                <path
                    d="M150.254 208.72H211.351"
                    stroke="#46CE84"
                    stroke-width="3"
                />
                <path
                    d="M150.254 226.176H211.351"
                    stroke="#46CE84"
                    stroke-width="3"
                />
                <path
                    d="M150.254 243.633H211.351"
                    stroke="#46CE84"
                    stroke-width="3"
                />
                <path
                    d="M150.254 261.089H211.351"
                    stroke="#46CE84"
                    stroke-width="3"
                />
                <path
                    d="M150.254 278.545H211.351"
                    stroke="#46CE84"
                    stroke-width="3"
                />
                <path
                    d="M150.254 296.001H211.351"
                    stroke="#46CE84"
                    stroke-width="3"
                />
                <path
                    d="M150.254 313.457H211.351"
                    stroke="#46CE84"
                    stroke-width="3"
                />
                <path
                    d="M150.254 330.914H211.351"
                    stroke="#46CE84"
                    stroke-width="3"
                />
                <path
                    d="M150.254 348.37H211.351"
                    stroke="#46CE84"
                    stroke-width="3"
                />
                <path
                    d="M150.254 365.826H211.351"
                    stroke="#46CE84"
                    stroke-width="3"
                />
                <path
                    d="M224.443 221.812H285.54"
                    stroke="black"
                    stroke-width="3"
                />
                <path
                    d="M224.443 234.903H285.54"
                    stroke="black"
                    stroke-width="3"
                />
                <path
                    d="M224.443 247.996H285.54"
                    stroke="black"
                    stroke-width="3"
                />
                <path
                    d="M224.443 261.088H285.54"
                    stroke="black"
                    stroke-width="3"
                />
                <path
                    d="M224.443 274.181H285.54"
                    stroke="black"
                    stroke-width="3"
                />
                <path
                    d="M224.443 287.272H285.54"
                    stroke="black"
                    stroke-width="3"
                />
                <path
                    d="M224.443 300.365H285.54"
                    stroke="black"
                    stroke-width="3"
                />
                <path
                    d="M224.443 313.457H285.54"
                    stroke="black"
                    stroke-width="3"
                />
                <path
                    d="M224.443 326.549H285.54"
                    stroke="black"
                    stroke-width="3"
                />
                <path
                    d="M224.443 339.642H285.54"
                    stroke="black"
                    stroke-width="3"
                />
                <path
                    d="M224.443 352.733H285.54"
                    stroke="black"
                    stroke-width="3"
                />
                <path
                    d="M224.443 365.826H285.54"
                    stroke="black"
                    stroke-width="3"
                />
                <path
                    d="M298.633 121.438H359.73"
                    stroke="#B6D9FF"
                    stroke-width="3"
                />
                <path
                    d="M298.633 138.895H359.73"
                    stroke="#B6D9FF"
                    stroke-width="3"
                />
                <path
                    d="M298.633 156.351H359.73"
                    stroke="#B6D9FF"
                    stroke-width="3"
                />
                <path
                    d="M298.633 173.808H359.73"
                    stroke="#B6D9FF"
                    stroke-width="3"
                />
                <path
                    d="M298.633 191.264H359.73"
                    stroke="#B6D9FF"
                    stroke-width="3"
                />
                <path
                    d="M298.633 208.72H359.73"
                    stroke="#B6D9FF"
                    stroke-width="3"
                />
                <path
                    d="M298.633 226.176H359.73"
                    stroke="#B6D9FF"
                    stroke-width="3"
                />
                <path
                    d="M298.633 243.633H359.73"
                    stroke="#B6D9FF"
                    stroke-width="3"
                />
                <path
                    d="M298.633 261.089H359.73"
                    stroke="#B6D9FF"
                    stroke-width="3"
                />
                <path
                    d="M298.633 278.545H359.73"
                    stroke="#B6D9FF"
                    stroke-width="3"
                />
                <path
                    d="M298.633 296.001H359.73"
                    stroke="#B6D9FF"
                    stroke-width="3"
                />
                <path
                    d="M298.633 313.457H359.73"
                    stroke="#B6D9FF"
                    stroke-width="3"
                />
                <path
                    d="M298.633 330.914H359.73"
                    stroke="#B6D9FF"
                    stroke-width="3"
                />
                <path
                    d="M298.633 348.37H359.73"
                    stroke="#B6D9FF"
                    stroke-width="3"
                />
                <path
                    d="M298.633 365.826H359.73"
                    stroke="#B6D9FF"
                    stroke-width="3"
                />
                <path
                    d="M372.822 25.4287H433.919"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M372.822 38.5205H433.919"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M372.822 51.6133H433.919"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M372.822 64.7051H433.919"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M372.822 77.7979H433.919"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M372.822 90.8896H433.919"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M372.822 103.982H433.919"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M372.822 117.074H433.919"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M372.822 130.166H433.919"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M372.822 143.259H433.919"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M372.822 156.351H433.919"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M372.822 169.443H433.919"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M372.822 182.535H433.919"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M372.822 195.627H433.919"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M372.822 208.72H433.919"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M372.822 221.812H433.919"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M372.822 234.904H433.919"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M372.822 247.996H433.919"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M372.822 261.089H433.919"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M372.822 274.181H433.919"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M372.822 287.272H433.919"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M372.822 300.365H433.919"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M372.822 313.457H433.919"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M372.822 326.55H433.919"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M372.822 339.642H433.919"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M372.822 352.733H433.919"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
                <path
                    d="M372.822 365.826H433.919"
                    stroke="#FF6B6B"
                    stroke-width="3"
                />
            </svg>
        </div>
    );
}
