import clsx from "clsx";

export const parentContainer = clsx(
    "flex w-full items-center justify-center h-[650px]",
);
export const textContainer = clsx("flex w-1/2 items-center justify-center");
export const chartDesc = clsx(
    "max-w-[140px] text-center text-xs font-bold uppercase underline",
);
export const chartContainer = clsx(
    "flex h-full w-1/2 flex-col items-center justify-center",
);
export const divider = clsx("h-[650px] h-full w-[2px] bg-black");
export const blackDot = clsx("h-2 w-2 bg-black -mt-1 rounded-full");
