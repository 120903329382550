import Loading from "./components/Loading";

const metaProps = {
    title: "Map individual trees at local to national scales with CTrees",
    desc: "CTrees' tree-level data provides information on individual trees at local to national scales, including in savannas, urban, and altered landscapes. Learn more about our innovative science.",
    img: "",
};

export const constants = {
    metaProps,
    loading: <Loading />,
    url: process.env.REACT_APP_REDDAI_URL,
};
