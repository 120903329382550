import clsx from "clsx";
import { Text } from "../components";
import * as styles from "./styles";

export default function ImgText({
    textRight = false,
    title,
    subheader,
    img,
    //-- Dummy URL for temporary testing --//
    bg = "https://s3-alpha-sig.figma.com/img/d40a/7376/a6247c49379a509ed8597cc1a9b79857?Expires=1728259200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=PdDcsyhqVjacBZoX2b5oTqIeBQrSg-C8zqDXzc9fjZbfwy5-ETCq9HG47HxVGeCvQfwXOWNdmloaevS7hFITFRAhywAzo-L4SoQ1eYvo-xz2JndFizKrYxS~aYvGkzQe0QJKZZeMICD-z8KGfp1PjD4e10RJEYHO-ILJ-s1InxGjBnokVokj7E8eTDWJDS5fThXtWppJbl3apipBN9lYFyyLJ33dh0Fy7RJMzQI399GlO26NdEZ4B-I3oc7xnowd2hVFMWqC9ZMQnioVqkbKEGVhdD61ZTP4MgLZQNBfPJB2oVnbJJfgUBg7mTXJano9TJSJDW5uWgLd~J6HYdj9xg__",
    bgAlt,
}) {
    const textProps = { title, subheader, green: "bg-green", textRight };
    const roundedEdges = textRight ? " rounded-l-xl" : " rounded-r-xl";
    const bgImgStyle = clsx(
        "absolute z-10 object-cover h-full w-full" + roundedEdges,
    );
    
    return (
        <>
            {/* DESKTOP */}
            <div className="hidden h-[600px] w-full md:flex">
                <div className="mx-10 flex h-full w-full">
                    {!textRight && <Text {...textProps} />}
                    <div className="relative h-full w-1/2">
                        <img alt={bgAlt} src={bg} className={bgImgStyle} />
                        <div className={styles.blackOverlay + roundedEdges}></div>
                        <div className={styles.img}>{img}</div>
                    </div>
                    {textRight && <Text {...textProps} />}
                </div>
            </div>

            {/* MOBILE */}
            <div className="my-20 w-full md:hidden">
                <div className="mx-8 text-sm md:text-md">
                    <div className={styles.mobileTitle}>{title}</div>
                    <div className="relative h-[300px] w-full">
                        <img alt={bgAlt} src={bg} className={styles.bgImg} />
                        <div className={styles.blackOverlay}></div>
                        <div className={styles.img}>{img}</div>
                    </div>
                    <div
                        className="rounded-b-lg bg-green p-8"
                        dangerouslySetInnerHTML={{ __html: subheader }}
                    ></div>
                </div>
            </div>
        </>
    );
}
