export const metaDataHeader = {
    title: "",
    desc: "",
    img: "",
};

const s3Bucket =
    "https://ctrees-website.s3.us-west-2.amazonaws.com/website_images/landing/";

export const dataCards = [
    {
        img: `${s3Bucket}JMRV_platform_screenshot_brazil.png`,
        imgAlt: "",
        title: "Jurisdictional MRV",
        subheader:
            "At COP29, CTrees added 2023 data to an open tool measuring carbon stocks, emissions, and removals in all jurisdictions globally.",
        link: "/news/jmrv-update-2024",
        linkDisplayText: "learn more",
    },
    {
        img: `${s3Bucket}luca_screenshot.jpg`,
        imgAlt: "",
        title: "Land Use Change Alerts",
        subheader:
            "Since Sept. 2023, CTrees has provided near-real-time information on land use change activities and forest disturbance in all forests globally.",
        link: "/luca",
        linkDisplayText: "access data",
    },
    {
        img: `${s3Bucket}REDDAI_roads_screenshot.png`,
        imgAlt: "",
        title: "REDD+AI",
        subheader:
            "In Oct. 2024, CTrees launched the first map of degradation across the tropics, with attribution to fire, logging, and roads.",
        link: "/reddai",
        linkDisplayText: "access data",
    },
    {
        img: `${s3Bucket}land_carbon_map.png`,
        imgAlt: "",
        title: "Land Carbon Map",
        subheader:
            "CTrees scientists published a map of live carbon for every hectare of forest across Gabon, developed in partnership with Gabonese institutions, the U.S. Forest Service, NASA and UCLA.",
        link: "/news/gabon-carbon-map",
        linkDisplayText: "learn more",
    },
    {
        img: `${s3Bucket}tree_overview_amazon_image.jpeg`,
        imgAlt: "",
        title: "Tree Level Data",
        subheader:
            "In Dec. 2024, CTrees found what is likely one of the largest trees in the Amazon rainforest.",
        link: "/news/largest-tree-amazon-with-fabien-wagner",
        linkDisplayText: "learn more",
    },
];

export const dataSubheader = "";
