import clsx from "clsx";

export default function HeroOverlay({ className, img }) {
    return (
        <>
            <img src={img} className={className + imgStyle} />
            <div className={overlayStyle}></div>
        </>
    );
}

const imgStyle = clsx(" w-full h-full object-cover absolute top-0 left-0");
const overlayStyle = clsx(imgStyle + " bg-black/60");
