import clsx from "clsx";

export default function CloseIcon({ onClick, className }) {
    return (
        <div onClick={onClick} className={className + " cursor-pointer"}>
            <div className={rightX}></div>
            <div className={leftX}></div>
        </div>
    );
}

const x = clsx("h-[2px] w-[40px] bg-black rounded-xl");
const rightX = clsx(x + " rotate-45 -mb-[1px]");
const leftX = clsx(x + " rotate-[135deg] -mt-[1px]");
