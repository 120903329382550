import { SolutionEarth } from "@assets/lottie";
import * as constants from "./constants";
import SolutionTemplate from "../../template";

export default function GlobalAssessment() {
    const props = {
        constants,
        animationData: SolutionEarth,
    };
    return <SolutionTemplate {...props} />;
}
