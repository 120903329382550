import { UnderlinedSectionHeader, Button } from "@components";
import { routesFlat } from "@routes";
import { textContainer } from "../../";

export default function Careers() {
    return (
        <>
            <UnderlinedSectionHeader className="mt-10 lg:mt-20">
                careers
            </UnderlinedSectionHeader>
            <div className={textContainer}>
                While we are not currently hiring, we are excited to connect
                with talented individuals with strong backgrounds in science and
                technology. Please check back soon for future opportunities.
            </div>
            <Button
                to={routesFlat.contact}
                className="mb-40 px-4 lg:mb-20"
                primary={false}
            >
                contact our team
            </Button>
        </>
    );
}
