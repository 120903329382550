import { useEffect, useState } from "react";
import clsx from "clsx";
import { useParams } from "react-router-dom";

import { StayConnected } from "@components";
import { tag as tagStyle } from "@components/Cards/Blue/styles";
import { Featured } from "../news/components";

import { formatDateMonth, getRelatedArticles, getArticle } from "@helpers";
import { feTagsFlat } from "@helpers/constants";
import { wrapImagesWithDiv } from "./helpers/htmlWrappers";
import "./article.css";

export default function Article() {
    const { id } = useParams();
    const [article, setArticle] = useState([]);
    const [tags, setTags] = useState([]);
    const [relatedArticles, setRelated] = useState([]);
    
    const date = article?.publish_date
        ? formatDateMonth(article?.publish_date)
        : "";

    useEffect(() => {
        getArticle(id).then((item) => {
            const body = wrapImagesWithDiv(item.body);
            const newItem = { ...item, body };
            const tags = item.product_tags.concat(item.article_tags);

            setTags(tags);
            setArticle(newItem);
        });
    }, [id]);

    useEffect(() => {
        getRelatedArticles(tags, article.id).then((articles) => {
            setRelated(articles);
        });
    }, [tags]);

    return (
        <div className="mb-20 flex w-full flex-col">
            <div className={articleContainer}>
                <div className="px-10 md:w-[600px]">
                    <div className="text-4xl font-bold">{article.title}</div>
                    <div className="my-4 flex w-full">
                        {/* <div className="text-wrap italic">
                            By {article.author}
                        </div> */}
                        <div className="whitespace-nowrap text-black/40">
                            {/* <span className="pr-2">|</span> */}
                            {date}
                        </div>
                    </div>
                    <div className="flex w-full">
                        {tags.map((tag) => (
                            <div className={clsx(tagStyle + "!bg-black/5")}>
                                {feTagsFlat[tag]}
                            </div>
                        ))}
                    </div>
                    <div
                        className={htmlWrapper}
                        dangerouslySetInnerHTML={{ __html: article.body }}
                    ></div>
                </div>
            </div>
            <div className={relatedContainer}>
                <div className="mb-10 w-[90%] text-2xl font-bold">
                    Related Articles
                </div>
                <Featured articles={relatedArticles} />
            </div>
            <StayConnected />
        </div>
    );
}

const relatedContainer = clsx("md:mb-20 flex w-full flex-col items-center");
const htmlWrapper = clsx(
    "article-body mb-20 flex h-full flex-col md:text-[16px] lg:mb-40",
);
const articleContainer = clsx(
    "flex flex-col items-center justify-center pt-36 md:mt-0",
);
