import clsx from "clsx";

export const parent = clsx("flex w-full items-center justify-center mb-20 md:mb-40");
export const rowContainer = clsx("flex flex-col md:flex-row w-full md:max-w-[1000px] items-center justify-center md:space-x-10 p-4 md:p-10 lg:p-20")
export const imgSvgParent = clsx("flex w-full md:w-1/2 flex-col items-center justify-center mt-10 md:mt-0 mb-2");
export const listParentDesktop = clsx(imgSvgParent, "hidden md:block");
export const listParentMobile = clsx(imgSvgParent, "md:hidden");

export const listContainer = clsx("w-full flex flex-col max-w-[400px]")
export const img = clsx("object-fit h-[300px] w-full max-w-[400px]")
export const svg = clsx("absolute inset-0")
export const greenBullet = clsx("rounded-full bg-green h-2 w-2 mr-4 mt-2")

export const rowsParent = clsx(
    "flex w-[90%] flex-col items-center justify-center rounded-lg bg-gray md:p-0",
);