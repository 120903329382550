import { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import * as styles from "./styles";

export function DropdownMenu({ items, isOpen, closeDropdown }) {
    const [height, setHeight] = useState(0);
    const contentRef = useRef(null);

    useEffect(() => {
        if (contentRef.current) {
            setHeight(isOpen ? contentRef.current.scrollHeight : 0);
        }
    }, [isOpen]);

    const nestedContainer = clsx(
        styles.nestedRouteContainer +
            (isOpen ? styles.nestedOpen : styles.nestedClose),
    );
    return (
        <div
            ref={contentRef}
            className={nestedContainer}
            onMouseLeave={closeDropdown}
            style={{ height: `${height}px` }}
        >
            {items?.map((nestedRoute) => (
                <>
                    <Link
                        key={nestedRoute.display}
                        to={nestedRoute.path}
                        onClick={closeDropdown}
                        className={styles.nestedNavItem}
                    >
                        {nestedRoute.display}
                    </Link>
                    {nestedRoute.divide && (
                        <div className={styles.nestedItemDivider}></div>
                    )}
                </>
            ))}
        </div>
    );
}
