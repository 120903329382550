export default function NewWindow({ fill = "#46CE84" }) {
    return (
        <svg
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g>
                <path
                    d="M8 11.6719H12V10.1719H8V11.6719ZM8 9.17188H15V7.67188H8V9.17188ZM8 6.67188H15V5.17188H8V6.67188ZM6.5 15.1719C6.0875 15.1719 5.73438 15.025 5.44062 14.7312C5.14687 14.4375 5 14.0844 5 13.6719V3.67188C5 3.25938 5.14687 2.90625 5.44062 2.6125C5.73438 2.31875 6.0875 2.17188 6.5 2.17188H16.5C16.9125 2.17188 17.2656 2.31875 17.5594 2.6125C17.8531 2.90625 18 3.25938 18 3.67188V13.6719C18 14.0844 17.8531 14.4375 17.5594 14.7312C17.2656 15.025 16.9125 15.1719 16.5 15.1719H6.5ZM6.5 13.6719H16.5V3.67188H6.5V13.6719ZM3.5 18.1719C3.0875 18.1719 2.73438 18.025 2.44063 17.7312C2.14688 17.4375 2 17.0844 2 16.6719V5.17188H3.5V16.6719H15V18.1719H3.5Z"
                    fill={fill}
                />
            </g>
        </svg>
    );
}
