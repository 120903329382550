import clsx from "clsx";
import * as global from "@components";
import * as components from "./components";
import * as constants from "./constants";

export default function Landing() {
    const dataProps = {
        cards: constants.dataCards,
        subheader: constants.dataSubheader,
    };
    return (
        <>
            <global.MetaTagHeader {...constants.metaDataHeader} />
            <div className={parentContainerStyle}>
                <components.ScrollProgress />
                <components.Hero />
                <components.WhyCTrees />
                <components.Solutions />
                <global.Data {...dataProps} />
                <components.Partners />
                <components.News />
            </div>
            <global.StayConnected />
        </>
    );
}
const parentContainerStyle = clsx(
    "w-full flex flex-col justify-center items-center",
);
