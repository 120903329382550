import Wrapper from "../../template";

export default function LineChart() {
    // line chart is positioned to the left of the screen off the overlay
    return (
        <Wrapper className={"absolute -translate-x-[10%] translate-y-[30%] transform"}>
            <svg
                width="170"
                height="129"
                viewBox="0 0 170 129"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g filter="url(#filter0_b_2981_11118)">
                    <g clip-path="url(#clip0_2981_11118)">
                        <rect
                            y="0.168945"
                            width="170"
                            height="128"
                            rx="36"
                            fill="black"
                            fill-opacity="0.75"
                        />
                        <path
                            d="M-36.7578 98.7305H22.1723L58.4727 19.2842L78.0918 45.0041L95.2946 67.427L132.894 58.8135L153.72 128.169L188.362 90.7305L206.758 98.7305"
                            stroke="#FF6B6B"
                            stroke-width="3"
                        />
                        <path
                            d="M-4.89453 95.151L32.3816 84.651L64.3252 52.3066L113.98 95.151L146.937 84.651L174.895 66.651"
                            stroke="#DFF841"
                            stroke-width="3"
                        />
                    </g>
                </g>
                <defs>
                    <filter
                        id="filter0_b_2981_11118"
                        x="-40"
                        y="-39.8311"
                        width="250"
                        height="208"
                        filterUnits="userSpaceOnUse"
                        color-interpolation-filters="sRGB"
                    >
                        <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                        />
                        <feGaussianBlur
                            in="BackgroundImageFix"
                            stdDeviation="20"
                        />
                        <feComposite
                            in2="SourceAlpha"
                            operator="in"
                            result="effect1_backgroundBlur_2981_11118"
                        />
                        <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect1_backgroundBlur_2981_11118"
                            result="shape"
                        />
                    </filter>
                    <clipPath id="clip0_2981_11118">
                        <rect
                            y="0.168945"
                            width="170"
                            height="128"
                            rx="36"
                            fill="white"
                        />
                    </clipPath>
                </defs>
            </svg>
        </Wrapper>
    );
}
