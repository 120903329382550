export default function BarChart({ className }) {
    return (
        <svg
            width="162"
            height="105"
            viewBox="0 0 162 105"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g filter="url(#filter0_b_2981_11136)">
                <rect
                    x="0.785156"
                    y="104.83"
                    width="104.069"
                    height="160.789"
                    rx="22.2703"
                    transform="rotate(-90 0.785156 104.83)"
                    fill="black"
                    fill-opacity="0.75"
                />
                <rect
                    x="23.0483"
                    y="82.5674"
                    width="59.5425"
                    height="32.1578"
                    transform="rotate(-90 23.0483 82.5674)"
                    fill="#FF6B6B"
                />
                <rect
                    x="65.1006"
                    y="82.5674"
                    width="44.9877"
                    height="32.1578"
                    transform="rotate(-90 65.1006 82.5674)"
                    fill="#DFF841"
                />
                <rect
                    x="107.153"
                    y="82.5674"
                    width="33.0792"
                    height="32.1578"
                    transform="rotate(-90 107.153 82.5674)"
                    fill="#46CE84"
                />
            </g>
            <defs>
                <filter
                    id="filter0_b_2981_11136"
                    x="-73.4491"
                    y="-73.4725"
                    width="309.257"
                    height="252.537"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur
                        in="BackgroundImageFix"
                        stdDeviation="37.1171"
                    />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_2981_11136"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_2981_11136"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
}
