import Wrapper from "../../template";
import Zoom from "./Zoom";
import Expander from "./Expander";

export default function ZoomControls() {
    return (
        <Wrapper>
            <Zoom />
            <Expander />
        </Wrapper>
    );
}

