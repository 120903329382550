import clsx from "clsx";

export default function HorizontalScrollWrapper({ children, className }) {
    return (
        <div
            className={clsx(overflowContainerStyle + className)}
            style={{ scrollbarWidth: "none" }}
        >
            <div className="flex h-full w-[3000px]">{children}</div>
        </div>
    );
}

const overflowContainerStyle = clsx(
    "flex w-screen overflow-y-hidden overflow-x-scroll md:pl-10 ",
);
