import clsx from "clsx";

export const parent = clsx("my-20 md:my-40 flex w-full justify-center items-center");
export const wrapper = clsx(
    "flex w-[70%] flex-col items-center rounded-lg bg-gray px-10 md:px-20 pb-20 pt-14",
);
export const expandContainer = clsx("mt-10 flex w-full flex-col items-center");
export const tagContainer = clsx(
    "relative w-full border-b-[1px] border-b-black/40 py-4 first:border-t-2 first:border-t-[1px] first:border-t-black/40",
);
export const tagLabel = clsx(
    "flex cursor-pointer items-center justify-between gap-4 text-lg",
);