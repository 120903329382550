import clsx from "clsx";
import { useState } from "react";
import { Link } from "react-router-dom";

import { date, text, rightColHeader } from "../styles";
import { routesFlat } from "@routes";
import { RocketIcon } from "@assets/icons";

export default function Featured() {
    const [openReadMore, setOpenReadMore] = useState(false);
    return (
        <>
            <div className={rightColHeader}>featured product update</div>
            <Link to={"jmrv-update-2024"}>
                <div className={clsx(date + " tracking-wider")}>
                    Nov 15, 2024
                </div>
                <div
                    className={clsx(
                        text + (openReadMore ? "" : " line-clamp-3"),
                    )}
                >
                    Trees’ Jurisdictional MRV platform provides open data on
                    forest area, carbon stocks, emissions, and removals for 263
                    countries and territories globally. The latest update to the
                    tool includes increased granularity of data and a 2023
                    biomass map to visualize where carbon stocks are located
                    within jurisdictions.
                </div>
            </Link>
            <div
                className={readMore}
                onClick={() => setOpenReadMore(!openReadMore)}
            >
                read {openReadMore ? "less" : "more"}
            </div>
            <Link to={routesFlat.jmrv} className="flex text-sm font-bold">
                <RocketIcon />
                <div className="ml-2 border-b-[1px] border-b-red">Try Tool</div>
            </Link>
        </>
    );
}

const readMore = clsx(
    "-mt-2 mb-2 cursor-pointer text-sm text-black/40 underline",
);
