import clsx from "clsx";

export const title = clsx("mt-4 text-6xl font-bold uppercase tracking-wider");
export const desc = clsx("mt-2 py-8 text-2xl tracking-wider");
export const img = clsx("h-[300px] w-full rounded-xl object-cover mt-10");

export const rowContainer = clsx("flex justify-between w-full");
export const colContainer = clsx("flex flex-col w-1/2");

export const featTitle = clsx(
    "uppercase font-bold tracking-widest text-sm mt-10 mb-2",
);
export const featCatTitle = clsx("text-black/50 capitalize mt-4");
