// NOTE: Need to import before reassigning to export
// because json files dont have default export values.
import Beat1Import from './landing/Beat_1'
import Beat2Import from './landing/Beat_2'
import Beat3Import from './landing/Beat_3'

import LineImport from './Line'

import CaliImport from './solutions/California'
import EarthImport from './solutions/Earth'
import TreeImport from './solutions/Tree'

// Generic
export const defaultOptions = {
    loop: true,
    autoplay: true,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};
export const Line = LineImport

// Homepage
export const Beat1 = Beat1Import
export const Beat2 = Beat2Import
export const Beat3 = Beat3Import

export const SolutionCali = CaliImport
export const SolutionEarth = EarthImport
export const SolutionTree = TreeImport