import { Link, useParams } from "react-router-dom";
import * as styles from "./styles";
import ScaleOnHoverDiv from "../../ScaleOnHoverDiv";

export default function GreenCard({
    img,
    imgAlt,
    title,
    subheader,
    linkDisplayText,
    link,
    children,
}) {
    // If pre-existing id, need to ensure there's no
    // double nesting by using <a/> instead of <Link/>
    const { id } = useParams();

    const CardBody = () => (
        <>
            <div className={styles.imgContainer}>
                <img
                    src={img}
                    alt={imgAlt}
                    className="min-h-full min-w-full object-cover"
                />
            </div>
            <div className={styles.textContainer}>
                <div className="line-clamp-2 text-xl font-bold">{title}</div>
                <div className={styles.subheader}>{subheader}</div>
                <div className={styles.linkText}>{linkDisplayText}</div>
                {children}
            </div>
        </>
    );
    return (
        <ScaleOnHoverDiv className={styles.parentContainer}>
            {id ? (
                <a href={link} className={styles.linkContainer}>
                    <CardBody />
                </a>
            ) : (
                <Link to={link} className={styles.linkContainer}>
                    <CardBody />
                </Link>
            )}
        </ScaleOnHoverDiv>
    );
}
