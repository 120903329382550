import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import clsx from "clsx";

import { DottedDivider, Button } from "@components";
import { LeftCol, RightCol } from "./components";
import * as styles from "./styles";

export default function Feature(props) {
    const selectedTabTitle = props.tabs[props.selectedTab];
    const tabContent = props.tabsContent[selectedTabTitle];

    const motionDivProps = {
        key: props.selectedTab,
        initial: { opacity: 0, y: 10 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -10 },
        transition: { duration: 0.2 },
        className: "ml-20 w-full",
    };

    return (
        <AnimatePresence mode="wait">
            <motion.div {...motionDivProps}>
                <div className={styles.title}>{selectedTabTitle}</div>
                <DottedDivider className="mt-4 w-full" />
                <div className={styles.desc}>{tabContent.desc}</div>
                {tabContent.productLink && (
                    <Button to={tabContent.productLink} className="mb-10 w-1/3">
                        Launch {tabContent.productName}
                    </Button>
                )}
                <DottedDivider className="mt-4 w-full" />
                <img
                    src={tabContent.img}
                    alt={tabContent.imgDesc}
                    className={styles.img}
                />
                <div className="mt-3 text-xs text-black/90">
                    {tabContent.imgDesc}
                </div>
                <div className={styles.rowContainer}>
                    <LeftCol tabContent={tabContent} />
                    <RightCol tabContent={tabContent} />
                </div>
            </motion.div>
        </AnimatePresence>
    );
}

export function LinkText({ to, className, children }) {
    return (
        <Link to={to} className={clsx(className + linkTextStyle)}>
            {children}
        </Link>
    );
}

const linkTextStyle = clsx(" flex items-center text-black/50 underline pt-2");
