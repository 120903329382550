import { useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { DottedDivider } from "@components";

export default function MenuItem({ path, display, onClick, nested, open = false }) {
    const [isOpen, setOpen] = useState(open);
    
    const onLinkClick = () => {
        if (path) {
            onClick();
        }
    };

    return (
        <motion.li
            variants={itemVariants}
            whileHover={!nested && { scale: 1.1 }}
            whileTap={!nested && { scale: 0.95 }}
            onClick={onLinkClick}
            className="list-none last:pb-40"
        >
            <div className="text-center text-6xl mt-10 font-bold">
                {nested ? (
                    <div
                        onClick={() => setOpen(!isOpen)}
                        className={nestedLabel}
                    >
                        <div>
                            {display}
                            <DottedDivider className="mt-2 w-full" />
                        </div>
                    </div>
                ) : (
                    <Link to={path}>{display}</Link>
                )}
            </div>
            {isOpen && nested && (
                <div className="mb-10 mt-10">
                    {nested?.map((item) => {
                        return (
                            <motion.li
                                variants={itemVariants}
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                <Link to={item.path} onClick={onClick}>
                                    <div className={nestedItem}>
                                        {item.display}
                                    </div>
                                </Link>
                            </motion.li>
                        );
                    })}
                </div>
            )}
        </motion.li>
    );
};

const nestedLabel = clsx(
    "underline-dotted flex w-full cursor-pointer flex-col items-center justify-center",
);
const nestedItem = clsx("mt-6 w-auto text-center text-xl");

const itemVariants = {
    open: {
        y: 0,
        opacity: 1,
        transition: {
            y: { stiffness: 1000, velocity: -100 },
        },
    },
    closed: {
        y: 50,
        opacity: 0,
        transition: {
            y: { stiffness: 1000 },
        },
    },
};
