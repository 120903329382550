import clsx from "clsx";
import { useState, useContext, useEffect } from "react";

import { NewsContext } from "@";
import { paginate } from "@helpers";
import * as components from "@components";
import { Featured, MostRecent } from './components'

const metaProps = {
    title: "News and updates on global forest carbon from CTrees",
    desc: "CTrees’ data products, science, and partnerships to protect and restore forests are constantly evolving. Learn more about our work.",
    img: "",
};

export default function News() {
    const [currentPaginatedPage, setPaginatedPage] = useState(0);
    const { articles } = useContext(NewsContext);

    const featured = articles?.slice(0, 5);
    const paginatedArticles = paginate(articles, 9);

    const [articlesInView, setArticlesInView] = useState(paginatedArticles[0]);

    useEffect(() => {
        setArticlesInView(paginatedArticles[currentPaginatedPage]);
    }, [currentPaginatedPage, articles]);

    const moveBackAPage = () => {
        if (currentPaginatedPage > 0) {
            setPaginatedPage(currentPaginatedPage - 1);
        }
    };
    const moveForwardAPage = () => {
        if (currentPaginatedPage + 1 < paginatedArticles.length) {
            setPaginatedPage(currentPaginatedPage + 1);
        }
    };
    return (
        <>
            <components.MetaTagHeader {...metaProps} />
            <div className="flex w-full justify-center">
                <div className="mb-40 mt-24 flex w-[90%] flex-col items-center">
                    
                    {/* Featured Article */}
                    <MostRecent article={featured[0]}/>

                    {/* Featured Articles */}
                    <div className="mb-10 w-full text-2xl font-bold">
                        Featured Articles
                    </div>
                    <Featured articles={featured} />

                    {/* Blue Cards Header */}
                    <div className="flex w-full justify-between">
                        <div className="mb-10 text-2xl font-bold">
                            All Insights
                        </div>
                        {/* NOTE: v2 Release */}
                        {/* <div className="flex w-[300px] justify-around text-xl tracking-wider">
                            <div className="uppercase">Search</div>
                            <div className="uppercase">Filter</div>
                        </div> */}
                    </div>

                    {/* Blue Cards */}
                    <div className={blueCardContainer}>
                        {articlesInView?.map((item, i) => (
                            <components.BlueCard article={item} />
                        ))}
                    </div>
                    <components.CarouselCounter
                        numOfDots={paginatedArticles.length}
                        currentDot={currentPaginatedPage}
                        onClickBack={moveBackAPage}
                        onClickForward={moveForwardAPage}
                    />
                </div>
            </div>

            <components.StayConnected />
        </>
    );
}

const blueCardContainer = clsx(
    "grid w-full grid-cols-3 flex-wrap content-center",
);
