import clsx from "clsx";
import { MetaTagHeader, DottedDivider } from "@components";
import * as components from "./components";

export default function About() {
    return (
        <>
            <MetaTagHeader {...metaProps} />
            <div className="flex justify-center md:mb-20">
                <div className="flex w-full flex-col items-center">
                    <components.Hero />
                    <components.WhoWeAre />
                    <DottedDivider className="lg:hidden w-full mb-20" />
                    <components.Team />
                    <components.Careers />
                </div>
            </div>
        </>
    );
}

export const textContainer = clsx(
    "flex flex-wrap my-12 px-4 max-w-[900px] text-center text-2xl",
);

const metaProps = {
    title: "About: The trusted source for forest carbon data",
    desc: "CTrees scientists and engineers have more than 20 years of experience in building global carbon monitoring systems. Learn more about our team. ",
    img: "",
};
