import Wrapper from "../../template";
import BarChart from "./BarChart";
import ChartSwitch from "./ChartSwitch";
import clsx from "clsx";

export default function ChartType() {
    return (
        <Wrapper>
            <BarChart className={barChartPosition} />
            <ChartSwitch />
        </Wrapper>
    );
}

// reposition bar chart icon to be on the right side of the screen off the overlay
const barChartPosition = clsx('absolute right-0 translate-x-[15%] -translate-y-[150%] transform');