import Wrapper from "../../template";
import ProgressBars from "./ProgressBars";
import Rings from "./Rings";
import clsx from "clsx";

export default function ProgressRing() {
    return (
        <Wrapper>
            <Rings className={ringsPosition} />
            <ProgressBars className={progressBarsPosition} />
        </Wrapper>
    );
}

// each icon is positioned absolutely slightly off the overlay
const ringsPosition = clsx('absolute bottom-4 -left-6 md:-left-12 mb-4 ml-4');
const progressBarsPosition = clsx('absolute -right-12 bottom-32 mr-4 mt-4');