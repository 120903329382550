export default function LinkIcon() {
    return (
        <svg
            width="21"
            height="11"
            viewBox="0 0 21 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.86523 10.0625H5.86523C4.4819 10.0625 3.30273 9.575 2.32773 8.6C1.35273 7.625 0.865234 6.44583 0.865234 5.0625C0.865234 3.67917 1.35273 2.5 2.32773 1.525C3.30273 0.55 4.4819 0.0625 5.86523 0.0625H9.86523V2.0625H5.86523C5.0319 2.0625 4.32357 2.35417 3.74023 2.9375C3.1569 3.52083 2.86523 4.22917 2.86523 5.0625C2.86523 5.89583 3.1569 6.60417 3.74023 7.1875C4.32357 7.77083 5.0319 8.0625 5.86523 8.0625H9.86523V10.0625ZM6.86523 6.0625V4.0625H14.8652V6.0625H6.86523ZM11.8652 10.0625V8.0625H15.8652C16.6986 8.0625 17.4069 7.77083 17.9902 7.1875C18.5736 6.60417 18.8652 5.89583 18.8652 5.0625C18.8652 4.22917 18.5736 3.52083 17.9902 2.9375C17.4069 2.35417 16.6986 2.0625 15.8652 2.0625H11.8652V0.0625H15.8652C17.2486 0.0625 18.4277 0.55 19.4027 1.525C20.3777 2.5 20.8652 3.67917 20.8652 5.0625C20.8652 6.44583 20.3777 7.625 19.4027 8.6C18.4277 9.575 17.2486 10.0625 15.8652 10.0625H11.8652Z"
                fill="#46CE84"
            />
        </svg>
    );
}
