import clsx from "clsx";

export default function LoadingAnimation (){
    return (
        <div className={containerStyle}>
            <div
                className={circleStyle}
                style={{ animationDelay: "0s" }}
            ></div>
            <div
                className={circleStyle}
                style={{ animationDelay: "0.1s" }}
            ></div>
            <div
                className={circleStyle}
                style={{ animationDelay: "0.2s" }}
            ></div>
            <div
                className={circleStyle}
                style={{ animationDelay: "0.3s" }}
            ></div>
            <div
                className={circleStyle}
                style={{ animationDelay: "0.4s" }}
            ></div>
        </div>
    );
};

const containerStyle = clsx("flex h-screen items-center justify-center space-x-2");
const circleStyle = clsx(`h-5 w-5 animate-bounce rounded-full bg-[#46CE84]`);
