import {
    LineBarChart,
    LineChart,
    TargetsAndLines,
} from "@assets/beats/solutions";

export const metaDataHeader = {
    title: "",
    desc: "",
    img: "",
};

export const latest = [
    {
        title: "CTrees presents on alerts for jurisdictional monitoring at AGU24",
        date: "12.11.24",
        link: "/news/AGU24",
    },
    {
        title: "2023 data on carbon stocks, emissions, and removals for all jurisdictions globally now available",
        date: "11.15.24",
        link: "/jmrv",
    },
    {
        title: "CTrees introduces first platform for measuring degradation in all tropical forest countries",
        date: "10.21.24",
        link: "/news/reddai-press-release",
    },
];

export const hero = {
    title: "Country & State Programs",
    subheader:
        "CTrees helps jurisdictions map their forests, providing operational data to reduce deforestation and preserve carbon sinks.",
    bgImg: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/hero/country-and-state.png",
};

export const firstBeat = {
    title: "Accounting is needed for reporting and finance",
    subheader:
        "<p>Accurate measurement, reporting, and verification (MRV) are required for countries and states to scale up financing for natural climate solutions.</p>" +
        "<p class='mt-6'>However, few countries currently meet the IPCC’s highest standards for greenhouse gas inventory measurement. In particular, tropical forest countries lack national forest inventory plots and forest monitoring systems.</p>",
    img: <LineBarChart className={"h-full w-3/5"} />,
};
export const secondBeat = {
    title: "See the forest and the trees",
    subheader:
        "<p>CTrees’ global platform offers countries access to consistent annual data for reporting on carbon fluxes for land use activities in all ecosystems.</p>" +
        "<p class='mt-6'>Our scientific data is developed and distributed at the smallest scale of management units (< 1.0 hectare), enabling decision-makers to design and implement nature-based climate solutions at all scales, and to demonstrate the results of their efforts to reduce carbon emissions from deforestation and degradation.</p>",
    img: <TargetsAndLines />,
    bg: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/beats/country-and-state.png",
    textRight: true,
};
export const thirdBeat = {
    title: "MRV for high-integrity programs",
    subheader:
        "<p>CTrees helps countries and states develop MRV for reporting and finance, including:</p>" +
        "<ul class='pl-5 mb-6 list-disc'><li class='pt-6'>National forest inventories (NFIs)</li>" +
        "<li>Jurisdictional REDD+ programs</li>" +
        "<li>Forest reference emission levels (FRELs)</li>" +
        "<li>Nationally determined contributions (NDCs) to the Paris Agreement on climate change</li>" +
        "<li>Wildfire fuel management & mitigation</li></ul>" +
        "<p>Our team of scientists has worked for more than 20 years to provide national and state agencies with operational data.",
    img: <LineChart className={"h-full w-3/5"} />,
};

export const dataCards = [
    {
        img: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/data/jmrv.png",
        imgAlt: "",
        title: "Jurisdictional MRV",
        subheader:
            "New data finds emissions from deforestation and degradation increased in 2023.",
        link: "/jmrv",
        linkDisplayText: "Read Case Study",
    },
    {
        img: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/data/land-carbon.png",
        imgAlt: "",
        title: "Land Carbon Map",
        subheader:
            "New data finds emissions from deforestation and degradation increased in 2023.",
        link: "/land-carbon",
        linkDisplayText: "Read Case Study",
    },
    {
        img: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/data/tree-level.png",
        imgAlt: "",
        title: "Tree-Level Data",
        subheader: "New data finds height of trees around the globe.",
        link: "/jmrv",
        linkDisplayText: "Read Case Study",
    },
    {
        img: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/data/river.png",
        imgAlt: "",
        title: "River Basin Data",
        subheader:
            "New data finds emissions from deforestation and degradation increased in 2023.",
        link: "/land-carbon",
        linkDisplayText: "Read Case Study",
    },
    {
        img: "https://ctrees-website.s3.amazonaws.com/webv3/solutions/data/land-carbon.png",
        imgAlt: "",
        title: "Other data",
        subheader:
            "New data finds emissions from deforestation and degradation increased in 2023.",
        link: "/land-carbon",
        linkDisplayText: "Read Case Study",
    },
];

export const dataSubheader =
    "Work with CTrees to develop customized datasets for MRV";
