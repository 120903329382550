import clsx from "clsx";
import { HeroOverlay } from "@components";

export default function Hero() {
    return (
        <div className={containerStyle}>
            <HeroOverlay img="https://images.pexels.com/photos/70069/pexels-photo-70069.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" />
            <div className={titleStyle}>data</div>
            <div className="z-10 w-1/2">
                <div className="max-w-[500px] text-xl text-white">
                    Backed by peer-reviewed science, CTrees datasets are
                    designed to bring confidence and clarity to efforts to
                    protect and restore ecosystems on land globally at any
                    scale.
                </div>
            </div>
        </div>
    );
}

const containerStyle = clsx(
    "px-20 flex h-[500px] w-full items-center justify-between relative",
);
const titleStyle = clsx("w-1/2 text-6xl font-bold uppercase text-white z-10");
