import Skeleton from "react-loading-skeleton";
import * as styles from "./styles";

export default function Loading() {
    return (
        <div className={styles.container}>
            <div className="hidden pt-4 md:flex">
                <Skeleton className={styles.panelSkeleton} count={4} />
            </div>
            <div className={styles.rightSideContainer}>
                <Skeleton className={styles.searchSkeleton} />
                <Skeleton className={styles.rightSideElement + " mr-2 w-32"} />
            </div>
            <Skeleton className={styles.panelSkeleton + " md:hidden"} count={4} />
        </div>
    );
}


