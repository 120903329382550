import clsx from "clsx";
import { Link } from "react-router-dom";
import { routesFlat } from "@routes";

export default function Links() {
    return (
        <div className={containerStyle}>
            <Link to={routesFlat.landing} className={textStyle}>
                Home
            </Link>
            <Link to={routesFlat.news} className={textStyle}>
                News
            </Link>
            <Link to={routesFlat.products} className={textStyle}>
                Products
            </Link>
            <Link to={routesFlat.about} className={textStyle}>
                About
            </Link>
        </div>
    );
}

const containerStyle = clsx("flex flex-col items-start gap-6 lg:flex-row lg:justify-end lg:gap-8");
const textStyle = clsx("text-sm uppercase font-bold lg:font-normal tracking-wider");
