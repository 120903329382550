export default function ChartSwitch({ className }) {
    return (
        <svg
            className={className}
            width="112"
            height="50"
            viewBox="0 0 112 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_b_2981_11130)">
                <rect
                    y="49.1689"
                    width="48.5"
                    height="112"
                    rx="24.25"
                    transform="rotate(-90 0 49.1689)"
                    fill="black"
                    fill-opacity="0.75"
                />
                <path
                    d="M19 31.1689C18.45 31.1689 17.9792 30.9731 17.5875 30.5814C17.1958 30.1898 17 29.7189 17 29.1689C17 28.6189 17.1958 28.1481 17.5875 27.7564C17.9792 27.3648 18.45 27.1689 19 27.1689H19.2625C19.3375 27.1689 19.4167 27.1856 19.5 27.2189L24.05 22.6689C24.0167 22.5856 24 22.5064 24 22.4314V22.1689C24 21.6189 24.1958 21.1481 24.5875 20.7564C24.9792 20.3648 25.45 20.1689 26 20.1689C26.55 20.1689 27.0208 20.3648 27.4125 20.7564C27.8042 21.1481 28 21.6189 28 22.1689C28 22.2023 27.9833 22.3689 27.95 22.6689L30.5 25.2189C30.5833 25.1856 30.6625 25.1689 30.7375 25.1689H31.2625C31.3375 25.1689 31.4167 25.1856 31.5 25.2189L35.05 21.6689C35.0167 21.5856 35 21.5064 35 21.4314V21.1689C35 20.6189 35.1958 20.1481 35.5875 19.7564C35.9792 19.3648 36.45 19.1689 37 19.1689C37.55 19.1689 38.0208 19.3648 38.4125 19.7564C38.8042 20.1481 39 20.6189 39 21.1689C39 21.7189 38.8042 22.1898 38.4125 22.5814C38.0208 22.9731 37.55 23.1689 37 23.1689H36.7375C36.6625 23.1689 36.5833 23.1523 36.5 23.1189L32.95 26.6689C32.9833 26.7523 33 26.8314 33 26.9064V27.1689C33 27.7189 32.8042 28.1898 32.4125 28.5814C32.0208 28.9731 31.55 29.1689 31 29.1689C30.45 29.1689 29.9792 28.9731 29.5875 28.5814C29.1958 28.1898 29 27.7189 29 27.1689V26.9064C29 26.8314 29.0167 26.7523 29.05 26.6689L26.5 24.1189C26.4167 24.1523 26.3375 24.1689 26.2625 24.1689H26C25.9667 24.1689 25.8 24.1523 25.5 24.1189L20.95 28.6689C20.9833 28.7523 21 28.8314 21 28.9064V29.1689C21 29.7189 20.8042 30.1898 20.4125 30.5814C20.0208 30.9731 19.55 31.1689 19 31.1689Z"
                    fill="white"
                />
                <path
                    opacity="0.3"
                    d="M56 12.6689V37.1689"
                    stroke="white"
                    stroke-width="1.55"
                />
                <path
                    d="M79 30.1689H81V25.1689H79V30.1689ZM87 30.1689H89V20.1689H87V30.1689ZM83 30.1689H85V27.1689H83V30.1689ZM83 25.1689H85V23.1689H83V25.1689ZM77 34.1689C76.45 34.1689 75.9792 33.9731 75.5875 33.5814C75.1958 33.1898 75 32.7189 75 32.1689V18.1689C75 17.6189 75.1958 17.1481 75.5875 16.7564C75.9792 16.3648 76.45 16.1689 77 16.1689H91C91.55 16.1689 92.0208 16.3648 92.4125 16.7564C92.8042 17.1481 93 17.6189 93 18.1689V32.1689C93 32.7189 92.8042 33.1898 92.4125 33.5814C92.0208 33.9731 91.55 34.1689 91 34.1689H77ZM77 32.1689H91V18.1689H77V32.1689Z"
                    fill="#5F6368"
                />
            </g>
            <defs>
                <filter
                    id="filter0_b_2981_11130"
                    x="-40"
                    y="-39.3311"
                    width="192"
                    height="128.5"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="20" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_2981_11130"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_2981_11130"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
}
