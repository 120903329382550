import { Link } from "react-router-dom";
import clsx from "clsx";
import ScaleOnHoverDiv from "../ScaleOnHoverDiv";

export const Button = ({
    children,
    to,
    className,
    onClick,
    enabled = true,
    newWindow = false,
    primary = true,
}) => {
    let styles = `${className} ${enabled ? enabledSecondaryStyle : disabledStyle}`;

    if (primary) {
        styles = `${className} ${enabled ? enabledStyle : disabledStyle}`;
    }

    const handleKeyDown = (e) => {
        // Accesibility to mimic button but maintain animation styles.
        if (e.key === "Enter" || e.key === " ") {
            onClick(e);
        }
    };

    return enabled ? (
        <Link
            to={to}
            target={newWindow && "_blank"}
            onKeyDown={handleKeyDown}
            className="focus:scale-[0.95] focus:outline-none"
        >
            <ScaleOnHoverDiv role="button" className={styles} onClick={onClick}>
                {children}
            </ScaleOnHoverDiv>
        </Link>
    ) : (
        <div className={styles}>{children}</div>
    );
};

const style = clsx(
    "uppercase text-md tracking-widest font-bold py-3 text-center rounded-full",
);
const enabledSecondaryStyle = clsx(style + " bg-black text-[white]");
const enabledStyle = clsx(style + " bg-green text-black");
const disabledStyle = clsx(style + " text-mediumGray bg-black/10");
