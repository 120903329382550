import clsx from "clsx";

export const parentContainer = clsx(
    "mb-20 flex w-full flex-nowrap justify-between lg:mb-40",
);
export const leftColContainer = clsx("mr-20 flex w-2/3 flex-col items-start");
export const rightColContainer = clsx("ml-10 flex h-full w-1/3 flex-col");

export const rightColHeader = clsx(
    "uppercase font-bold tracking-widest text-sm first:mt-0 mt-8",
);
export const date = clsx("text-black/40 text-sm mb-2 font-bold tracking-wider");
export const text = clsx("my-2 text-sm");
