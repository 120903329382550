import clsx from "clsx";
import {
    UnderlinedSectionHeader,
    GreenCard,
    HorizontalScrollWrapper,
} from "@components";

export default function Data({ cards, subheader }) {
    return (
        <div className={parentContainerStyle}>
            <UnderlinedSectionHeader>Data</UnderlinedSectionHeader>
            <div className={subheaderStyle}>{subheader}</div>
            <HorizontalScrollWrapper>
                {cards.map((card) => {
                    return <GreenCard {...card} key={card.title} />;
                })}
            </HorizontalScrollWrapper>
        </div>
    );
}

const parentContainerStyle = clsx(
    "w-full flex flex-col items-center my-20 md:my-40",
);
const subheaderStyle = clsx(
    "my-10 max-w-[550px] text-center text-lg md:text-2xl mx-8",
);

Data.prototypes = {
    cards: Array,
    subheader: String,
};
