export default function ProgressBars({ className }) {
    return (
        <svg
            className={className}
            width="199"
            height="107"
            viewBox="0 0 199 107"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_b_2981_11095)">
                <rect
                    x="0.724609"
                    y="0.168945"
                    width="198.06"
                    height="106"
                    rx="20.6672"
                    fill="black"
                    fill-opacity="0.75"
                />
                <rect
                    x="42.0591"
                    y="27.7256"
                    width="118.836"
                    height="7.7771"
                    rx="3.88855"
                    fill="#DFF841"
                />
                <rect
                    x="42.0591"
                    y="49.2803"
                    width="103.336"
                    height="7.7771"
                    rx="3.88855"
                    fill="#B6D9FF"
                />
                <rect
                    x="42.0591"
                    y="70.8359"
                    width="46.5011"
                    height="7.7771"
                    rx="3.88855"
                    fill="#FF6B6B"
                />
            </g>
            <defs>
                <filter
                    id="filter0_b_2981_11095"
                    x="-68.166"
                    y="-68.7216"
                    width="335.842"
                    height="243.781"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur
                        in="BackgroundImageFix"
                        stdDeviation="34.4453"
                    />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_2981_11095"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_2981_11095"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
}
