import Loading from "./components/Loading";

const metaProps = {
    title: "",
    desc: "",
    img: "",
};

export const constants = {
    metaProps,
    loading: <Loading />,
    url: process.env.REACT_APP_JMRV_URL,
};
