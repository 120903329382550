import { routesFlat } from "@routes";
import clsx from "clsx";
import { Button } from "../Button";

export default function PartnerWithCTrees() {
    return (
        <div className="flex w-full items-center justify-center">
            <div className={blueBg}>
                <div className={gradientBg}>
                    <div className={title}>partner with ctrees</div>
                    <Button
                        to={routesFlat.contact}
                        primary={false}
                        className="px-8 py-2"
                    >
                        Contact Us
                    </Button>
                </div>
            </div>
        </div>
    );
}

const blueBg = clsx(
    "p-20 rounded-lg w-[90%] bg-blue relative flex justify-center h-[220px]",
);
const gradientBg = clsx(
    "h-full w-[80%] bg-gradient-to-r from-lime to-green absolute top-0 rounded-b-full flex flex-col items-center justify-center",
);
const title = clsx("uppercase text-2xl text-center font-bold tracking-wider mb-6");
