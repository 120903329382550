import { LineChart, Alert, ProgressRing } from "@/assets/beats/products/overlays";

export const hero = {
    bgColor: "blue",
    headerImg: "https://ctrees-website.s3.amazonaws.com/webv3/products/hero/tree-level.png",
    title: "Tree-Level Data",
    subheader: "Map individual trees at local to national scales",
    desc: "CTrees' tree-level datasets provide information on individual trees at local to national scales, supporting agencies and organizations implementing natural climate solutions.",
};

export const features = [
    {
        title: "Trees inside and outside forests",
        bgImg: "https://ctrees-website.s3.amazonaws.com/webv3/products/beats/tree-level-beat-1.png",
        img: <Alert />,
        list: [
            "Access accurate geospatial data at 1-hectare resolution from 2000 to present.",
            "Meet IPCC Tier 3 standards for wall-to-wall mapping, for national greenhouse gas inventories and Paris Agreement nationally determined contributions.",
        ],
    },
    {
        title: "Scientific insight",
        bgImg: "https://ctrees-website.s3.amazonaws.com/webv3/products/beats/tree-level-beat-2.png",
        img: <LineChart />,
        list: [
            "Meet accuracy requirements for calculating emission factors at project or jurisdictional levels. ",
            "Establish accurate forest reference emission levels at jurisdiction scales for REDD finance. ",
            "Understand spatial confidence level for prediction of carbon risks and returns.",
        ],
    },
    {
        title: "Data for land management policy",
        bgImg: "https://ctrees-website.s3.amazonaws.com/webv3/products/beats/tree-level-beat-3.png",
        img: <ProgressRing />,
        list: [
            "Estimate carbon for all ecosystems on land: forests, woodlands, grasslands, urban areas, croplands, coastal wetlands, and mangroves.",
            "Understand impacts of climate change, soil, and disturbance processes.",
        ],
    },
];

export const questionList = [
    {
        question:
            "Understand impacts of climate change, soil, and disturbance processes?",
        answer: "Estimate carbon for all ecosystems on land: forests, woodlands, grasslands, urban areas, croplands, coastal wetlands, and mangroves.",
    },
    {
        question:
            "Access accurate geospatial data at 1-hectare resolution from 2000 to present?",
        answer: "Estimate carbon for all ecosystems on land: forests, woodlands, grasslands, urban areas, croplands, coastal wetlands, and mangroves.",
    },
    {
        question: "Map individual trees at local to national scales?",
        answer: "Estimate carbon for all ecosystems on land: forests, woodlands, grasslands, urban areas, croplands, coastal wetlands, and mangroves.",
    },
];
